import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  HStack,
  Heading,
  Avatar,
  Icon,
  Stack,
  Input,
  Text,
  FormLabel,
  FormControl,
  Checkbox,
  VStack,
  useToast,
} from '@chakra-ui/react';

import Account from './Account';
import Permission from './Permission';
import Activity from './Activity';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DELETE, GET, POST, PUT } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';
import moment from 'moment';
import { se } from 'date-fns/locale';
import { useSelector } from 'react-redux';

function MemberDetail() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedService, setSelectedService] = useState('permission');
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const toast = useToast();
  const handleBlock = async () => {
    try {
      if (id) {
        const response = await POST(`/store/team/block/${id}?action=block`);

        if (response.status === 'success') {
          navigate('/dashboard/team');
          toast({
            description: 'Team member blocked successfully',
            status: 'success',
            position: 'top-right',
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            description: response?.message,
            status: 'error',
            position: 'top-right',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleUnBlock = async () => {
    try {
      if (id) {
        const response = await POST(`/store/team/block/${id}?action=unblock`);

        if (response.status === 'success') {
          navigate('/dashboard/team');
          toast({
            description: response?.message,
            status: response?.status,
            position: 'top-right',
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            description: response?.message,
            status: 'error',
            position: 'top-right',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemove = async userId => {
    try {
      const response = await DELETE(`/users/delete/${userId}`);

      if (response.status === 'success') {
        toast({
          description: response?.message,
          status: response?.status,
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        navigate('/dashboard/team');
      } else {
        toast({
          description: response?.message,
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  

  useEffect(() => {
    console.log(data);
    console.log(state);
    setData(state.permissions.map(permissions => permissions._id));
    console.log(data);
  }, []);

  const user = useSelector(state => state.user.value);
  const handleSubmit = async () => {
    console.log('hello', data);
    try {
      let response = await PUT(
        `/store/team/update/${id}`,
        { permissions: data },
        {
          Authorization: `Bearer ${user?.verificationToken}`,
        }
      );

      console.log(response);
      if (response.status === 'success') {
        toast({
          description: 'Profile updated successfully',
          status: 'success',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
        navigate('/dashboard/team')
      } else {
        toast({
          description: response?.message,
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Stack px={6} display={'flex'} flexDirection={'column'} gap={1}>
      <Stack
        mb={2}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Stack display={'flex'} flexDirection={'column'}>
          <Heading fontSize={'30px'} fontWeight={'600'}>
            Team Member Details
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the latest update...Check Now!
          </Text>
        </Stack>
      </Stack>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Stack>
          <HStack>
            <Avatar
              w="87px"
              h="87px"
              name="Dan Abrahmov"
              src={
                `${imgUrl}${state?.profile_picture}` ||
                'https://bit.ly/dan-abramov'
              }
            />
            <Stack>
              <Text color={'#007341'} fontSize={'24px'} fontWeight={'700'}>
                {state?.fullName || 'Hammad Hussain'}
              </Text>
              <Text lineHeight={'0'} fontSize={'18px'} color="#989BA2">
                #545061
              </Text>
            </Stack>
          </HStack>
        </Stack>
        <Stack>
          <Text fontSize={'16px'} fontWeight={500}>
            Email Address
          </Text>
          <Text lineHeight={'0.6'} fontSize={'17px'} fontWeight={700}>
            {state?.email || 'hammadhh1@gmail.com'}
          </Text>
        </Stack>
        <Stack>
          <Text fontSize={'16px'} fontWeight={500}>
            Store Manager
          </Text>
          <Text lineHeight={'0.6'} fontSize={'17px'} fontWeight={700}>
            {state?.storeId?.name || 'AJWA Mart Grocery'}
          </Text>
        </Stack>
        <Stack>
          <Text fontSize={'16px'} fontWeight={500}>
            Member Since
          </Text>
          <Text lineHeight={'0.6'} fontSize={'17px'} fontWeight={700}>
            {moment(state?.memberSince).format('DD/MM/YYYY') || '3/19/2024'}
          </Text>
        </Stack>

        <Stack gap={6} direction={'row'}>
          <Button
            onClick={() => handleRemove(id)}
            background={'#E94646'}
            w="135px"
            h="40px"
            borderRadius={'9px'}
            color={'#fff'}
          >
            Delete
          </Button>
          {state?.blocked ? (
            <Button
              onClick={() => handleUnBlock()}
              background={'#E94646'}
              w="135px"
              h="40px"
              borderRadius={'9px'}
              color={'#fff'}
            >
              Unblock
            </Button>
          ) : (
            <Button
              onClick={() => handleBlock()}
              background={'#E94646'}
              w="135px"
              h="40px"
              borderRadius={'9px'}
              color={'#fff'}
            >
              Block
            </Button>
          )}
        </Stack>
      </Stack>

      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={4}
        flexWrap={'wrap'}
        py={4}
      >
        <Button
          border={
            selectedService === 'permission'
              ? '2px solid #EAA13E'
              : '2px solid #07d57c'
          }
          padding={'10px 25px'}
          bg={
            selectedService === 'permission'
              ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
              : 'transparent'
          }
          width={'max-content'}
          onClick={() => setSelectedService('permission')}
        >
          Permission Settings
        </Button>
        <Button
          border={
            selectedService === 'account'
              ? '2px solid #EAA13E'
              : '2px solid #07d57c'
          }
          padding={'10px 25px'}
          bg={
            selectedService === 'account'
              ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
              : 'transparent'
          }
          width={'max-content'}
          onClick={() => setSelectedService('account')}
        >
          Account Settings
        </Button>

        <Button
          border={
            selectedService === 'activity'
              ? '2px solid #EAA13E'
              : '2px solid #07d57c'
          }
          padding={'10px 25px'}
          bg={
            selectedService === 'activity'
              ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
              : 'transparent'
          }
          width={'max-content'}
          onClick={() => setSelectedService('activity')}
        >
          Activity Logs
        </Button>
      </Stack>

      {selectedService === 'permission' ? (
        <>
          <Permission setData={setData} abc={state?.permissions} />
          <Button
            padding={'10px 50px'}
            bg={
              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            type="submit"
            w="176px"
            _hover={{
              color: '#000',
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </>
      ) : selectedService === 'account' ? (
        <Account
          data={state?.accountSettings[0]}
          id={id}
          storeId={state?.storeId}
        />
      ) : (
        <Activity data={state?.activityLogs} />
      )}
    </Stack>
  );
}

export default MemberDetail;
