import {
  Stack,
  Container,
  Heading,
  Box,
  Image,
  Text,
  Button,
  Icon,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  SimpleGrid,
  Grid,
  GridItem,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  TableContainer,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { RiCloseLargeLine } from 'react-icons/ri';
import wishimg from '../../../assets/images/website/wishlist.jpg';
import { RiFileList3Line } from 'react-icons/ri';
import { BsBoxSeam } from 'react-icons/bs';
import { LiaShippingFastSolid } from 'react-icons/lia';
import { LiaDollySolid } from 'react-icons/lia';
import Cele from '../../../assets/images/website/shoper.png';
import { useNavigate, useParams } from 'react-router-dom';
import { DELETE, GET, PUT } from '../../../utilities/ApiProvider';
import { convertNumbertoCommaSeperate } from '../../../utilities/helper';
import moment from 'moment';

export default function SingleOrder() {
  const { id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [orderdetail, setorderdetail] = useState(null);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const storeId = localStorage.getItem('storeId');

  useEffect(() => {
    async function getOrderDetails() {
      try {
        const response = await GET(`/store/order/${id}?storeId=${storeId}`);
        console.log('orderList details', response);
        if (response.status === 'success') {
          setorderdetail(response.data);
        } else {
          console.log('Error while fetching groceries');
        }
      } catch (error) {
        console.log(error, 'error while fetching groceries');
      }
    }
    getOrderDetails();
  }, [id]);

  const handleCancelOrder = async () => {
    try {
      const response = await PUT(`/store/order/cancel/${id}`);
      console.log('response', response);
      if (response.status === 'success') {
        toast({
          description: 'Your order has been cancelled successfully',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        navigate('/');
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Stack py={4} textAlign={'center'} alignItems={'center'}>
              <Image w={'120px'} src={Cele} />
              <Heading>Are you sure?</Heading>
              <Text fontSize={'14px'}>You want to cancel this order</Text>
              <Stack>
                <Button
                  py={4}
                  fontSize={'16px'}
                  width={'200px'}
                  background={
                    'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                  }
                  _hover={{
                    border: '2px solid #07d57c',
                    bg: 'transparent',
                  }}
                  onClick={handleCancelOrder}
                >
                  Yes
                </Button>
                <Button
                  py={4}
                  fontSize={'16px'}
                  width={'200px'}
                  background={
                    'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                  }
                  _hover={{
                    border: '2px solid #07d57c',
                    bg: 'transparent',
                  }}
                  onClick={onClose}
                >
                  No
                </Button>
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Stack px={{ base: '15px', md: '15px', xl: '0', '2xl': '0' }}>
        <Container p={0} mb={6} maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}>
          <Stack
            px={{ base: '4', xl: '12', '2xl': '12' }}
            bg={'#fff'}
            py={12}
            borderRadius={12}
          >
            <Stack bg={'#fff'} py={12} borderRadius={12}>
              {!orderdetail ? (
                <Stack
                  alignItems={'center'}
                  justifyContent={'center'}
                  height={'400px'}
                >
                  <Spinner />
                </Stack>
              ) : (
                <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                  <GridItem colSpan={{ base: '5', xl: '3', '2xl': '3' }}>
                    <Stack>
                      <Heading
                        mb={6}
                        color={'#111'}
                        fontSize={'25px'}
                        fontWeight={700}
                      >
                        Orders Status
                      </Heading>
                      <SimpleGrid
                        borderBottom={'1px solid #D9D9D9'}
                        pb={4}
                        mb={'15px !important'}
                        columns={4}
                        spacing={{ base: '1', xl: '10', '2xl': '10' }}
                      >
                        <Box textAlign={'center'}>
                          <Box
                            display={'flex'}
                            width={'60px'}
                            height={'60px'}
                            border={
                              orderdetail?.orderStatus === 'pending'
                                ? '1px solid #fff'
                                : '1px solid  #000'
                            }
                            borderRadius={'50%'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            margin={'auto'}
                            bg={
                              orderdetail?.orderStatus === 'pending'
                                ? 'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                                : '#fff'
                            }
                            role="group"
                          >
                            <Icon
                              color={
                                orderdetail?.orderStatus === 'pending'
                                  ? '#fff'
                                  : '#000'
                              }
                              fontSize={'20px'}
                              as={RiFileList3Line}
                            />
                          </Box>
                          <Text
                            fontWeight={600}
                            fontSize={{
                              base: '12px',
                              xl: '16px',
                              '2xl': '16px',
                            }}
                          >
                            Order Received
                          </Text>
                        </Box>
                        <Box textAlign={'center'}>
                          <Box
                            display={'flex'}
                            width={'60px'}
                            height={'60px'}
                            border={
                              orderdetail?.orderStatus === 'processing'
                                ? '1px solid #fff'
                                : '1px solid  #000'
                            }
                            borderRadius={'50%'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            margin={'auto'}
                            bg={
                              orderdetail?.orderStatus === 'processing'
                                ? 'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                                : '#fff'
                            }
                            role="group"
                          >
                            <Icon
                              color={
                                orderdetail?.orderStatus === 'processing'
                                  ? '#fff'
                                  : '#000'
                              }
                              _groupHover={{ color: '#fff' }}
                              fontSize={'20px'}
                              as={BsBoxSeam}
                            />
                          </Box>
                          <Text
                            fontWeight={600}
                            fontSize={{
                              base: '12px',
                              xl: '16px',
                              '2xl': '16px',
                            }}
                          >
                            Order In process
                          </Text>
                        </Box>
                        <Box textAlign={'center'}>
                          <Box
                            display={'flex'}
                            width={'60px'}
                            height={'60px'}
                            border={
                              orderdetail?.orderStatus === 'shipping'
                                ? '1px solid #fff'
                                : '1px solid  #000'
                            }
                            borderRadius={'50%'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            margin={'auto'}
                            bg={
                              orderdetail?.orderStatus === 'shipping'
                                ? 'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                                : '#fff'
                            }
                            role="group"
                          >
                            <Icon
                              color={
                                orderdetail?.orderStatus === 'shipping'
                                  ? '#fff'
                                  : '#000'
                              }
                              _groupHover={{ color: '#fff' }}
                              fontSize={'23px'}
                              as={LiaDollySolid}
                            />
                          </Box>
                          <Text
                            fontWeight={600}
                            fontSize={{
                              base: '12px',
                              xl: '16px',
                              '2xl': '16px',
                            }}
                          >
                            Ready for Ship
                          </Text>
                        </Box>
                        <Box textAlign={'center'}>
                          <Box
                            display={'flex'}
                            width={'60px'}
                            height={'60px'}
                            border={
                              orderdetail?.orderStatus === 'shipped'
                                ? '1px solid #fff'
                                : '1px solid  #000'
                            }
                            borderRadius={'50%'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            margin={'auto'}
                            bg={
                              orderdetail?.orderStatus === 'shipped'
                                ? 'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                                : '#fff'
                            }
                            role="group"
                          >
                            <Icon
                              color={
                                orderdetail?.orderStatus === 'shipped'
                                  ? '#fff'
                                  : '#000'
                              }
                              _groupHover={{ color: '#fff' }}
                              fontSize={'20px'}
                              as={LiaShippingFastSolid}
                            />
                          </Box>
                          <Text
                            fontWeight={600}
                            fontSize={{
                              base: '12px',
                              xl: '16px',
                              '2xl': '16px',
                            }}
                          >
                            Order Shipped
                          </Text>
                        </Box>
                      </SimpleGrid>
                      <Stack gap={4}>
                        <TableContainer>
                          <Table variant="simple">
                            <Thead>
                              <Tr>
                                <Th>Products</Th>
                                <Th>Price</Th>
                                <Th>Purchased Date</Th>
                                <Th>Quantity</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {orderdetail?.orderDetails?.map((v, k) => (
                                <Tr>
                                  <Td>
                                    <Box alignItems={'center'} gap={2}>
                                      <Image
                                        w={'80px'}
                                        h={'85px'}
                                        src={v?.productId?.images[0]?.path}
                                      />
                                      <Text
                                        fontSize={'15px'}
                                        noOfLines={1}
                                        color={'#212121'}
                                        display={'-webkit-box'}
                                        WebkitLineClamp={2}
                                        overflow={'hidden'}
                                        textOverflow={'ellipsis'}
                                        width={{
                                          base: '100%',
                                          md: '100%',
                                          lg: '100%',
                                        }}
                                      >
                                        {v?.productId?.name}
                                      </Text>
                                    </Box>
                                  </Td>
                                  <Td>
                                    <Text fontSize={'15px'}>
                                      Rs{' '}
                                      {convertNumbertoCommaSeperate(v?.total)}
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={'15px'}>
                                      {moment(v?.createdAt)?.format(
                                        'DD/MM/YYYY'
                                      )}
                                    </Text>
                                  </Td>

                                  <Td>
                                    <Text fontSize={'15px'}>{v?.quantity}</Text>
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </TableContainer>

                        <Box display={'flex'} gap={2}>
                          {/* <Button
                          border={'2px solid #07d57c'}
                          padding={'10px 25px'}
                          bg={'transparent'}
                        >
                          Edit Order
                        </Button> */}
                          {orderdetail?.orderStatus === 'complete' && (
                            <Button
                              _hover={{
                                color: '#fff',
                              }}
                              bg={'green.500'}
                              color={'white'}
                            >
                              Order Completed
                            </Button>
                          )}
                          {orderdetail?.orderStatus === 'cancelled' && (
                            <Button
                              _hover={{
                                color: '#fff',
                              }}
                              ripple={'none'}
                              bg={'red.500'}
                              color={'white'}
                            >
                              Order Cancelled
                            </Button>
                          )}
                          {orderdetail?.orderStatus === 'pending' && (
                            <Button
                              _hover={{
                                color: '#000',
                              }}
                              bg={'red.500'}
                              color={'white'}
                              onClick={onOpen}
                            >
                              Cancel Order
                            </Button>
                          )}
                        </Box>
                      </Stack>
                    </Stack>
                  </GridItem>
                  <GridItem
                    colStart={{ base: '1', xl: '4', '2xl': '4' }}
                    colEnd={6}
                  >
                    <Stack px={4} py={6} bg={'#F3F3F3'} borderRadius={6}>
                      <Stack
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                      >
                        <Stack>
                          <Heading
                            mb={6}
                            color={'#111'}
                            fontSize={'25px'}
                            fontWeight={700}
                          >
                            Your Order
                          </Heading>
                        </Stack>

                        <Stack>
                          <Text
                            mb={6}
                            color={'#111'}
                            fontSize={'20px'}
                            fontWeight={400}
                          >
                            #{orderdetail?.invoice || 'KHI000139'}
                          </Text>
                        </Stack>
                      </Stack>
                      <Table className="orderTb" variant="simple">
                        <Tbody>
                          {orderdetail?.orderDetails?.map((v, k) => (
                            <Tr>
                              <Td>
                                <Heading fontSize={'16px'} fontWeight={500}>
                                  {v?.productId?.name} × {v?.quantity}
                                </Heading>
                              </Td>
                              <Td>
                                <Heading
                                  fontSize={'20px'}
                                  fontWeight={700}
                                  bg={
                                    'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                                  }
                                  backgroundClip={'text'}
                                >
                                  Rs {convertNumbertoCommaSeperate(v?.total)}
                                </Heading>
                              </Td>
                            </Tr>
                          ))}

                          <Tr py={'5px !important'}>
                            <Td py={'0px !important'}>
                              <Heading fontSize={'19px'} fontWeight={700}>
                                Subtotal
                              </Heading>
                            </Td>
                            <Td py={'0 !important'}>
                              <Heading
                                fontSize={'20px'}
                                fontWeight={700}
                                bg={
                                  'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                                }
                                backgroundClip={'text'}
                              >
                                Rs.{' '}
                                {orderdetail?.subtotal &&
                                  convertNumbertoCommaSeperate(
                                    orderdetail?.subtotal
                                  )}
                              </Heading>
                            </Td>
                          </Tr>
                          {/* <Tr>
                          <Td>
                            <Heading fontSize={'19px'} fontWeight={700}>
                              Promotional Discount
                            </Heading>
                          </Td>
                          <Td>
                            <Heading
                              fontSize={'20px'}
                              fontWeight={700}
                              bg={
                                'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                              }
                              backgroundClip={'text'}
                            >
                              -Rs. 2.00
                            </Heading>
                          </Td>
                        </Tr> */}
                          {/* <Tr>
                          <Td>
                            <Heading fontSize={'19px'} fontWeight={700}>
                              Basket Total
                            </Heading>
                          </Td>
                          <Td>
                            <Heading
                              fontSize={'20px'}
                              fontWeight={700}
                              bg={
                                'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                              }
                              backgroundClip={'text'}
                            >
                              Rs. 38
                            </Heading>
                          </Td>
                        </Tr> */}
                          {/* <Tr>
                          <Td>
                            <Heading fontSize={'19px'} fontWeight={700}>
                              Bags, Picking & Packing
                            </Heading>
                          </Td>
                          <Td>
                            <Heading
                              fontSize={'20px'}
                              fontWeight={700}
                              bg={
                                'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                              }
                              backgroundClip={'text'}
                            >
                              Rs. 3
                            </Heading>
                          </Td>
                        </Tr> */}
                          <Tr py={'5px !important'}>
                            <Td py={'0 !important'}>
                              <Heading fontSize={'19px'} fontWeight={700}>
                                Delivery
                              </Heading>
                            </Td>
                            <Td py={'0 !important'}>
                              <Heading
                                fontSize={'20px'}
                                fontWeight={700}
                                bg={
                                  'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                                }
                                backgroundClip={'text'}
                              >
                                Rs. {orderdetail?.delivery}
                              </Heading>
                            </Td>
                          </Tr>
                          {/* <Tr>
                          <Td>
                            <Heading fontSize={'19px'} fontWeight={700}>
                              Small Basket Fee
                            </Heading>
                          </Td>
                          <Td>
                            <Heading
                              fontSize={'20px'}
                              fontWeight={700}
                              bg={
                                'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                              }
                              backgroundClip={'text'}
                            >
                              Rs. 4
                            </Heading>
                          </Td>
                        </Tr> */}
                          <Tr py={'5px !important'}>
                            <Td py={'0 !important'}>
                              <Heading fontSize={'19px'} fontWeight={700}>
                                Delivery Time
                              </Heading>
                            </Td>
                            <Td py={'0 !important'}>
                              <Heading
                                fontSize={'20px'}
                                fontWeight={700}
                                bg={
                                  'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                                }
                                backgroundClip={'text'}
                              >
                                {moment(orderdetail?.orderDate)?.format(
                                  'DD, MMM YYYY'
                                )}
                              </Heading>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>
                              <Heading fontSize={'22px'} fontWeight={700}>
                                Total Order
                              </Heading>
                            </Td>
                            <Td>
                              <Heading
                                fontSize={'20px'}
                                fontWeight={700}
                                bg={
                                  'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                                }
                                backgroundClip={'text'}
                              >
                                Rs{' '}
                                {orderdetail?.total &&
                                  convertNumbertoCommaSeperate(
                                    orderdetail?.total
                                  )}
                              </Heading>
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                      {/* <Button
                      fontSize={'16px'}
                      padding={'20px 35px'}
                      background={
                        'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                      }
                    >
                      Checkout
                    </Button> */}
                    </Stack>
                  </GridItem>
                </Grid>
              )}
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </>
  );
}
