import {
  SimpleGrid,
  Stack,
  Button,
  Container,
  Heading,
  Text,
  Box,
  Image,
  Icon,
  Flex,
  Tag,
  Spinner,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { GET } from '../../../utilities/ApiProvider';
import { Link } from 'react-router-dom';
import { imgUrl } from '../../../utilities/config';
import star from '../../../assets/images/website/star.svg';
import { FiShoppingCart } from 'react-icons/fi';
import {
  convertNumbertoCommaSeperate,
  useAddToCart,
  useAddToWhishlist,
} from '../../../utilities/helper';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { selectWishlistData } from '../../../reducers/slices/website.slice';

export default function DiscountOfferProducts({ id }) {
  const storeId = localStorage.getItem('storeId');
  const [productlist, setProductlist] = useState(null);
  const addToCart = useAddToCart();
  const wishlist = useSelector(selectWishlistData);

  const user = useSelector(state => state.user.value);
  const { handleFavourite, loading } = useAddToWhishlist();
  useEffect(() => {
    console.log('id', id);
    fetchProducts(); // Fetch products on component mount
  }, [id, storeId]); // Dependencies: id and storeId

  // Function to fetch products based on filters
  async function fetchProducts() {
    try {
      const response = await GET(`/store/discount/${id}?storeId=${storeId}`);
      console.log('discount offer products', response);

      if (response.status === 'success') {
        setProductlist(response?.data?.[0]?.products || []); // Set the product list state with the products array
        // const updatedList = response?.data?.[0]?.products?.map(item => {
        //   if (wishlist.find(v => v._id === item._id)) {
        //     return { ...item, is_favourite: true };
        //   }
        //   return { ...item, is_favourite: false };
        // });
        // setProductlist(updatedList || []); // Set the product list state with the products array
      } else {
        setProductlist([]);
        console.log('Error while fetching products');
      }
    } catch (error) {
      console.log(error, 'error while fetching products');
      setProductlist([]);
    }
  }
  console.log('productlist', productlist);

  return (
    <>
      <Stack>
        <Container
          p={0}
          mt={4}
          mb={6}
          maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}
        >
          <Stack px={4} bg={'#fff'} py={16} borderRadius={12} gap={8}>
            <Stack pb={8} textAlign={'center'} alignItems={'center'}>
              <Heading
                mb={6}
                color={'#409D47'}
                fontSize={{
                  base: '20px',
                  md: '25px',
                  xl: '30px',
                  '2xl': '35px',
                }}
                fontWeight={700}
              >
                {' '}
                Discount Products
              </Heading>
            </Stack>
            {!productlist ? (
              <Stack
                display={'flex'}
                minH={'400px'}
                bg={'#fff'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Spinner />
              </Stack>
            ) : (
              <SimpleGrid
                columns={{ base: '2', xl: '4', '2xl': '4' }}
                spacing={0}
              >
                {productlist?.length > 0 &&
                  productlist.map((v, k) => {
                    console.log(v);
                    return (
                      <Stack key={k} border={'1px solid #D9E8D6'}>
                        <Stack as={Link} to={`/productdetail/${v._id}`}>
                          <Stack position={'relative'}>
                            {((v.off && v.off > 0) ||
                              (v.stock && v.stock !== 0)) && (
                              <Box
                                display={'flex'}
                                gap={2}
                                position={'absolute'}
                                top={'5px'}
                                left={'5px'}
                              >
                                {v.off > 0 && (
                                  <Tag
                                    bg={'#007341'}
                                    lineHeight={'base'}
                                    color={'#fff'}
                                    fontSize={'12px'}
                                    fontWeight={600}
                                  >
                                    {v.off} %
                                  </Tag>
                                )}
                                {v.stock && (
                                  <Tag
                                    bg={v.stock > 0 ? '#E0883B' : '#C5262F'}
                                    lineHeight={'base'}
                                    color={'#fff'}
                                    fontSize={'12px'}
                                    fontWeight={600}
                                  >
                                    {v.stock > 0 ? 'In Stock' : 'Out of Stock'}
                                  </Tag>
                                )}
                              </Box>
                            )}
                            <Image
                              minHeight={'238px'}
                              maxHeight={'238px'}
                              objectFit={'contain'}
                              src={
                                v.url?.includes('https')
                                  ? v.images?.[0]?.path
                                  : `${imgUrl}/${v.images?.[0]?.path}`
                              }
                            />
                          </Stack>
                          <Stack className="mh-3" px={4} py={3} spacing={2}>
                            <Stack>
                              <Heading
                                as={'h3'}
                                maxH={'40px'}
                                minHeight={'40px'}
                                color={'#212121'}
                                overflow={'hidden'}
                                display={'-webkit-box'}
                                WebkitLineClamp={2}
                                WebkitBoxOrient={'vertical'}
                              >
                                {v.name}
                              </Heading>
                              <Text
                                fontSize={'13px'}
                                maxH={'40px'}
                                minHeight={'40px'}
                                color={'#212121'}
                                overflow={'hidden'}
                                display={'-webkit-box'}
                                WebkitLineClamp={2}
                                WebkitBoxOrient={'vertical'}
                              >
                                {v.description}
                              </Text>
                            </Stack>
                            <Box display={'flex'} gap={2}>
                              <Image src={star} alt="star" />
                              <Text fontSize={'12px'} color={'#989BA2'}>
                                ({v.rating})
                              </Text>
                            </Box>
                          </Stack>
                        </Stack>
                        <Stack px={4} pb={4} spacing={2}>
                          <Text fontSize={'13px'} color={'#212121'}>
                            {v.tagName}
                          </Text>
                          <Box
                            className="mh-3"
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                          >
                            {v?.isDiscount ? (
                              <Heading as={'h3'}>
                                Rs{' '}
                                {convertNumbertoCommaSeperate(
                                  v.discountedPrice
                                )}
                                <Text
                                  as="span"
                                  ml={2}
                                  textDecoration="line-through"
                                  color="gray.500"
                                  fontSize={'sm'}
                                >
                                  Rs {convertNumbertoCommaSeperate(v.price)}
                                </Text>
                              </Heading>
                            ) : (
                              <Heading as={'h3'}>
                                Rs {convertNumbertoCommaSeperate(v.price)}
                              </Heading>
                            )}
                            <Flex gap={2}>
                              {user && (
                                <Button
                                  disabled={loading}
                                  onClick={() => {
                                    handleFavourite(v, false);
                                    // Create a deep copy of the product list
                                    let temp = productlist.map(item => ({
                                      ...item,
                                    }));

                                    let check = temp.find(
                                      item => item._id === v._id
                                    )?.is_favourite;

                                    temp = temp.map(item => {
                                      if (item._id === v._id) {
                                        return {
                                          ...item,
                                          is_favourite: !check,
                                        };
                                      }
                                      return item;
                                    });

                                    setProductlist(temp);
                                  }}
                                  w={'40px'}
                                  height={'40px'}
                                  borderRadius={'50%'}
                                >
                                  <Icon
                                    fontSize={'15px'}
                                    color={'#007341'}
                                    as={v?.is_favourite ? FaHeart : FaRegHeart}
                                  />
                                </Button>
                              )}

                              <Button
                                disabled={v.stock > 0 ? false : true}
                                onClick={() => addToCart(v)}
                                w={'40px'}
                                height={'40px'}
                                borderRadius={'50%'}
                              >
                                <Icon
                                  fontSize={'15px'}
                                  color={'#007341'}
                                  as={FiShoppingCart}
                                />
                              </Button>
                            </Flex>
                          </Box>
                        </Stack>
                      </Stack>
                    );
                  })}
              </SimpleGrid>
            )}
          </Stack>
        </Container>
      </Stack>
    </>
  );
}
