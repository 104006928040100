import {
  Box,
  Button,
  Grid,
  Heading,
  Icon,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { LuUser } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';
import moment from 'moment';
function Offer() {
  const [offers, setOffers] = useState(null);
  useEffect(() => {
    fetchOffers();
  }, []);
  const fetchOffers = async () => {
    try {
      const response = await GET('/admin/coupon');
      if (response.status === 'success') {
        console.log(response.data);
        setOffers(response.data);
      } else {
        setOffers([]);
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  function calculateAvailability(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const differenceInMs = end - start;

    const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

    return differenceInDays;
  }
  return (
    <Stack px={6} py={2}>
      <Stack
        mb={2}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Stack display={'flex'} flexDirection={'column'}>
          <Heading fontSize={'30px'} fontWeight={'600'}>
            Offers
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the latest update...Check Now!
          </Text>
          <Text fontSize={'18px'} color={'#1B1C1D'} fontWeight={'500'}>
            You’ve {offers?.length || 0} offers
          </Text>
        </Stack>
        <Button
          padding={'10px 50px'}
          as={Link}
          to={'/dashboard/add-offer'}
          bg={
            'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
          }
          color={'#fff'}
          fontWeight={'500'}
          lineHeight={'inherit'}
          borderRadius={12}
          _hover={{
            color: '#000',
          }}
        >
          Add New Offer
        </Button>
      </Stack>
      {!offers ? (
        <Stack
          w={'100%'}
          h={'70vh'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Spinner />
        </Stack>
      ) : (
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          {offers.map(item => (
            <Stack
              gap={2}
              shadow={'0px 16.32px 38.75px 0px #0000000F'}
              borderRadius={'17px'}
              p={{ base: '15px 20px', xl: '25px 40px' }}
              role="group"
              transition={'0.3s'}
              _hover={{
                borderColor: 'primaryGreen.200',
              }}
            >
              <Box display={'flex'} justifyContent={'space-between'}>
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                  <Box
                    w={'67px'}
                    h={'67px'}
                    bg={
                      'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
                    }
                    display={'flex'}
                    alignItems={'center'}
                    transition={'0.3s'}
                    borderRadius={'12px'}
                    mb="5px"
                    justifyContent={'center'}
                    _groupHover={{
                      bgColor: 'primaryGreen.200',
                    }}
                  >
                    <Icon
                      _groupHover={{
                        color: '#fff',
                      }}
                      transition={'0.3s'}
                      fontSize={'30px'}
                      as={LuUser}
                    />
                  </Box>
                  <Box>
                    <Heading
                      fontSize={'16px'}
                      fontWeight={'700'}
                      color={'#007341'}
                      lineHeight={'1.6rem'}
                    >
                      {item?.name || 'Big Sale'}
                    </Heading>
                    <Text
                      color={'#989BA2'}
                      fontSize={'14px'}
                      fontWeight={'400'}
                    >
                      {moment(item.createdAt).fromNow()}
                    </Text>
                  </Box>
                </Stack>
                <Stack alignItems={'end'} justifyContent={'center'}>
                  <Button
                    as={Link}
                    to={`/dashboard/offer-detail/${item?._id}`}
                    border={'2px solid #07d57c'}
                    padding={'10px 25px'}
                    bg={'transparent'}
                    // width={'max-content'}
                    width={'133px'}
                    borderRadius={'10px'}
                  >
                    View Details
                  </Button>
                </Stack>
              </Box>
              <Stack
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Stack display={'flex'} flexDirection={'column'}>
                  <Text fontSize={'17px'} fontWeight={'500'} color={'#1A1B1A'}>
                    Discount
                  </Text>
                  <Text
                    fontSize={'18px'}
                    fontWeight={'700'}
                    color={'#1A1B1A'}
                    margin={'0 !important'}
                  >
                    {item?.discount || '10'}% off
                  </Text>
                </Stack>
                <Stack display={'flex'} flexDirection={'column'}>
                  <Text fontSize={'17px'} fontWeight={'500'} color={'#1A1B1A'}>
                    No. of Users
                  </Text>
                  <Text
                    fontSize={'18px'}
                    fontWeight={'700'}
                    color={'#1A1B1A'}
                    margin={'0 !important'}
                  >
                    {item?.limit || '25'}
                  </Text>
                </Stack>
                <Stack display={'flex'} flexDirection={'column'}>
                  <Text fontSize={'17px'} fontWeight={'500'} color={'#1A1B1A'}>
                    Availability
                  </Text>
                  <Text
                    fontSize={'18px'}
                    fontWeight={'700'}
                    color={'#1A1B1A'}
                    margin={'0 !important'}
                  >
                    {calculateAvailability(item?.start, item?.end) || '10 days'}{' '}
                    days
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Grid>
      )}
    </Stack>
  );
}

export default Offer;
