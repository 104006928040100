import {
  Box,
  Flex,
  Text,
  Heading,
  FormLabel,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  useDisclosure,
  Image,
  FormControl,
  SimpleGrid,
  Spinner,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  CheckboxGroup,
  Checkbox,
} from '@chakra-ui/react';
import React, { useState, useEffect, useCallback } from 'react';
import Star from '../../../assets/images/website/star.svg';
import wishimg from '../../../assets/images/website/wishlist.jpg';
import { Link } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs';
import { GET, POST } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';
import { convertNumbertoCommaSeperate } from '../../../utilities/helper';
import Pagination from '../../../utilities/Pagination';
import ModalWrapper from '../../../components/Modal/';
import { AddIcon } from '@chakra-ui/icons';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';

function AddProductCsv({ fetchData, onClose }) {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const handleFileChange = event => {
    setFile(event.target.files[0]);
  };
  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      formData.append('files', file);
      const response = await POST('/admin/product/uploader', formData);
      if (
        response.status === 'success' ||
        response.data === 'upload successfully'
      ) {
        fetchData();
        setLoading(false);
        toast({
          title: 'Upload successfully',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        onClose();
      }
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Stack display={'flex'} flexDirection={'column'} gap={3}>
        <FormControl>
          <FormLabel fontSize={'16px'} fontWeight={'500'} lineHeight={'30px'}>
            Upload CSV File
          </FormLabel>
          <Box
            h="56px"
            borderRadius={'8px'}
            border={'1px dashed #B4B4B4'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            cursor={'pointer'}
            onClick={() => document.getElementById('upload').click()}
          >
            <Stack
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              gap={3}
            >
              <Box
                w="24px"
                h="24px"
                borderRadius={'50%'}
                border={'1px solid #1A1A1A'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                color="#1A1A1A"
              >
                <AddIcon fontSize={'12px'} />
              </Box>
              <Box marginTop={'0px !important'}>
                <Text fontSize={'18px'} fontWeight={'500'}>
                  Upload CSV File
                </Text>
              </Box>
              <Input
                onChange={handleFileChange}
                type="file"
                display="none"
                id="upload"
                accept=".csv"
              />
              <label htmlFor="upload">
                <Box
                  as="span"
                  display={'none'}
                  cursor="pointer"
                  padding="10px 20px"
                  borderRadius="8px"
                  border="1px solid #B4B4B4"
                  background="#F3F3F3"
                  _hover={{
                    background: '#E2E2E2',
                  }}
                >
                  Choose File
                </Box>
              </label>
              {file && <Text marginTop="10px">{file.name}</Text>}
            </Stack>
          </Box>
        </FormControl>
        <Stack py={3}>
          <Button
            padding={'10px 50px'}
            type="submit"
            bg={
              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            h="45px"
            _hover={{
              color: '#000',
            }}
            isLoading={loading}
          >
            Continue
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

function EditProductCsv({ fetchData, onClose }) {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [selectedService, setSelectedService] = useState('3');
  const handleFileChange = event => {
    setFile(event.target.files[0]);
  };
  const data = [
    {
      id: '1',
      label: 'Price only',
      value: 'price',
    },
    {
      id: '2',
      label: 'Stock only',
      value: 'stock',
    },
    {
      id: '3',
      label: 'Both Price and Stock',
      value: 'stock',
    },
  ];
  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      formData.append('files', file);
      formData.append('type', 'update');
      if (selectedService === '1') {
        formData.append('reqColumn', JSON.stringify(['sku', 'price']));
      } else if (selectedService === '2') {
        formData.append('reqColumn', JSON.stringify(['sku', 'stock']));
      } else if (selectedService === '3') {
        formData.append('reqColumn', JSON.stringify(['sku', 'price', 'stock']));
      }

      const response = await POST(`/admin/product/uploader`, formData);
      console.log(response);

      if (
        response.status === 'success' ||
        response.data === 'upload successfully'
      ) {
        fetchData();
        setLoading(false);
        toast({
          title: 'Upload successfully',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        onClose();
      } else {
        setLoading(false);
        console.log('Error while uploading csv file');
      }
      console.log(response);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Stack display={'flex'} flexDirection={'column'} gap={3}>
        <FormControl>
          <FormLabel fontSize={'16px'} fontWeight={'500'} lineHeight={'30px'}>
            Upload CSV File
          </FormLabel>
          <Box
            h="56px"
            borderRadius={'8px'}
            border={'1px dashed #B4B4B4'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            cursor={'pointer'}
            onClick={() => document.getElementById('upload').click()}
          >
            <Stack
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              gap={3}
            >
              <Box
                w="24px"
                h="24px"
                borderRadius={'50%'}
                border={'1px solid #1A1A1A'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                color="#1A1A1A"
              >
                <AddIcon fontSize={'12px'} />
              </Box>
              <Box marginTop={'0px !important'}>
                <Text fontSize={'18px'} fontWeight={'500'}>
                  Upload CSV File
                </Text>
              </Box>
              <Input
                onChange={handleFileChange}
                type="file"
                display="none"
                id="upload"
                accept=".csv"
              />
              <label htmlFor="upload">
                <Box
                  as="span"
                  display={'none'}
                  cursor="pointer"
                  padding="10px 20px"
                  borderRadius="8px"
                  border="1px solid #B4B4B4"
                  background="#F3F3F3"
                  _hover={{
                    background: '#E2E2E2',
                  }}
                >
                  Choose File
                </Box>
              </label>
              {file && <Text marginTop="10px">{file.name}</Text>}
            </Stack>
          </Box>
        </FormControl>
        <Stack>
          <CheckboxGroup>
            <Stack direction={'row'} gap={3}>
              {data?.map((item, index) => (
                <Checkbox
                  onChange={() => setSelectedService(item.id)}
                  isChecked={selectedService === item.id}
                >
                  <Text fontSize={'15px'} fontWeight={'500'}>
                    {item.label}
                  </Text>
                </Checkbox>
              ))}
            </Stack>
          </CheckboxGroup>
        </Stack>
        <Stack py={3}>
          <Button
            padding={'10px 50px'}
            type="submit"
            bg={
              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            h="45px"
            _hover={{
              color: '#000',
            }}
            isLoading={loading}
          >
            Continue
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

export default function Productlist() {
  const [selectedService, setSelectedService] = useState('all');
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    onOpen: onOpen2,
    onClose: onClose2,
    isOpen: isOpen2,
  } = useDisclosure();
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [results, setResults] = useState([]);
  const [Open, setOpen] = useState(false);
  const navigate = useNavigate();

  const itemsPerPage = 20;
  async function fetchData() {
    try {
      const response = await GET(
        `/admin/product?skip=${
          (currentPage - 1) * itemsPerPage
        }&limit=${itemsPerPage}`
      );
      console.log(response, 'response');

      if (response.status === 'success') {
        console.log('chalgya yeah');
        setData(response);
        setTotalItems(response?.count);
        // setSelectedService(Object.keys(response.data)[0]);
      } else {
        setData({});
      }
    } catch (error) {
      console.log(error);
    }
  }
  const fetchResults = async query => {
    console.log(query);
    if (!query) return;
    try {
      const response = await GET(
        `/store/product?storeId=${localStorage.getItem(
          'storeId'
        )}&searchQuery=${query}`
      );
      if (response.status === 'success') {
        console.log(response.data);
        setResults(response.data);
        setOpen(true);
      } else {
        console.log('Error while fetching results');
      }
    } catch (error) {
      console.log(error, 'error while fetching results');
    }
  };
  const debouncedFetchResults = useCallback(debounce(fetchResults, 300), []);

  const handleInputChange = e => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedFetchResults(query);
  };
  useEffect(() => {
    fetchData();
  }, [currentPage]);
  return (
    <Stack gap={6} px={4} pb={6}>
      <ModalWrapper
        isOpen={isOpen}
        onClose={onClose}
        title={'Add Bulk Products'}
        children={<AddProductCsv fetchData={fetchData} onClose={onClose} />}
        size={'lg'}
        isCentered={true}
      />
      <ModalWrapper
        isOpen={isOpen2}
        onClose={onClose2}
        title={'Edit Bulk Products'}
        children={<EditProductCsv fetchData={fetchData} onClose={onClose2} />}
        size={'lg'}
        isCentered={true}
      />
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
        alignItems={'center'}
      >
        <Stack>
          <Heading fontSize={'30px'} fontWeight={'700'}>
            Products
          </Heading>
          <Text fontSize={'15px'} color={'#1A1B1A'} fontWeight={'400'}>
            Here is the all user details...Check Now!
          </Text>
        </Stack>
        <Stack
          position={'relative'}
          w={{ base: '100%', xl: '450px', '2xl': '40%' }}
          height={'45px'}
          spacing={4}
        >
          <FormControl>
            <Input
              type="text"
              placeholder="Search here..."
              padding="24px 30px"
              bg="#EDF5EC"
              value={searchQuery}
              onChange={handleInputChange}
            />
            <Button
              bg="transparent"
              top="0"
              bottom="0"
              margin="auto"
              position="absolute"
              right={0}
              zIndex={1}
            >
              <Icon color="#197540" fontSize="20px" as={BsSearch} />
            </Button>
          </FormControl>
          {Open && (
            <Box position={'relative'}>
              <Menu isOpen={Open} onClose={() => setOpen(false)}>
                <MenuList
                  position="absolute"
                  minWidth={{
                    base: '90vw',
                    sm: '90vw',
                    lg: '95vw',
                    xl: '25vw',
                    '2xl': '23vw',
                  }}
                  maxH="300px"
                  overflowY="auto"
                  zIndex={9}
                >
                  {results.length > 0 ? (
                    results.map((result, index) => (
                      <MenuItem
                        onClick={() =>
                          navigate(`/dashboard/productdetails/${result._id}`)
                        }
                        key={index}
                      >
                        {result.name}{' '}
                        {/* Adjust this based on your API response */}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No results found</MenuItem>
                  )}
                </MenuList>
              </Menu>
            </Box>
          )}
        </Stack>
        <Stack direction={'row'}>
          <Button
            bg={
              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
            }
            border={'2px solid #07d57c'}
            padding={'10px 20px'}
            fontSize={'15px'}
            onClick={onOpen2}
            width={'max-content'}
          >
            Edit Bulk Products
          </Button>
          <Button
            bg={
              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
            }
            border={'2px solid #07d57c'}
            padding={'10px 20px'}
            fontSize={'15px'}
            onClick={onOpen}
            width={'max-content'}
          >
            Add Bulk Products
          </Button>
          <Button
            as={Link}
            to={'/dashboard/addproducts'}
            bg={
              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
            }
            border={'2px solid #07d57c'}
            padding={'10px 20px'}
            fontSize={'15px'}
            width={'max-content'}
          >
            Add New Product
          </Button>
        </Stack>
      </Stack>
      {!data ? (
        <Stack
          w={'100%'}
          h={'60vh'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Spinner />
        </Stack>
      ) : (
        <>
          {/* <Stack gap={2}> */}
          {/* <Heading fontSize={'18px'} fontWeight={'500'}>
              Categories:
            </Heading> */}
          {/* <Stack
              direction={'row'}
              alignItems={'center'}
              gap={4}
              flexWrap={'wrap'}
            >
              {Object.keys(data)?.map(item => (
                <Button
                  border={
                    selectedService === item
                      ? '2px solid #EAA13E'
                      : '2px solid #07d57c'
                  }
                  padding={'10px 25px'}
                  bg={
                    selectedService === item
                      ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                      : 'transparent'
                  }
                  width={'max-content'}
                  _hover={{
                    color: '#000',
                    bg:
                      selectedService === item
                        ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                        : 'transparent',
                  }}
                  onClick={() => setSelectedService(item)}
                >
                  {item}
                </Button>
              ))}
            </Stack> */}
          {/* </Stack> */}
          <Stack gap={2}>
            <Heading fontSize={'18px'} fontWeight={'500'}>
              You’ve {convertNumbertoCommaSeperate(data?.count)} products
            </Heading>
            <SimpleGrid columns={3} spacing={10}>
              {data?.data?.map(item => (
                <Stack borderRadius={8} gap={2} p={5} boxShadow="xl">
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    flexWrap={'wrap'}
                    alignItems={'center'}
                  >
                    <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                      Item No. {item.productId}
                    </Text>
                    <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                      Category:{' '}
                      {item.categories.map(item => item.name).join(', ')}
                    </Text>
                  </Box>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    flexWrap={'wrap'}
                    alignItems={'center'}
                  >
                    <Box display={'flex'} alignItems={'center'} gap={2}>
                      <Image
                        width={{
                          base: '50px',
                          md: '80px',
                          xl: '80px',
                          '2xl': '80px',
                        }}
                        src={
                          item?.images?.[0]?.path.includes('https')
                            ? item?.images?.[0]?.path
                            : `${imgUrl}${item?.images?.[0]?.path}`
                        }
                      />
                      <Box>
                        <Heading
                          textOverflow={'ellipsis'}
                          width={'170px'}
                          overflow={'hidden'}
                          whiteSpace={'nowrap'}
                          color={'#111'}
                          fontWeight={500}
                          fontSize={'16px'}
                        >
                          {item.name}
                        </Heading>
                        <Flex>
                          <Image src={Star} />
                          <Text color={'#989BA2'} fontSize={'14px'}>
                            (5.00)
                          </Text>
                        </Flex>
                      </Box>
                    </Box>
                    <Button
                      as={Link}
                      border={'2px solid #07d57c'}
                      padding={'10px 20px'}
                      bg={'transparent'}
                      width={'max-content'}
                      fontSize={'15px'}
                      to={`/dashboard/productdetails/${item._id}`}
                    >
                      View Item
                    </Button>
                  </Box>

                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    flexWrap={'wrap'}
                    alignItems={'center'}
                  >
                    <Box>
                      <Heading color={'#007341'} fontSize={'16px'}>
                        Price
                      </Heading>
                      <Text
                        color={'#1A1B1A'}
                        fontSize={'16px'}
                        fontWeight={600}
                      >
                        Rs : {item.price}
                      </Text>
                    </Box>
                    <Box>
                      <Heading color={'#007341'} fontSize={'16px'}>
                        Quantity
                      </Heading>
                      <Text
                        color={'#1A1B1A'}
                        fontSize={'16px'}
                        fontWeight={600}
                      >
                        {item.stock > 0 ? item.stock : 'Out of stock'}
                      </Text>
                    </Box>
                    <Box>
                      <Heading color={'#007341'} fontSize={'16px'}>
                        Threshold
                      </Heading>
                      <Text
                        color={'#1A1B1A'}
                        fontSize={'16px'}
                        fontWeight={600}
                      >
                        {item.threshold}
                      </Text>
                    </Box>
                  </Box>
                </Stack>
              ))}
            </SimpleGrid>
            {totalItems > itemsPerPage && (
              <Stack bg={'#fff'} px={4} py={4} borderRadius={12} mt={4}>
                <Pagination
                  totalItems={totalItems}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                />
              </Stack>
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
}
