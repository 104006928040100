import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  Heading,
  FormControl,
  Radio,
  Icon,
  Input,
  Select,
  Stack,
  Text,
  Textarea,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Image,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import React, { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { GET, POST } from '../../../utilities/ApiProvider';
import { MultiSelect } from 'react-multi-select-component';
import { se } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';

function AddDiscount() {

  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedSub, setSelectedSub] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [data, setData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [product, setProduct] = useState([]);
  const toast = useToast();
  const navigate = useNavigate();

  async function fetchCategories() {
    try {
      const response = await GET('/admin/groceries');
      console.log(response);
      if (response.status === 'success') {
        setData(response.data);
        setCategories(
          response.data.map(item => ({
            value: item._id,
            label: item.name,
            subCategories: item.children,
          }))
        );
      } else {
        setCategories([]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (selected.length > 0 && selectedSub.length > 0) {
      let temp = [...selected, ...selectedSub];
      fetchProducts(temp);
    }
    async function fetchProducts(temp) {
      if (temp.length === 0) return;
      try {
        let check = temp.map(item => item.value).join(',');
        console.log(check);
        const response = await GET(
          `/admin/product?subcategories=${check}&isDiscount=true`
        );
        console.log(response);
        if (response.status === 'success') {
          setProduct(
            response.data?.map(item => ({
              value: item._id,
              label: item.name,
            }))
          );
        } else {
          setProduct([]);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [selected, selectedSub]);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      let temp = [...selected, ...selectedSub];
      formData.append('storeId', '66792cc177d1c268a6bf34af');
      formData.append(
        'categories',
        JSON.stringify(temp.map(item => item.value))
      );
      formData.append(
        'products',
        JSON.stringify(selectedProduct?.map(item => item.value))
      );
      formData.append('image', selectedFile);
      if (Array.from(formData.entries()).some(([key, value]) => value === '')) {
        toast({
          description: 'Please Fill All Details',
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      const response = await POST('/admin/discount', formData);
      console.log(response);
      if (response.status === 'success') {
        toast({
          description: 'You Discount has been added successfully',
          status: 'success',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
        e.target.reset();
        setSelected([]);
        setSelectedSub([]);
        setSelectedProduct([]);
        setSelectedFile(null);
        navigate('/dashboard/discounts');
      } else {
        toast({
          description: 'Please Try Again',
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Stack>
      <Stack display={'flex'} flexDirection={'column'}>
        <Heading fontSize={'30px'} fontWeight={'700'}>
          Add New Discount
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the all details to add new discount...Check Now!
        </Text>
      </Stack>

      <form onSubmit={handleSubmit}>
        <Stack direction={'row'} gap={6} justifyContent={'space-between'}>
          <Stack width={'full'} gap={3}>
            <FormControl>
              <FormLabel
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'30px'}
              >
                Discount Name
              </FormLabel>
              <Input
                background={'#F3F3F3'}
                border={'1px solid #B4B4B4'}
                py={6}
                type="text"
                name="name"
                placeholder="Enter Your Name"
              />
            </FormControl>
            <Box display={'flex'} gap={3}>
              <FormControl>
                <FormLabel
                  fontSize={'16px'}
                  fontWeight={'500'}
                  lineHeight={'30px'}
                >
                  Discount Rs.
                </FormLabel>
                <Input
                  background={'#F3F3F3'}
                  border={'1px solid #B4B4B4'}
                  py={6}
                  type="text"
                  name="price"
                  placeholder="Enter Your Name"
                />
              </FormControl>

              <FormControl>
                <FormLabel
                  fontSize={'16px'}
                  fontWeight={'500'}
                  lineHeight={'30px'}
                >
                  Discount %
                </FormLabel>
                <Input
                  background={'#F3F3F3'}
                  border={'1px solid #B4B4B4'}
                  py={6}
                  name="unit"
                  type="text"
                  placeholder="Enter Your Name"
                />
              </FormControl>
            </Box>
            <FormControl>
              <FormLabel
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'30px'}
              >
                Discount Code
              </FormLabel>
              <Input
                background={'#F3F3F3'}
                border={'1px solid #B4B4B4'}
                py={6}
                name="code"
                type="text"
                placeholder="Enter Your Name"
              />
            </FormControl>
            <Box display={'flex'} gap={3}>
              <FormControl>
                <FormLabel
                  fontSize={'16px'}
                  fontWeight={'500'}
                  lineHeight={'30px'}
                >
                  Time Frame
                </FormLabel>
                <Input
                  background={'#F3F3F3'}
                  border={'1px solid #B4B4B4'}
                  py={6}
                  name="start"
                  type="date"
                  placeholder="Enter Your Name"
                />
              </FormControl>

              <FormControl display={'flex'} alignItems={'end'}>
                {/* <FormLabel fontSize={'16px'} fontWeight={'500'} lineHeight={'30px'}>Choose Time</FormLabel> */}
                <Input
                  background={'#F3F3F3'}
                  border={'1px solid #B4B4B4'}
                  py={6}
                  name="end"
                  type="date"
                  placeholder="Enter Your Name"
                />
              </FormControl>
            </Box>
            <FormControl>
              <FormLabel
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'30px'}
              >
                No. of Users
              </FormLabel>
              <Input
                background={'#F3F3F3'}
                border={'1px solid #B4B4B4'}
                py={6}
                name="numberOfUser"
                type="text"
                placeholder="Enter Your Name"
              />
            </FormControl>
           
            <Box my={4}>
              <Button
                mb={2}
                bg={
                  'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
                }
                type="submit"
                w="176px"
                h="49px"
                borderRadius={'9px'}
              >
                Continue
              </Button>
            </Box>
          </Stack>
          <Stack  gap={4} pr={6} width={'full'}>
          <Box>
              <Text fontSize={'18px'} fontWeight={'600'} mb={3} pl={1}>
                Choose Category
              </Text>

              <MultiSelect
              
                options={categories}
                value={selected}
                onChange={setSelected}
                hasSelectAll={false}
                placeholder="Select Categories"
              />
            </Box>
            <Box>
              <Text fontSize={'18px'} fontWeight={'600'} mb={3} pl={1}>
                Sub Category
              </Text>
              <MultiSelect
                width="100%"
                height="62px"
                options={selected?.flatMap(item =>
                  item.subCategories.map(val => ({
                    value: val._id,
                    label: val.name,
                  }))
                )}
                value={selectedSub}
                onChange={setSelectedSub}
                hasSelectAll={false}
                placeholder="Select Categories"
              />
            </Box>

            <Box>
              <Text fontSize={'18px'} fontWeight={'600'} mb={3} pl={1}>
                Choose Item
              </Text>
              <MultiSelect
                width="100%"
                height="62px"
                options={product}
                value={selectedProduct}
                onChange={setSelectedProduct}
                hasSelectAll={false}
                placeholder="Select Categories"
              />
            </Box>
            <FormControl
              onClick={() => document.getElementById('upload').click()}
            >
              <FormLabel
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'30px'}
              >
                Discount Rs.
              </FormLabel>
              <Button border="1px dashed #75767A" w="100%" h="89px">
                Attach File
              </Button>
              <Input
                onChange={e => setSelectedFile(e.target.files[0])}
                type="file"
                id="upload"
                display={'none'}
              />
              {selectedFile && (
                <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={'500'}>
                  {selectedFile.name}
                </Text>
              )}
            </FormControl>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
}

export default AddDiscount;
