import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Grid,
  Stack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Tag,
  Icon,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Image,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { dateTime } from '../../../assets/data/Data';
import { IoTimeOutline } from 'react-icons/io5';
import Cele from '../../../assets/images/website/cele.png';
import { GET, POST } from '../../../utilities/ApiProvider';
import moment from 'moment';
import ModalWrapper from '../../../components/Modal/index';

function AddDeliveryTime({ data, onClose, fetchDeliveryData }) {
  const toast = useToast();
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      console.log(Array.from(formData.entries()));
      const datas = {
        storeId: '66792cc177d1c268a6bf34af',
        deliveryFee: [
          {
            day: data?.day,
            time: [
              {
                start: moment(formData.get('startTime'), 'HH:mm').format(
                  'HH:mm A'
                ),
                end: moment(formData.get('endTime'), 'HH:mm').format('HH:mm A'),
                fee: parseFloat(formData.get('deliveryFee')),
              },
            ],
            faq: [
              {
                question: formData.get('question1'),
                answer: formData.get('answer1'),
              },
              {
                question: formData.get('question2'),
                answer: formData.get('answer2'),
              },
            ],
          },
        ],
      };
      const response = await POST('/store/delivery', datas);
      if (response.status === 'success') {
        toast({
          description: 'Delivery time added successfully',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        onClose();
        fetchDeliveryData();
      } else {
        toast({
          description: `${response.message}`,
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Stack display={'flex'} flexDirection={'column'} gap={2}>
        <FormControl>
          <FormLabel fontSize={'15px'}>Start Time</FormLabel>
          <Input
            py={6}
            name="startTime"
            type="time"
            placeholder="Enter Start Time"
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize={'15px'}>End Time</FormLabel>
          <Input
            py={6}
            name="endTime"
            type="time"
            placeholder="Enter End Time"
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize={'15px'}>Delivery Fee</FormLabel>
          <Input
            py={6}
            name="deliveryFee"
            type="text"
            placeholder="Enter Delivery Fee"
          />
        </FormControl>

        <FormControl>
          <FormLabel fontSize={'15px'}>Question #01</FormLabel>
          <Input
            py={6}
            name="question1"
            type="text"
            placeholder="Enter End Time"
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize={'15px'}>Answer</FormLabel>
          <Textarea
            resize={'none'}
            h={'100px'}
            name="answer1"
            py={6}
            type="text"
            placeholder="Enter Answer"
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize={'15px'}>Question #02</FormLabel>
          <Input
            py={6}
            name="question2"
            type="text"
            placeholder="Enter End Time"
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize={'15px'}>Answer</FormLabel>
          <Textarea
            resize={'none'}
            h={'100px'}
            py={6}
            type="text"
            name="answer2"
            placeholder="Enter Answer"
          />
        </FormControl>

        <Stack mt={'10px !important'}>
          <Button
            bg={
              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
            }
            type="submit"
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            h="45px"
            _hover={{
              color: '#000',
            }}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

function Delivery() {
  const [datelist, setdatelist] = useState(dateTime);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [delivery, setDelivery] = useState(null);
  const [day, setDay] = useState('');
  async function fetchDeliveryData() {
    try {
      const response = await GET(
        `/store/delivery/66792cc177d1c268a6bf34af?currentDate=${moment().format(
          'YYYY-MM-DD'
        )}`
      );
      console.log(response);
      if (response.status === 'success') {
        setDelivery(response.data[0]?.deliveryFee);
      } else {
        setDelivery([]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchDeliveryData();
  }, []);
  return (
    <Stack px={6} py={2}>
      <ModalWrapper
        isOpen={isOpen}
        onClose={onClose}
        title={'Add New Delivery Time'}
        children={
          <AddDeliveryTime
            data={day}
            onClose={onClose}
            fetchDeliveryData={fetchDeliveryData}
          />
        }
        size={'lg'}
        isCentered={true}
      />
      <Stack
        mb={2}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Stack display={'flex'} flexDirection={'column'}>
          <Heading fontSize={'30px'} fontWeight={'600'}>
            Delivery Fee
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the all delivery options...Check Now!
          </Text>
        </Stack>
        {/* <Button
          padding={'10px 50px'}
          bg={
            'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
          }
          color={'#fff'}
          fontWeight={'500'}
          lineHeight={'inherit'}
          borderRadius={12}
          _hover={{
            color: '#000',
          }}
        >
          Add New
        </Button> */}
      </Stack>
      <Stack>
        {!delivery ? (
          <Stack h={'60vh'} alignItems={'center'} justifyContent={'center'}>
            <Spinner />
          </Stack>
        ) : (
          <Accordion allowToggle>
            {delivery?.map((item, index) => (
              <AccordionItem
                mb={3}
                border={'1px solid #B4B4B4'}
                background={'#F3F3F3'}
                borderRadius={'8px'}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  py={4}
                >
                  <Stack
                    w="100%"
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Box px={4}>
                      <Heading fontSize={'22px'} fontWeight={'600'}>
                        {item.day}
                      </Heading>
                    </Box>
                    <Box px={4}>
                      <Button
                        bg={
                          'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
                        }
                        w="max-content"
                        color={'#fff'}
                        fontWeight={'500'}
                        lineHeight={'inherit'}
                        borderRadius={12}
                        _hover={{
                          color: '#000',
                        }}
                        onClick={() => {
                          setDay(item);
                          onOpen();
                        }}
                      >
                        Add New
                      </Button>
                    </Box>
                  </Stack>

                  <AccordionButton width={'max-content'}>
                    <AccordionIcon />
                  </AccordionButton>
                </Box>
                <AccordionPanel>
                  <Grid templateColumns={'repeat(2,1fr)'} gap={4}>
                    {item?.time?.map((time, index) => (
                      <>
                        <Stack>
                          <Box
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            borderRadius={8}
                            border={'1px solid #B4B4B4'}
                            height={'62px'}
                            px={6}
                          >
                            <Stack>
                              <Text fontSize={'18px'} fontWeight={500}>
                                Select Time:
                              </Text>
                            </Stack>
                            <HStack gap={1}>
                              <Button
                                bg={
                                  'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
                                }
                                w="max-content"
                                color={'#fff'}
                                fontWeight={'500'}
                                lineHeight={'inherit'}
                                borderRadius={12}
                                _hover={{
                                  color: '#000',
                                }}
                              >
                                {time.start}
                              </Button>
                              <Button
                                bg={
                                  'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
                                }
                                w="max-content"
                                color={'#fff'}
                                fontWeight={'500'}
                                lineHeight={'inherit'}
                                borderRadius={12}
                                _hover={{
                                  color: '#000',
                                }}
                              >
                                {time.end}
                              </Button>
                            </HStack>
                          </Box>
                        </Stack>
                        <Stack>
                          <Box
                            borderRadius={8}
                            border={'1px solid #B4B4B4'}
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            px={6}
                            height={'62px'}
                          >
                            <Text fontSize={'18px'} fontWeight={500}>
                              Delivery Fee:
                            </Text>
                            <Text
                              fontSize={'18px'}
                              fontWeight={600}
                              color={'#007341'}
                            >
                              Rs. {time?.fee || '100.00'}
                            </Text>
                          </Box>
                        </Stack>
                      </>
                    ))}
                  </Grid>
                  <Text py={4} fontSize={'22px'} fontWeight={600}>
                    Question
                  </Text>
                  <Grid templateColumns={'repeat(2,1fr)'} gap={4}>
                    {item?.faq?.map((faq, index) => (
                      <Box display={'flex'} flexDirection={'column'} gap={3}>
                        <Text fontSize={'16px'} fontWeight={500}>
                          Question / Answer (1)
                        </Text>
                        <Box
                          fontSize={'18px'}
                          fontWeight={500}
                          display={'flex'}
                          alignItems={'center'}
                          borderRadius={8}
                          border={'1px solid #B4B4B4'}
                          height={'62px'}
                          px={6}
                        >
                          {faq.question ||
                            ' Can I cancel or amend a same day delivery?'}
                        </Box>
                        <Box
                          py={2}
                          fontSize={'18px'}
                          fontWeight={500}
                          borderRadius={8}
                          border={'1px solid #B4B4B4'}
                          height={'104px'}
                          px={6}
                        >
                          {faq?.answer ||
                            'Once you have placed your Same Day order you won’t be able to edit it'}
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        )}
      </Stack>
    </Stack>
  );
}

export default Delivery;
