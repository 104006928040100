// src/Pagination.js
import React, { useState, useEffect } from 'react';
import { Button, Input, Flex, Box } from '@chakra-ui/react';

const Pagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
  isLoading,
}) => {
  const [inputPage, setInputPage] = useState(currentPage);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    setInputPage(currentPage);
  }, [currentPage]);

  const handlePageChange = page => {
    if (page < 1) page = 1;
    if (page > totalPages) page = totalPages;
    onPageChange(page);
  };

  const handleInputChange = e => {
    setInputPage(Number(e.target.value));
  };

  const handleInputBlur = () => {
    handlePageChange(inputPage);
  };

  return (
    <Flex alignItems="center" justifyContent="center">
      <Button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1 || isLoading}
        mr="2"
      >
        Previous
      </Button>
      <Box>
        <Input
          type="number"
          value={inputPage}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          min="1"
          max={totalPages}
          width="60px"
          textAlign="center"
          mr="2"
        />
        <span> of {totalPages}</span>
      </Box>
      <Button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages || isLoading}
        ml="2"
      >
        Next
      </Button>
    </Flex>
  );
};

export default Pagination;
