import {
  Heading,
  Stack,
  Text,
  SimpleGrid,
  Box,
  Button,
  UnorderedList,
  ListItem,
  Image,
  Avatar,
  Flex,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import P1 from '../../../assets/images/website/p1.jpg';
import P2 from '../../../assets/images/website/p2.jpg';
import Product from '../../../assets/images/website/singleproduct.jpg';

import Star from '../../../assets/images/website/star.svg';
import { Link, useParams } from 'react-router-dom';
import { DELETE, GET } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';

export default function SingleProductDetails() {
  const [isLoading, setIsLoading] = useState(false);
  
  const [Orderdetails, setOrderdetails] = useState([]);
  const [selectedImage, setSelectedImage] = useState(0);
  const toast = useToast();
  const { id } = useParams();
  const storeId = localStorage.getItem('storeId');
  useEffect(() => {
    fetchOrderDetails();
  }, []);

  async function fetchOrderDetails() {
    setIsLoading(true);
    try {
      const response = await GET(`/store/product/${id}?storeId=${storeId}`);

      if (response.status === 'success') {
        console.log('Products Details', response.data);
        setOrderdetails(response.data);
        setIsLoading(false);
      } else {
        console.log('Error while fetching products');
      }
    } catch (error) {
      console.log(error, 'error while fetching products');
    }
  }

  async function handleRemoveReview(reviewId) {
   
    try {
      const response = await DELETE(`/admin/review/${reviewId}`);

      if (response.status === 'success') {
        const { reviews } = Orderdetails;
        reviews.forEach((review, index) => {
          if (review._id === reviewId) {
            reviews.splice(index, 1);
          }
        });
        
        setOrderdetails({ ...Orderdetails, reviews });
        toast({
          description: 'Review has been removed successfully',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
      } else {
        toast({
          description: 'Error while removing review',
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
        console.log('Error while removing review');
      }
    } catch (error) {
      console.log(error, 'error while removing review');
    }
  }

  return (
    <Stack gap={6} px={4} pb={6}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
        alignItems={'center'}
      >
        <Stack>
          <Heading fontSize={'30px'} fontWeight={'700'}>
            Products Details
          </Heading>
          <Text fontSize={'15px'} color={'#1A1B1A'} fontWeight={'400'}>
            Here is the all user details...Check Now!
          </Text>
        </Stack>
        <Button
          as={Link}
          to={`/dashboard/edit/product/${id}`}
          bg={
            'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
          }
          border={'2px solid #07d57c'}
          padding={'10px 20px'}
          fontSize={'15px'}
          width={'max-content'}
        >
          Edit Item
        </Button>
      </Stack>
      {isLoading ? (
        <Box
          display={'flex'}
          height={'80vh'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Spinner color="#47A046" />
        </Box>
      ) : (
        <Stack>
          <Box display={'flex'} flexWrap={'wrap'} gap={6} alignItems={'center'}>
            <Stack width={'30%'} gap={3}>
              <Stack>
                <Image
                 
                  src={
                    Orderdetails?.images?.[0]?.path.includes('https')
                      ? Orderdetails?.images?.[0]?.path
                      : `${imgUrl}${Orderdetails?.images?.[0]?.path}`
                  }
                />
                <Box display={'flex'} gap={3}>
                  {Orderdetails?.images?.map((image, ind) => (
                    <Box cursor={'pointer'}>
                      
                    </Box>
                  ))}
                </Box>
              </Stack>
            </Stack>
            <Stack width={'60%'} gap={3} pr={4}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Stack>
                  <Heading
                    color={'#007341'}
                    fontSize={{ base: '20px', xl: '23px', '2xl': '23px' }}
                  >
                    {Orderdetails?.name}
                  </Heading>
                  <Flex gap={4}>
                    <Text>
                      <Text as={'span'} fontWeight={600}>
                        SKU:
                      </Text>
                      {Orderdetails?.sku}
                    </Text>
                    <Text>
                      <Text as={'span'} fontWeight={600}>
                        Category:
                      </Text>
                      {Orderdetails?.categories
                        ?.map(category => category.name)
                        ?.join(', ')}
                    </Text>
                  </Flex>
                </Stack>
                <Heading color={'#007341'} fontSize={'25px'}>
                  Rs. {Orderdetails?.price}
                </Heading>
              </Box>
              <Stack>
                <Heading color={'#04ab62'} fontSize={'20px'}>
                  Description
                </Heading>
                <Text>{Orderdetails?.description}</Text>
              </Stack>

              <UnorderedList listStyleType={'none'}>
                <ListItem>
                  <Text>100% Money Back Warranty</Text>
                </ListItem>
                <ListItem>
                  <Text>Free and Fast Delivery</Text>
                </ListItem>
                <ListItem>
                  <Text>All Items Top Best Quality</Text>
                </ListItem>
                <ListItem>
                  <Text>24/7 Support</Text>
                </ListItem>
              </UnorderedList>
            </Stack>
          </Box>
          <Stack>
            <Heading fontSize={'20px'} fontWeight={'700'}>
              Item Reviews:
            </Heading>
            <SimpleGrid columns={3} spacing={10}>
              {Orderdetails?.reviews?.length > 0 ? (
                Orderdetails?.reviews?.map(review => (
                  <Stack borderRadius={8} gap={2} p={5} boxShadow="xl">
                    <Box>
                      <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        flexWrap={'wrap'}
                        alignItems={'center'}
                      >
                        <Box display={'flex'} gap={2} alignItems={'center'}>
                          <Avatar
                            size="lg"
                            name={review.name}
                            src={review?.image}
                          />
                          <Box>
                            <Heading
                              color={'#111'}
                              fontWeight={700}
                              fontSize={'16px'}
                            >
                              {review.name}
                            </Heading>
                            <Flex>
                              <Image src={Star} />
                            </Flex>
                          </Box>
                        </Box>
                        <Button
                          border={'2px solid #07d57c'}
                          padding={'10px 20px'}
                          bg={'transparent'}
                          width={'max-content'}
                          fontSize={'15px'}
                          
                          onClick={() => handleRemoveReview(review._id)}
                        >
                          Remove Review
                        </Button>
                      </Box>
                    </Box>
                    <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                      {review.feedback}
                    </Text>
                  </Stack>
                ))
              ) : (
                <Text>No Reviews Yet</Text>
              )}
            </SimpleGrid>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
