import React, { useEffect, useState } from 'react';
import {
  Stack,
  Heading,
  Text,
  HStack,
  Box,
  VStack,
  Avatar,
  Button,
  Spinner,
  GridItem,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';
import Pagination from '../../../utilities/Pagination';

function SellingItems() {
  const [totalItem, setTotalItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;

  async function fetchData() {
    setIsLoading(true);
    try {
      const storeId = localStorage.getItem('storeId');
      const response = await GET(
        `/admin/product?storeId=${storeId}&bestSeller=true&skip=${
          (currentPage - 1) * itemsPerPage
        }&limit=${itemsPerPage}`
      );

      if (response.status === 'success') {
        setTotalItem(response);
        setTotalItems(response?.count);
        setIsLoading(false);
      } else {
        setTotalItem({});
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  return (
    <Stack>
      <Stack display={'flex'} flexDirection={'column'}>
        <Heading fontSize={'30px'} fontWeight={'700'}>
          Most Selling Items
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the your most selling items...Check Now!
        </Text>
      </Stack>
      {!totalItem ? (
        <Box
          display={'flex'}
          height={'80vh'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Spinner color="#47A046" />
        </Box>
      ) : (
        <>
          <Box>
            <Stack display={'flex'} flexDirection={'column'} gap={2}>
              <HStack mt={6} display={'flex'} w="100%" px={4}>
                <Box
                  fontSize="15px"
                  fontWeight={'400'}
                  color="#75767A"
                  flex={2}
                >
                  Name
                </Box>
                <Box
                  fontSize="15px"
                  fontWeight={'400'}
                  color="#75767A"
                  flex={1}
                >
                  Item Sold
                </Box>

                <Box
                  fontSize="15px"
                  fontWeight={'400'}
                  color="#75767A"
                  flex={1}
                >
                  Price
                </Box>
                <Box
                  fontSize="15px"
                  fontWeight={'400'}
                  color="#75767A"
                  flex={1}
                >
                  Categories
                </Box>
                <Box
                  fontSize="15px"
                  fontWeight={'400'}
                  color="#75767A"
                  flex={1}
                >
                  More Info
                </Box>
              </HStack>
              {totalItem?.data?.map((v, index) => (
                <HStack
                  display={'flex'}
                  w="100%"
                  key={index}
                  h="70px"
                  bg={'#fff'}
                  shadow={'0px 16px 38px 0px #0000000F'}
                  px={4}
                  borderRadius={'16px'}
                >
                  <Box
                    fontSize="15px"
                    fontWeight={'600'}
                    color="#1B1C1D"
                    flex={2}
                  >
                    {v?.name}
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'600'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    {v?.orderDetails?.totalQuantitySold} Times
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'600'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    {v?.price}
                  </Box>
                  <Box
                    fontSize="15px"
                    fontWeight={'600'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    {v?.categories[0]?.name}
                  </Box>

                  <Box
                    fontSize="15px"
                    fontWeight={'500'}
                    color="#1B1C1D"
                    flex={1}
                  >
                    <Button
                      padding={'10px 50px'}
                      bg={
                        'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                      }
                      color={'#fff'}
                      fontWeight={'500'}
                      lineHeight={'inherit'}
                      borderRadius={12}
                      as={Link}
                      to={`/dashboard/productdetails/${v?._id}`}
                      _hover={{
                        color: '#000',
                      }}
                      w={'fit-content'}
                    >
                      View Details
                    </Button>
                  </Box>
                </HStack>
              ))}
            </Stack>
          </Box>

          {totalItems > itemsPerPage && (
            <Stack bg={'#fff'} px={4} py={4} borderRadius={12} mt={4}>
              <Pagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
}

export default SellingItems;
