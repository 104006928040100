import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  Avatar,
  Button,
  VStack,
  Divider,
  useDisclosure,
  Spinner,
  Image,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';
import { RiCoinLine } from 'react-icons/ri';
import moment from 'moment';
import { useSelector } from 'react-redux';

Chart.register(CategoryScale);
export default function DashboardHome() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [data, setData] = useState(null);
  const user = useSelector(state => state.user.value);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const url =
          user?.role === 'manager'
            ? `/users/managerHome?storeId=${user?.storeId}`
            : '/admin/dashboard';
        const response = await GET(url);
        console.log(response);
        if (response.status === 'success') {
          setData(response.data);
          setIsLoading(false);
        } else {
          setData({});
        }
      } catch (error) {
        console.log(error);
      }
    }
    getData();
  }, []);
  const Data = [
    {
      id: 1,
      year: 'Mon',
      userGain: 80000,
      userLost: 823,
    },
    {
      id: 2,
      year: 'Tues',
      userGain: 45677,
      userLost: 345,
    },
    {
      id: 3,
      year: 'Wed',
      userGain: 78888,
      userLost: 555,
    },
    {
      id: 4,
      year: 'Thurs',
      userGain: 90000,
      userLost: 4555,
    },
    {
      id: 5,
      year: 'Fri',
      userGain: 4300,
      userLost: 234,
    },
    {
      id: 5,
      year: 'Sat',
      userGain: 4300,
      userLost: 234,
    },
    {
      id: 5,
      year: 'Sun',
      userGain: 4300,
      userLost: 234,
    },
  ];

  function handleOpen(modalData) {
    setModalData(modalData);
    onOpen();
  }

  return (
    <Stack overflowY="hidden">
      <MainDashboard>
        <Stack
          overflowY="auto"
          margin={'0 !important'}
          gap={8}
          borderRadius={'30px 0 0 0'}
          py={10}
          px={6}
          bg={'#FBFBFB'}
          height={'100%'}
          bgPos={'-32px -100px'}
        >
          {/*Heading  */}
          <Stack display={'flex'} flexDirection={'column'}>
            <Heading fontSize={'30px'} fontWeight={'600'}>
              Dashboard
            </Heading>
            <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
              Here is the discount details...Check Now!
            </Text>
          </Stack>
          {isLoading ? (
            <Box
              display={'flex'}
              height={'80vh'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Spinner color="#47A046" />
            </Box>
          ) : (
            <>
              <Stack mt={'10px'}>
                {user?.role !== 'manager' && (
                  <>
                    <Grid templateColumns="repeat(4, 1fr)" gap={6} mb={6}>
                      {data?.['AnalyticsData']?.map((val, ind) => (
                        <Box
                          key={ind}
                          p={5}
                          shadow="md"
                          borderWidth="1px"
                          height={'219px'}
                          borderRadius="16px"
                          bg={
                            ind === 0 &&
                            'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                          }
                          display={'flex'}
                          flexDirection={'column'}
                          gap={'15px'}
                        >
                          <HStack>
                            <IconButton
                              isRound={true}
                              variant="solid"
                              colorScheme="#F2F2F2"
                              bg={'#F2F2F2'}
                              aria-label="Done"
                              fontSize="20px"
                              icon={
                                <RiCoinLine
                                  color={ind === 0 ? '#8ABD3D' : '#1A1B1A'}
                                />
                              }
                            />
                            <Text
                              fontSize={'16px'}
                              color={ind === 0 ? '#F2F2F2' : '#75767A'}
                            >
                              {val?.title || 'Total Revenue'}
                            </Text>
                          </HStack>
                          <Stack>
                            <Heading
                              as="h1"
                              fontWeight={'500'}
                              fontSize={'40px'}
                              color={ind === 0 && '#F2F2F2'}
                            >
                              Rs {val?.value || '9,251.00'}
                            </Heading>
                            {ind === 3 ? (
                              <Stack flexDirection={'column'}>
                                <HStack>
                                  <Button
                                    padding={{
                                      md: '10px 15px',
                                      '2xl': '10px 50px',
                                    }}
                                    fontSize={{ md: '13px', '2xl': '16px' }}
                                    bg={'transparent'}
                                    color={'#1A1B1A'}
                                    as={Link}
                                    to={'/dashboard/most-selling-items'}
                                    fontWeight={'500'}
                                    lineHeight={'inherit'}
                                    borderRadius={12}
                                    border={'1px solid #1A1B1A'}
                                    _hover={{
                                      color: '#000',
                                    }}
                                    w={'100%'}
                                    textAlign={'center'}
                                  >
                                    View Items
                                  </Button>
                                </HStack>
                              </Stack>
                            ) : (
                              <Text
                                fontSize={'15px'}
                                color={ind === 0 ? '#F2F2F2' : '#75767A'}
                                fontWeight={'400'}
                              >
                                Here is the latest update...Check Now!
                              </Text>
                            )}
                          </Stack>
                        </Box>
                      ))}
                    </Grid>
                    <Grid templateColumns="repeat(4, 1fr)" gap={6}>
                      <GridItem
                        colSpan={3}
                        className="chart-container"
                        position={'relative'}
                      >
                        <Heading fontWeight={'500'} fontSize={'25px'}>
                          User Statistics
                        </Heading>
                        <Bar
                          options={{
                            plugins: {
                              legend: {
                                display: true,
                                position: 'top',
                                labels: {
                                  font: {
                                    size: 14,
                                    weight: 'italic',
                                    padding: 10,
                                  },
                                },
                              },
                            },
                            scales: {
                              x: {
                                grid: {
                                  display: false,
                                },
                              },
                              y: {
                                grid: {
                                  display: false,
                                },
                              },
                            },
                          }}
                          height={'100%'}
                          data={{
                            labels: Data.map(data => data.year),
                            title: 'Sales Analytics',
                            datasets: data?.['chartOne'] || [],
                          }}
                        />
                      </GridItem>

                      <Stack
                        py={4}
                        height={'284px'}
                        display={'flex'}
                        alignItems={'center'}
                      >
                        <Heading fontWeight={'500'} fontSize={'25px'}>
                          Top Rated Categories
                        </Heading>
                        {data?.['pieChart'] ? (
                          <Box
                            bg={'#fff'}
                            h="275px"
                            borderRadius="16px"
                            display={'flex'}
                            flexDirection={'column'}
                          >
                            <Doughnut data={data?.['pieChart']} />
                          </Box>
                        ) : (
                          <Box>
                            <Spinner />
                          </Box>
                        )}
                      </Stack>
                    </Grid>
                  </>
                )}
                {user?.role === 'manager' && (
                  <Grid templateColumns="repeat(4, 1fr)" gap={6}>
                    <GridItem
                      colSpan={4}
                      className="chart-container"
                      position={'relative'}
                    >
                      <Stack
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                        mb={4}
                      >
                        <Heading fontWeight={'600'} fontSize={'28px'} mb={2}>
                          Best Selling Items
                        </Heading>
                        <Text
                          cursor={'pointer'}
                          fontSize={'16px'}
                          fontWeight={'400'}
                          color={'#75767A'}
                          as={Link}
                          to={`/dashboard/most-selling-items`}
                        >
                          View All
                        </Text>
                      </Stack>

                      <Box
                        py={4}
                        borderRadius={'17px'}
                        shadow={'0px 17.048px 40.488px 0px rgba(0, 0, 0, 0.06)'}
                      >
                        {data?.['Best Selling Items']?.['product']
                          ?.slice(0, 3)
                          ?.map((val, ind) => (
                            <Box px={4}>
                              <Stack
                                display={'flex'}
                                flexDirection={'row'}
                                gap={3}
                                justifyContent={'space-between'}
                                alignItems={'start'}
                              >
                                <Stack flex={2} direction={'row'} gap={2}>
                                  <Image src={val?.url} w="66px" h="64px" />
                                  <Stack direction={'column'}>
                                    <Text
                                      color="#007341"
                                      fontSize="16px"
                                      fontWeight={'700'}
                                    >
                                      {val?.name || 'Spring Onions 1 bunch'}
                                    </Text>
                                    <Stack
                                      direction={'row'}
                                      alignItems={'center'}
                                      lineHeight={'0px'}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="82"
                                        height="13"
                                        viewBox="0 0 82 13"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0_356_94)">
                                          <path
                                            d="M8.27052 10.353L12.098 12.7808L11.0823 8.19766L14.4639 5.11336L10.0171 4.71699L8.27052 0.394012L6.53638 4.71699L2.07715 5.11336L5.45873 8.19766L4.44302 12.7808L8.27052 10.353Z"
                                            fill="#F8C519"
                                          />
                                          <path
                                            d="M24.7813 10.353L28.6088 12.7808L27.5931 8.19766L30.9746 5.11336L26.5278 4.71699L24.7813 0.394012L23.0471 4.71699L18.5879 5.11336L21.9695 8.19766L20.9538 12.7808L24.7813 10.353Z"
                                            fill="#F8C519"
                                          />
                                          <path
                                            d="M41.2998 10.353L45.1273 12.7808L44.1116 8.19766L47.4932 5.11336L43.0464 4.71699L41.2998 0.394012L39.5657 4.71699L35.1064 5.11336L38.488 8.19766L37.4723 12.7808L41.2998 10.353Z"
                                            fill="#F8C519"
                                          />
                                          <path
                                            d="M57.8154 10.353L61.643 12.7808L60.6272 8.19766L64.0088 5.11336L59.562 4.71699L57.8154 0.394012L56.0813 4.71699L51.6221 5.11336L55.0037 8.19766L53.9879 12.7808L57.8154 10.353Z"
                                            fill="#F8C519"
                                          />
                                          <path
                                            d="M74.3301 10.353L78.1576 12.7808L77.1419 8.19766L80.5235 5.11336L76.0766 4.71699L74.3301 0.394012L72.5959 4.71699L68.1367 5.11336L71.5183 8.19766L70.5026 12.7808L74.3301 10.353Z"
                                            fill="#F8C519"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_356_94">
                                            <rect
                                              width="82.599"
                                              height="12.3867"
                                              fill="white"
                                              transform="matrix(1 0 0 -1 0 12.781)"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                      <span>(5.00)</span>
                                    </Stack>
                                  </Stack>
                                </Stack>
                                <Stack
                                  m="0 !important"
                                  flex={1}
                                  direction={'column'}
                                  gap={2}
                                >
                                  <Text fontSize="16px" fontWeight={'500'}>
                                    Price
                                  </Text>
                                  <Text
                                    fontSize="16px"
                                    fontWeight={'700'}
                                    color="#007341"
                                    lineHeight={'0px'}
                                  >
                                    Rs : {val?.price?.toFixed(2) || '25.00'}
                                  </Text>
                                </Stack>
                                <Stack
                                  m="0 !important"
                                  flex={1}
                                  direction={'column'}
                                  gap={2}
                                >
                                  <Text fontSize="16px" fontWeight={'500'}>
                                    Quantity
                                  </Text>
                                  <Text
                                    fontSize="16px"
                                    fontWeight={'700'}
                                    lineHeight={'0px'}
                                  >
                                    {val?.orderDetails?.totalQuantitySold ||
                                      '50'}
                                  </Text>
                                </Stack>
                                <Stack
                                  m="0 !important"
                                  flex={1}
                                  direction={'column'}
                                  gap={2}
                                >
                                  <Text fontSize="16px" fontWeight={'500'}>
                                    Threshold
                                  </Text>
                                  <Text
                                    fontSize="16px"
                                    fontWeight={'700'}
                                    lineHeight={'0px'}
                                  >
                                    {val?.threshold || '5'}
                                  </Text>
                                </Stack>

                                <Stack>
                                  <Button
                                    fontSize={{ md: '13px', '2xl': '16px' }}
                                    bg={'transparent'}
                                    color={'#1A1B1A'}
                                    fontWeight={'500'}
                                    lineHeight={'inherit'}
                                    borderRadius={9}
                                    border={'1px solid #1A1B1A'}
                                    width={'max-content'}
                                    _hover={{
                                      color: '#000',
                                    }}
                                    as={Link}
                                    to={`/dashboard/productdetails/${val?._id}`}
                                    textAlign={'center'}
                                  >
                                    View Items
                                  </Button>
                                </Stack>
                              </Stack>
                              <Divider
                                w="95%"
                                display={'flex'}
                                justifyContent={'center'}
                                my={6}
                                color="#B4B4B4"
                                px={6}
                              />
                            </Box>
                          ))}
                      </Box>
                    </GridItem>
                  </Grid>
                )}
              </Stack>
              <Stack>
                <Stack
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  my={'15px'}
                >
                  <Heading fontWeight={'500'} fontSize={'25px'}>
                    Users Activities
                  </Heading>
                  <Text
                    cursor={'pointer'}
                    fontSize={'16px'}
                    fontWeight={'400'}
                    color={'#75767A'}
                    as={Link}
                    to={`/dashboard/orders`}
                  >
                    View All
                  </Text>
                </Stack>
                <Stack display={'flex'} flexDirection={'column'} gap={2}>
                  <HStack display={'flex'} w="100%" px={4}>
                    <Box
                      fontSize="15px"
                      fontWeight={'400'}
                      color="#75767A"
                      flex={1}
                    >
                      NO.
                    </Box>
                    <Box
                      fontSize="15px"
                      fontWeight={'400'}
                      color="#75767A"
                      flex={1}
                    >
                      ID
                    </Box>
                    <Box
                      fontSize="15px"
                      fontWeight={'400'}
                      color="#75767A"
                      flex={1}
                    >
                      Name
                    </Box>
                    <Box
                      fontSize="15px"
                      fontWeight={'400'}
                      color="#75767A"
                      flex={1}
                    >
                      Purchasing Date
                    </Box>
                    <Box
                      fontSize="15px"
                      fontWeight={'400'}
                      color="#75767A"
                      flex={1}
                    >
                      Purchased Items
                    </Box>
                  </HStack>
                  {user?.role === 'manager' ? (
                    data && data?.['Users Activities']?.length > 0 ? (
                      data?.['Users Activities']?.map((val, ind) => (
                        <HStack
                          key={ind}
                          display={'flex'}
                          w="100%"
                          h="70px"
                          bg={'#fff'}
                          shadow={'0px 16px 38px 0px #0000000F'}
                          px={4}
                          borderRadius={'16px'}
                        >
                          <Box
                            fontSize="15px"
                            fontWeight={'500'}
                            color="#1B1C1D"
                            flex={1}
                          >
                            {ind + 1}
                          </Box>
                          <Box
                            fontSize="15px"
                            fontWeight={'500'}
                            color="#1B1C1D"
                            flex={1}
                          >
                            #{val?._id.slice(-6)}
                          </Box>
                          <Box
                            fontSize="15px"
                            fontWeight={'500'}
                            color="#1B1C1D"
                            flex={1}
                          >
                            <VStack
                              flexDirection={'row'}
                              alignItems={'center'}
                              justifyContent={'flex-start'}
                              gap={2}
                            >
                              <Avatar
                                size="sm"
                                name={val?.name}
                                src={
                                  val?.userId !== null &&
                                  `${imgUrl}${val?.userId?.profile_picture}`
                                }
                              />
                              <Text>{val?.name}</Text>
                            </VStack>
                          </Box>
                          <Box
                            fontSize="15px"
                            fontWeight={'500'}
                            color="#1B1C1D"
                            flex={1}
                          >
                            {moment(
                              val?.['orderDetails']?.[0]?.['createdAt']
                            ).format('DD/MM/YYYY')}
                          </Box>
                          <Box
                            fontSize="15px"
                            fontWeight={'500'}
                            color="#1B1C1D"
                            flex={1}
                          >
                            <Button
                              padding={'10px 50px'}
                              bg={
                                'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                              }
                              color={'#fff'}
                              fontWeight={'500'}
                              lineHeight={'inherit'}
                              borderRadius={12}
                              _hover={{
                                color: '#000',
                              }}
                              w={'fit-content'}
                              as={Link}
                              to={
                                `/dashboard/orderDetail/${val?.orderDetails?.[0]?.orderId}`
                                // `/dashboard/oderdetails/${val?.orderDetails?.[0]?.orderId}`
                                // val?.userId
                                //   ? `/dashboard/userDetails/${val?.userId?._id}`
                                //   : ''
                              }
                            >
                              View Details
                            </Button>
                          </Box>
                        </HStack>
                      ))
                    ) : (
                      <Stack className={'bread m5-h'}>
                        <Heading as={'h5'}>No Activities Found </Heading>
                      </Stack>
                    )
                  ) : data && data?.['recentSales']?.length > 0 ? (
                    data?.['recentSales']?.slice(0, 5)?.map((val, ind) => (
                      <HStack
                        key={ind}
                        display={'flex'}
                        w="100%"
                        h="70px"
                        bg={'#fff'}
                        shadow={'0px 16px 38px 0px #0000000F'}
                        px={4}
                        borderRadius={'16px'}
                      >
                        <Box
                          fontSize="15px"
                          fontWeight={'500'}
                          color="#1B1C1D"
                          flex={1}
                        >
                          {ind + 1}
                        </Box>
                        <Box
                          fontSize="15px"
                          fontWeight={'500'}
                          color="#1B1C1D"
                          flex={1}
                        >
                          #{val?._id.slice(-6)}
                        </Box>
                        <Box
                          fontSize="15px"
                          fontWeight={'500'}
                          color="#1B1C1D"
                          flex={1}
                        >
                          <VStack
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'flex-start'}
                            gap={2}
                          >
                            <Avatar
                              size="sm"
                              name="Kent Dodds"
                              src={
                                `${imgUrl}${val?.['userId']?.['profile_picture']}` ||
                                'https://bit.ly/kent-c-dodds'
                              }
                            />
                            <Text>{val?.['userId']?.['fullName']}</Text>
                          </VStack>
                        </Box>
                        <Box
                          fontSize="15px"
                          fontWeight={'500'}
                          color="#1B1C1D"
                          flex={1}
                        >
                          {moment(val?.['orderDate']).format('DD/MM/YYYY')}
                        </Box>
                        <Box
                          fontSize="15px"
                          fontWeight={'500'}
                          color="#1B1C1D"
                          flex={1}
                        >
                          <Button
                            padding={'10px 50px'}
                            bg={
                              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                            }
                            color={'#fff'}
                            fontWeight={'500'}
                            lineHeight={'inherit'}
                            borderRadius={12}
                            _hover={{
                              color: '#000',
                            }}
                            w={'fit-content'}
                            as={Link}
                            to={`/dashboard/orderDetail/${val?.orderDetails?.[0]?.orderId}`}
                          >
                            View Details
                          </Button>
                        </Box>
                      </HStack>
                    ))
                  ) : (
                    <Stack className={'bread m5-h'}>
                      <Heading as={'h5'}>No Activities Found </Heading>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
      </MainDashboard>
    </Stack>
  );
}
