import React, { useEffect, useState } from 'react';
import {
  Stack,
  Heading,
  Text,
  Box,
  Grid,
  GridItem,
  IconButton,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  VStack,
  useDisclosure,
  Divider,
  Avatar,
  Image,
  Radio,
  useToast,
} from '@chakra-ui/react';
import Permission from './Permission';
import { GET, POST } from '../../../utilities/ApiProvider';
import { useNavigate } from 'react-router-dom';

function AddNewMember() {
  const [data, setData] = useState([]);
  const [stores, setStores] = useState([]);
  const imageUploader = React.useRef(null);
  const toast = useToast();
  const navigate = useNavigate();
  const [uploadedImage, setUploadedImage] = useState(null);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      console.log(data);

      const formData = new FormData(e.target);
      formData.append('role', 'manager');
      formData.append('permissions', JSON.stringify(data));
     
      const response = await POST(
        `/store/team?storeId=${formData.get('storeId')}`,
        formData
      );
      if (response.status === 'success') {
        toast({
          description: 'Team member added successfully',
          status: 'success',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
        e.target.reset();
        navigate('/dashboard/team');
      } else {
        toast({
          description: response?.message,
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
      }
      // console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  async function getStores() {
    try {
      const response = await GET(`/store`);
      if (response.status === 'success') {
        console.log(response.data);
        setStores(response.data);
      } else {
        console.log(response);
        setStores([]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getStores();
  }, []);
  const handleImageUpload = e => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      reader.onload = event => {
        setUploadedImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
   console.log( data);
   
  }, [setData])
  

  return (
    <Stack px={6} display={'flex'} flexDirection={'column'} gap={4}>
      <Stack display={'flex'} flexDirection={'column'}>
        <Heading fontSize={'30px'} fontWeight={'600'}>
          Add New Team Member
        </Heading>
        <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
          Here is the details to add new manager...Check Now!
        </Text>
      </Stack>
      <form onSubmit={handleSubmit}>
        <Stack display="flex" flexDirection="row" gap={4} alignItems="center">
          <Input
            type="file"
            name="profile_picture"
            accept="image/*"
            onChange={handleImageUpload}
            ref={imageUploader}
            display="none"
          />
          <Avatar
            w="121px"
            h="121px"
            src={uploadedImage}
            // src="https://bit.ly/broken-link"
          />

          <Button
            padding="10px 50px"
            bg="var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))"
            color="#fff"
            fontWeight="500"
            lineHeight="inherit"
            borderRadius={12}
            w="140px"
            _hover={{
              color: '#000',
            }}
            onClick={() => imageUploader.current.click()}
          >
            Upload Photo
          </Button>
        </Stack>

        <Stack>
          <Box display={'flex'} gap={4}>
            <FormControl>
              <FormLabel
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'30px'}
              >
                Full Name
              </FormLabel>
              <Input
                background={'#F3F3F3'}
                border={'1px solid #B4B4B4'}
                py={6}
                type="text"
                name="fullName"
                placeholder="Enter Your Name"
              />
            </FormControl>

            <FormControl>
              <FormLabel
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'30px'}
              >
                Email Address
              </FormLabel>
              <Input
                background={'#F3F3F3'}
                border={'1px solid #B4B4B4'}
                py={6}
                type="email"
                name="email"
                placeholder="Enter Your Name"
              />
            </FormControl>
          </Box>
          <Box display={'flex'} gap={4}>
            <FormControl>
              <FormLabel
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'30px'}
              >
                Phone No.
              </FormLabel>
              <Input
                background={'#F3F3F3'}
                border={'1px solid #B4B4B4'}
                py={6}
                type="number"
                name="phone"
                placeholder="Enter Your Name"
              />
            </FormControl>

            <FormControl>
              <FormLabel
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'30px'}
              >
                Choose Store
              </FormLabel>
              <Select name="storeId" size={'lg'}>
                <option value="Select">Select</option>
                {stores?.map(item => (
                  <option value={item._id}>{item.name}</option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box display={'flex'} w="49.5%" gap={4}>
            <FormControl>
              <FormLabel
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'30px'}
              >
                Password
              </FormLabel>
              <Input
                background={'#F3F3F3'}
                border={'1px solid #B4B4B4'}
                py={6}
                type="password"
                name="password"
                placeholder="Enter Your Name"
              />
            </FormControl>
          </Box>
        </Stack>
        <Stack py={4}>
          <Permission setData={setData} />
          <Button
            padding={'10px 50px'}
            type="submit"
            bg={
              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            w="176px"
            _hover={{
              color: '#000',
            }}
          >
            Save Changes
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}

export default AddNewMember;
