import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Heading,
  VStack,
  Avatar,
  HStack,
  Icon,
  Stack,
  Text,
  Spinner,
  useToast,
  Image,
} from '@chakra-ui/react';
import { LuUser } from 'react-icons/lu';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DELETE, GET } from '../../../utilities/ApiProvider';
import moment from 'moment';

function DiscountDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [users, setUsers] = useState(null);
  useEffect(() => {
    fetchDiscountDetail(id);
  }, [id]);
  const fetchDiscountDetail = async id => {
    setIsLoading(true);
    try {
      const response = await GET(
        `/store/discount/${id}?storeId=66792cc177d1c268a6bf34af`
      );
      console.log('Discount Details', response);
      if (response.status === 'success') {
        setData(response.data[0]);
        setUsers(response.users);
        setIsLoading(false);
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  function calculateAvailability(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const differenceInMs = end - start;

    const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

    return differenceInDays;
  }
  const handleRemove = async id => {
    try {
      const response = await DELETE(`/store/discount/${id}`);
      console.log('delete', response);
      if (response.status === 'success') {
        navigate('/dashboard/discounts');
        toast({
          description: response?.message,
          status: response?.status,
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
      } else {
        toast({
          description: `${response.message}`,
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Stack px={6} py={2}>
      <Stack
        mb={2}
        display={'flex'}
        gap={2}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Stack display={'flex'} flexDirection={'column'}>
          <Heading fontSize={'30px'} fontWeight={'700'}>
            Discount Details
          </Heading>
          <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
            Here is the discount details...Check Now!
          </Text>
        </Stack>
      </Stack>
      {isLoading ? (
        <Box
          display={'flex'}
          height={'80vh'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Spinner color="#47A046" />
        </Box>
      ) : (
        <Stack>
          <Stack
            gap={2}
            shadow={'0px 16.32px 38.75px 0px #0000000F'}
            borderRadius={'17px'}
            p={{ base: '15px 20px', xl: '25px 40px' }}
            role="group"
            transition={'0.3s'}
            _hover={{
              borderColor: 'primaryGreen.200',
            }}
          >
            <Box display={'flex'} justifyContent={'space-between'}>
              <Stack direction={'row'} gap={2} alignItems={'center'}>
                <Box >
                  <Image objectFit={'contain'} height={'150px'} src={data?.image} />
                </Box>
           
              </Stack>
              <Stack alignItems={'end'} justifyContent={'center'}>
                <Button
                  onClick={() => handleRemove(id)}
                  border={'2px solid #07d57c'}
                  padding={'10px 25px'}
                  bg={'transparent'}
                  width={'max-content'}
                  borderRadius={6}
                  fontSize={'15px'}
                >
                  Remove
                </Button>
              </Stack>
            </Box>
            <Stack
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
                <Stack display={'flex'} flexDirection={'column'}>
                <Text fontSize={'17px'} fontWeight={'500'} color={'#1A1B1A'}>
                {data?.name || 'Big Sale'}
                </Text>
                <Text
                  fontSize={'16px'}
                  fontWeight={'700'}
                  color={'#1A1B1A'}
                  margin={'0 !important'}
                >
                   {moment(data?.createdAt).fromNow() || '2 hours ago'}
                </Text>
              </Stack>
              <Stack display={'flex'} flexDirection={'column'}>
                <Text fontSize={'17px'} fontWeight={'500'} color={'#1A1B1A'}>
                  Discount
                </Text>
                <Text
                  fontSize={'16px'}
                  fontWeight={'700'}
                  color={'#1A1B1A'}
                  margin={'0 !important'}
                >
                  {data?.unit || '10'} off
                </Text>
              </Stack>
              <Stack display={'flex'} flexDirection={'column'}>
                <Text fontSize={'17px'} fontWeight={'500'} color={'#1A1B1A'}>
                  No. of Users
                </Text>
                <Text
                  fontSize={'16px'}
                  fontWeight={'700'}
                  color={'#1A1B1A'}
                  margin={'0 !important'}
                >
                  {data?.numberOfUser || '25'}
                </Text>
              </Stack>
              <Stack display={'flex'} flexDirection={'column'}>
                <Text fontSize={'17px'} fontWeight={'500'} color={'#1A1B1A'}>
                  Availability
                </Text>
                <Text
                  fontSize={'16px'}
                  fontWeight={'700'}
                  color={'#1A1B1A'}
                  margin={'0 !important'}
                >
                  {calculateAvailability(data?.start, data?.end) || '10 days'}{' '}
                  days
                </Text>
              </Stack>
              <Stack display={'flex'} flexDirection={'column'}>
                <Text fontSize={'17px'} fontWeight={'500'} color={'#1A1B1A'}>
                  Code
                </Text>
                <Text
                  fontSize={'16px'}
                  fontWeight={'700'}
                  color={'#1A1B1A'}
                  margin={'0 !important'}
                >
                  {data?.code || 'X453f123s'}
                </Text>
              </Stack>
              <Stack display={'flex'} flexDirection={'column'}>
                <Text fontSize={'17px'} fontWeight={'500'} color={'#1A1B1A'}>
                  User Avail
                </Text>
                <Text
                  fontSize={'16px'}
                  fontWeight={'700'}
                  color={'#1A1B1A'}
                  margin={'0 !important'}
                >
                  {users?.length || '13'} Users
                </Text>
              </Stack>
              <Stack display={'flex'} flexDirection={'column'}>
                <Text fontSize={'17px'} fontWeight={'500'} color={'#1A1B1A'}>
                  Discount Category
                </Text>
                <Text
                  fontSize={'16px'}
                  fontWeight={'700'}
                  color={'#1A1B1A'}
                  margin={'0 !important'}
                >
                  Grocery
                </Text>
              </Stack>
            </Stack>
          </Stack>

          <Box py={4}>
            <Stack mt={6} display={'flex'} flexDirection={'column'}>
              <Heading fontSize={'18px'} fontWeight={'500'}>
                All Users
              </Heading>
              <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
                Here is the latest update...Check Now!
              </Text>
            </Stack>

            <Stack display={'flex'} flexDirection={'column'} gap={2}>
              <HStack mt={6} display={'flex'} w="100%" px={4}>
                <Box
                  fontSize="15px"
                  fontWeight={'400'}
                  color="#75767A"
                  flex={1}
                >
                  NO.
                </Box>
                <Box
                  fontSize="15px"
                  fontWeight={'400'}
                  color="#75767A"
                  flex={1}
                >
                  Email
                </Box>
                <Box
                  fontSize="15px"
                  fontWeight={'400'}
                  color="#75767A"
                  flex={1}
                >
                  Name
                </Box>
                <Box
                  fontSize="15px"
                  fontWeight={'400'}
                  color="#75767A"
                  flex={1}
                >
                  Role
                </Box>
                <Box
                  fontSize="15px"
                  fontWeight={'400'}
                  color="#75767A"
                  flex={1}
                >
                  Purchased Count
                </Box>
                <Box
                  fontSize="15px"
                  fontWeight={'400'}
                  color="#75767A"
                  flex={1}
                >
                  View Items
                </Box>
              </HStack>
              {users?.length > 0 ? (
                users.map((item, index) => (
                  <HStack
                    display={'flex'}
                    w="100%"
                    h="70px"
                    bg={'#fff'}
                    shadow={'0px 16px 38px 0px #0000000F'}
                    px={4}
                    borderRadius={'16px'}
                  >
                    <Box
                      fontSize="14px"
                      fontWeight={'600'}
                      color="#1B1C1D"
                      flex={1}
                    >
                      {index + 1 < 10 ? '0' + (index + 1) : index + 1}
                    </Box>
                    <Box
                      fontSize="14px"
                      fontWeight={'600'}
                      color="#1B1C1D"
                      flex={1}
                    >
                      {item?.email}
                    </Box>
                    <Box
                      fontSize="14px"
                      fontWeight={'600'}
                      color="#1B1C1D"
                      flex={1}
                    >
                      <Text>{item?.fullName}</Text>
                    </Box>
                    <Box
                      fontSize="14px"
                      fontWeight={'600'}
                      color="#1B1C1D"
                      flex={1}
                    >
                      {item?.role}
                    </Box>
                    <Box
                      fontSize="14px"
                      fontWeight={'600'}
                      color="#1B1C1D"
                      flex={1}
                    >
                      <Text>{item?.purchasedItems?.length}</Text>
                    </Box>
                    <Box
                      fontSize="15px"
                      fontWeight={'500'}
                      color="#1B1C1D"
                      flex={1}
                    >
                      <Button
                        padding={'10px 50px'}
                        bg={
                          'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                        }
                        color={'#fff'}
                        fontWeight={'500'}
                        lineHeight={'inherit'}
                        as={Link}
                        borderRadius={12}
                        _hover={{
                          color: '#000',
                        }}
                        w={'fit-content'}
                        to={`/dashboard/userDetails/${item?._id}`}
                      >
                        View Details
                      </Button>
                    </Box>
                  </HStack>
                ))
              ) : (
                <></>
              )}
            </Stack>
          </Box>
        </Stack>
      )}
    </Stack>
  );
}

export default DiscountDetail;
