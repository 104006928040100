import {
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  FormLabel,
  Select,
  Box,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spinner,
  Checkbox,
  Image,
  useToast,
  Icon,
  FormControl,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AiFillDelete } from 'react-icons/ai';
import { FaDesktop, FaMobileAlt } from 'react-icons/fa';
import { DELETE, GET, POST, PUT } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';

const StoreSetting = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAvaLoading, setAvaIsLoading] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedMobFile, setSelectedMobFile] = useState(null);
  const imgReg = useRef(null);
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();

  const [SingleStore, setSingleStore] = useState({
    name: '',
    phone: '',
    state: '',
    address: '',
    logo: '',
    area: '',
    zipCode: '',
    availability: [],
  });
  const [uploadingImage, setUploadingImage] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    fetchSingleStore();
  }, []);

  async function fetchSingleStore() {
    setIsLoading(true);
    try {
      const response = await GET(`/store/${id}`);
      console.log('SingleStore', response);

      if (response.status === 'success') {
        setSingleStore(response.data);
        setIsLoading(false);
      } else {
        console.log('Error while fetching products');
      }
    } catch (error) {
      console.log(error, 'error while fetching products');
    }
  }

  const uploadProfilePic = async () => {
    setUploadingImage(true);
    try {
      let form = document.getElementById('StorePicfrom');
      let data = new FormData(form);
      let res = await PUT(`/store/${id}`, data);
      if (res.status === 'success') {
        fetchSingleStore();
      } else {
        console.log('Error while uploading store logo');
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500,
      });
    }
    setUploadingImage(false);
  };
  const updateUser = async () => {
    setIsLoading(true);

    // Create a FormData object
    const formData = new FormData();
    formData.append('name', SingleStore.name);
    formData.append('phone', SingleStore.phone);
    formData.append('state', SingleStore.state);
    formData.append('address', SingleStore.address);
    formData.append('area', SingleStore.area);
    formData.append('logo', SingleStore.logo);
    formData.append('zipCode', SingleStore.zipCode);
    // If availability is an array, you can stringify it or append items individually

    try {
      console.log('Sending data:', formData); // Log the FormData to be sent
      let response = await PUT(`/store/${id}`, formData);
      console.log('Update response:', response);

      if (response.status === 'success') {
        fetchSingleStore();
        toast({
          description: response.message,
          status: 'success',
          position: 'top-right',
          duration: 1000,
        });
      } else {
        console.log('Error while updating store:', response.message);
      }
    } catch (err) {
      console.log('Error:', err);
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 1000,
      });
    }

    setIsLoading(false);
  };

  const changeOnStatus = (index, isSelected) => {
    setSingleStore(prevState => {
      const updatedAvailability = [...prevState.availability];
      updatedAvailability[index].isSelected = isSelected;
      return { ...prevState, availability: updatedAvailability };
    });
  };

  const changeStartingTime = (index, startTime) => {
    setSingleStore(prevState => {
      const updatedAvailability = [...prevState.availability];
      updatedAvailability[index].time[0].start = startTime;
      return { ...prevState, availability: updatedAvailability };
    });
  };

  const changeEndingTime = (index, endTime) => {
    setSingleStore(prevState => {
      const updatedAvailability = [...prevState.availability];
      updatedAvailability[index].time[0].end = endTime;
      return { ...prevState, availability: updatedAvailability };
    });
  };

  const removeIds = data => {
    return (
      data?.map(({ _id, time, ...rest }) => ({
        ...rest,
        time: time.map(({ _id, ...restTime }) => restTime),
      })) || []
    );
  };

  const result = removeIds(SingleStore?.availability);
  console.log(result);

  const updatedAvailability = async () => {
    setAvaIsLoading(true);

    // Create a FormData object
    const formData = new FormData();

    // Assuming `result` is the processed data from `removeIds(SingleStore?.availability)`
    const result = removeIds(SingleStore?.availability);

    // Append the availability data to the FormData object with the key 'availability'
    formData.append('availability', JSON.stringify(result));

    try {
      console.log('Sending data:', formData); // Log the FormData for debugging

      // Ensure your PUT function can handle FormData
      let response = await PUT(`/store/${id}`, formData);

      console.log('Update response:', response);

      if (response.status === 'success') {
        fetchSingleStore(); // Refresh the store data
        toast({
          description: response.message,
          status: 'success',
          position: 'top-right',
          duration: 1000,
        });
        onClose();
      } else {
        console.log('Error while updating store:', response.message);
        toast({
          description: response.message,
          status: 'error',
          position: 'top-right',
          duration: 1000,
        });
      }
    } catch (err) {
      console.log('Error:', err);
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 1000,
      });
    }

    setAvaIsLoading(false);
  };

  const handleRemove = async id => {
    setIsRemoveLoading(true);
    try {
      const response = await DELETE(`/store/${id}`);

      if (response.status === 'success') {
        navigate('/dashboard/store');
        toast({
          description: 'Removed successfully',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
      } else {
        toast({
          description: `${response.message}`,
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setIsRemoveLoading(true);
  };

  const DesktopBannerSubmit = async event => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('banners', selectedFile);

    try {
      const storeId = localStorage.getItem('storeId');
      const response = await POST(`/store/${storeId}/banners`, formData);
      console.log(response);
      if (response.status === 'success') {
        setSingleStore(response.data);
        setSelectedFile('');
        toast({
          title: response?.status,
          description: response?.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        onClose2(); // Close the modal on success
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'There was an error uploading the banner.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleBannerRemove = async bannerId => {
    const storeId = localStorage.getItem('storeId');
    try {
      const response = await DELETE(
        `/store/${storeId}/banners?bannerId=${bannerId}`
      );
      console.log('banner deleted', response);

      if (response.status === 'success') {
        setSingleStore(response.data);
        toast({
          title: 'Banner Deleted',
          description: 'The banner has been deleted successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: 'Error',
        description: 'There was an error deleting the banner.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const MobileBannerSubmit = async event => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('mobileBanners', selectedMobFile);

    try {
      const storeId = localStorage.getItem('storeId');
      const response = await POST(`/store/${storeId}/banners`, formData);
      console.log(response);
      if (response.status === 'success') {
        setSingleStore(response.data);
        toast({
          title: response?.status,
          description: response?.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        onClose3(); // Close the modal on success
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'There was an error uploading the banner.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <Box
          display={'flex'}
          height={'80vh'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Spinner color="#47A046" />
        </Box>
      ) : (
        <Stack>
          <Tabs variant="unstyled" py={6}>
            <Stack
              pr={4}
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Stack className={'bread m3-h'}>
                <Heading as={'h3'} fontFamily={'Quicksand'} color={'#1B1C1D'}>
                  Store Settings
                </Heading>
                <Text
                  color={'#75767A'}
                  fontWeight={400}
                  fontFamily={'Quicksand'}
                >
                  Here is your all store details...Check Now!
                </Text>
              </Stack>
              <TabList gap={6}>
                <Tab
                  padding={'12px 30px'}
                  color={'#7D7D7D'}
                  fontWeight={'500'}
                  lineHeight={'inherit'}
                  fontSize={'15px'}
                  px={8}
                  bg={'transparent'}
                  border={'2px solid #07d57c'}
                  _hover={{
                    color: '#fff',
                    bg: 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))',
                  }}
                  textTransform={'capitalize'}
                  w={'fit-content'}
                  borderRadius={6}
                  _selected={{
                    color: 'white',
                    bg: 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))',
                    border: '2px solid #EAA13E',
                  }}
                >
                  Store Settings
                </Tab>
                <Tab
                  border={'2px solid #07d57c'}
                  padding={'12px 30px'}
                  color={'#7D7D7D'}
                  fontWeight={'500'}
                  lineHeight={'inherit'}
                  fontSize={'15px'}
                  px={8}
                  bg={'transparent'}
                  // border={'1px solid #7D7D7D'}
                  _hover={{
                    color: '#fff',
                    bg: 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))',
                  }}
                  textTransform={'capitalize'}
                  w={'fit-content'}
                  borderRadius={6}
                  _selected={{
                    color: 'white',
                    bg: 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))',
                    border: '2px solid #EAA13E',
                  }}
                >
                  Store Banners
                </Tab>
              </TabList>
            </Stack>

            <TabPanels py={4}>
              <TabPanel p={0}>
                <Stack px={6} bg={'#F2F2F2'} py={8} borderRadius={8}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={'space-between'}
                    gap={6}
                  >
                    <Stack direction="row" alignItems="center" gap={6}>
                      <Box
                        w="138px"
                        h="138px"
                        borderRadius="100%"
                        border={'4px solid'}
                        borderColor="#E0883B"
                        overflow="hidden"
                      >
                        <Image
                          src={`${imgUrl}${SingleStore?.logo}`}
                          w="100%"
                          h="100%"
                          objectFit="cover"
                        />
                      </Box>
                      <Box>
                        <form id="StorePicfrom">
                          <Input
                            onChange={uploadProfilePic}
                            ref={imgReg}
                            display={'none'}
                            type="file"
                            name="logo"
                          />
                        </form>
                        <Stack className="btn" mb="20px">
                          <Button
                            isLoading={uploadingImage}
                            onClick={() => imgReg?.current.click()}
                            bg={
                              'radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)'
                            }
                            fontSize={{ base: '12px', xl: '14px' }}
                            fontWeight={500}
                            color={'#fff'}
                            width={'max-content'}
                            px={'40px'}
                            py={'24px'}
                            gap={2}
                            transition={'.3s'}
                            h={{ base: '35px', xl: '45px' }}
                            _hover={{
                              boxShadow: '0px 7px 20px 0px #0000003b',
                            }}
                          >
                            <Text as={'span'}>Upload Photo</Text>
                          </Button>
                        </Stack>
                      </Box>
                    </Stack>
                    <Stack>
                      <Button
                        bg={'#F31620'}
                        fontSize={{ base: '12px', xl: '14px' }}
                        fontWeight={500}
                        color={'#fff'}
                        width={'max-content'}
                        px={'25px'}
                        py={'24px'}
                        gap={2}
                        transition={'.3s'}
                        _hover={{
                          boxShadow: '0px 7px 20px 0px #0000003b',
                        }}
                        onClick={() => handleRemove(id)}
                        isLoading={isRemoveLoading}
                      >
                        <AiFillDelete /> Delete
                      </Button>
                    </Stack>
                  </Stack>

                  <Stack px={2} gap={6} py={6} borderRadius={8}>
                    <SimpleGrid
                      columns={{
                        base: '1',
                        sm: '1',
                        md: '1',
                        lg: '1',
                        xl: '2',
                        '2xl': '2',
                      }}
                      spacingX="40px"
                      spacingY="20px"
                    >
                      <Stack className="grid1">
                        <Stack>
                          <FormLabel
                            fontWeight={500}
                            m={0}
                            color={'#1A1B1A'}
                            fontSize={'16px'}
                          >
                            Branch Name
                          </FormLabel>
                          <Box className="bfiled">
                            <Input
                              type="text"
                              value={SingleStore.name}
                              onChange={e =>
                                setSingleStore({
                                  ...SingleStore,
                                  name: e.target.value,
                                })
                              }
                              placeholder="Enter Branch Name"
                            />
                          </Box>
                        </Stack>
                        <Stack>
                          <FormLabel
                            fontWeight={500}
                            m={0}
                            color={'#1A1B1A'}
                            fontSize={'16px'}
                          >
                            State
                          </FormLabel>
                          <Box className="bfiled">
                            <Select
                              value={SingleStore.state}
                              onChange={e =>
                                setSingleStore({
                                  ...SingleStore,
                                  state: e.target.value,
                                })
                              }
                            >
                              <option disabled hidden value={SingleStore.state}>
                                {SingleStore.state}
                              </option>
                              <option value={'sindh'}>Sindh</option>
                              <option value={'punjab'}>Punjab</option>
                              <option value={'balochistan'}>Balochistan</option>
                              <option value={'gilgit'}>Gilgit-Baltistan</option>
                            </Select>
                          </Box>
                        </Stack>
                        <Stack>
                          <FormLabel
                            fontWeight={500}
                            m={0}
                            color={'#1A1B1A'}
                            fontSize={'16px'}
                          >
                            Zip Code
                          </FormLabel>
                          <Box className="bfiled">
                            <Input
                              type="number"
                              value={SingleStore.zipCode}
                              onChange={e =>
                                setSingleStore({
                                  ...SingleStore,
                                  zipCode: e.target.value,
                                })
                              }
                              placeholder="Enter Zip Code"
                            />
                          </Box>
                        </Stack>
                      </Stack>
                      <Stack className="grid2">
                        <Stack>
                          <FormLabel
                            fontWeight={500}
                            m={0}
                            color={'#1A1B1A'}
                            fontSize={'16px'}
                          >
                            Phone No.
                          </FormLabel>
                          <Box className="bfiled">
                            <Input
                              type="number"
                              value={SingleStore.phone}
                              onChange={e =>
                                setSingleStore({
                                  ...SingleStore,
                                  phone: e.target.value,
                                })
                              }
                              placeholder="Enter Phone No."
                            />
                          </Box>
                        </Stack>
                        <Stack>
                          <FormLabel
                            fontWeight={500}
                            m={0}
                            color={'#1A1B1A'}
                            fontSize={'16px'}
                          >
                            Area
                          </FormLabel>
                          <Box className="bfiled">
                            <Input
                              type="text"
                              value={SingleStore.area}
                              onChange={e =>
                                setSingleStore({
                                  ...SingleStore,
                                  area: e.target.value,
                                })
                              }
                              placeholder="Enter Area here"
                            />
                          </Box>
                        </Stack>
                        <Stack>
                          <FormLabel
                            fontWeight={500}
                            m={0}
                            color={'#1A1B1A'}
                            fontSize={'16px'}
                          >
                            Address
                          </FormLabel>
                          <Box className="bfiled">
                            <Input
                              type="text"
                              value={SingleStore.address}
                              onChange={e =>
                                setSingleStore({
                                  ...SingleStore,
                                  address: e.target.value,
                                })
                              }
                              placeholder="Enter Area here"
                            />
                          </Box>
                        </Stack>
                      </Stack>
                    </SimpleGrid>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      <Button
                        bg={
                          'radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)'
                        }
                        fontSize={{ base: '12px', xl: '14px' }}
                        fontWeight={500}
                        color={'#fff'}
                        width={'max-content'}
                        px={'40px'}
                        py={'24px'}
                        gap={2}
                        transition={'.3s'}
                        h={{ base: '35px', xl: '45px' }}
                        _hover={{
                          boxShadow: '0px 7px 20px 0px #0000003b',
                        }}
                        isLoading={isLoading}
                        onClick={updateUser}
                      >
                        Save Changes
                      </Button>
                      <Stack alignItems={'end'}>
                        <Button
                          bg={
                            'radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)'
                          }
                          fontSize={{ base: '12px', xl: '14px' }}
                          fontWeight={500}
                          color={'#fff'}
                          width={'max-content'}
                          px={'40px'}
                          py={'24px'}
                          gap={2}
                          transition={'.3s'}
                          h={{ base: '35px', xl: '45px' }}
                          _hover={{
                            boxShadow: '0px 7px 20px 0px #0000003b',
                          }}
                          onClick={onOpen}
                        >
                          Change Store Availability
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </TabPanel>
              <TabPanel p={0}>
                <Stack px={6} bg={'#F2F2F2'} borderRadius={8}>
                  <Tabs variant="unstyled" py={6}>
                    <TabList gap={6}>
                      <Tab
                        padding={'10px 20px'}
                        color={'#7D7D7D'}
                        fontWeight={'500'}
                        lineHeight={'inherit'}
                        fontSize={'14px'}
                        px={8}
                        bg={'transparent'}
                        border={'2px solid #07d57c'}
                        _hover={{
                          color: '#fff',
                          bg: 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))',
                        }}
                        textTransform={'capitalize'}
                        w={'fit-content'}
                        borderRadius={6}
                        _selected={{
                          color: 'white',
                          bg: 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))',
                          border: '2px solid #EAA13E',
                        }}
                      >
                        <Icon mr={2} as={FaDesktop} /> Desktop Banners
                      </Tab>
                      <Tab
                        border={'2px solid #07d57c'}
                        padding={'10px 20px'}
                        color={'#7D7D7D'}
                        fontWeight={'500'}
                        lineHeight={'inherit'}
                        fontSize={'14px'}
                        px={8}
                        bg={'transparent'}
                        // border={'1px solid #7D7D7D'}
                        _hover={{
                          color: '#fff',
                          bg: 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))',
                        }}
                        textTransform={'capitalize'}
                        w={'fit-content'}
                        borderRadius={6}
                        _selected={{
                          color: 'white',
                          bg: 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))',
                          border: '2px solid #EAA13E',
                        }}
                      >
                        <Icon mr={2} as={FaMobileAlt} /> Mobile Banners
                      </Tab>
                    </TabList>
                    <TabPanels py={4}>
                      <TabPanel p={0}>
                        <Stack gap={4} borderRadius={8}>
                          <SimpleGrid
                            columns={{
                              base: '1',
                              sm: '1',
                              md: '1',
                              lg: '3',
                              xl: '3',
                              '2xl': '3',
                            }}
                            spacingX="40px"
                            spacingY="20px"
                            alignItems={'flex-start'}
                          >
                            {SingleStore?.banners?.length > 0 ? (
                              SingleStore?.banners?.map((v, k) => (
                                <Stack
                                  key={k}
                                  py={3}
                                  px={3}
                                  borderRadius={6}
                                  bg={'#FFFFFF'}
                                  shadow={
                                    '0px 17.048px 40.488px 0px rgba(0, 0, 0, 0.06)'
                                  }
                                >
                                  <Stack position={'relative'} m={'0'}>
                                    <Button
                                      position={'absolute'}
                                      right={'10px'}
                                      top={'15px'}
                                      bg={'#F31620'}
                                      fontSize={'16px'}
                                      fontWeight={500}
                                      color={'#fff'}
                                      width={'max-content'}
                                      px={'18px'}
                                      py={'20px'}
                                      gap={2}
                                      transition={'.3s'}
                                      _hover={{
                                        boxShadow: '0px 7px 20px 0px #0000003b',
                                      }}
                                      onClick={() => handleBannerRemove(v?._id)}
                                    >
                                      <AiFillDelete />
                                    </Button>
                                    <Image
                                      src={`${imgUrl}${v?.url}`}
                                      margin={'0 !important'}
                                    />
                                  </Stack>
                                </Stack>
                              ))
                            ) : (
                              <Text>No Banners available</Text>
                            )}
                          </SimpleGrid>
                          <Stack width={''}>
                            <Button
                              bg={
                                'radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)'
                              }
                              fontSize={{ base: '12px', xl: '14px' }}
                              fontWeight={500}
                              color={'#fff'}
                              width={'max-content'}
                              px={'40px'}
                              py={'24px'}
                              gap={2}
                              transition={'.3s'}
                              h={{ base: '35px', xl: '45px' }}
                              _hover={{
                                boxShadow: '0px 7px 20px 0px #0000003b',
                              }}
                              onClick={onOpen2}
                            >
                              Upload Desktop Banners
                            </Button>
                          </Stack>
                        </Stack>
                      </TabPanel>
                      <TabPanel p={0}>
                        <Stack gap={4} borderRadius={8}>
                          <SimpleGrid
                            columns={{
                              base: '1',
                              sm: '1',
                              md: '1',
                              lg: '3',
                              xl: '3',
                              '2xl': '3',
                            }}
                            spacingX="40px"
                            spacingY="20px"
                          >
                            {SingleStore?.mobileBanners?.length > 0 ? (
                              SingleStore?.mobileBanners?.map((v, k) => (
                                <Stack
                                  key={k}
                                  py={3}
                                  px={3}
                                  borderRadius={6}
                                  bg={'#FFFFFF'}
                                  shadow={
                                    '0px 17.048px 40.488px 0px rgba(0, 0, 0, 0.06)'
                                  }
                                >
                                  <Stack position={'relative'} m={'0'}>
                                    <Button
                                      position={'absolute'}
                                      right={'10px'}
                                      top={'15px'}
                                      bg={'#F31620'}
                                      fontSize={'16px'}
                                      fontWeight={500}
                                      color={'#fff'}
                                      width={'max-content'}
                                      px={'18px'}
                                      py={'20px'}
                                      gap={2}
                                      transition={'.3s'}
                                      _hover={{
                                        boxShadow: '0px 7px 20px 0px #0000003b',
                                      }}
                                      onClick={() => handleBannerRemove(v?._id)}
                                    >
                                      <AiFillDelete />
                                    </Button>
                                    <Image
                                      src={`${imgUrl}${v?.url}`}
                                      margin={'0 !important'}
                                    />
                                  </Stack>
                                </Stack>
                              ))
                            ) : (
                              <Text>No Banners available</Text>
                            )}
                          </SimpleGrid>
                          <Stack width={''}>
                            <Button
                              bg={
                                'radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)'
                              }
                              fontSize={{ base: '12px', xl: '14px' }}
                              fontWeight={500}
                              color={'#fff'}
                              width={'max-content'}
                              px={'40px'}
                              py={'24px'}
                              gap={2}
                              transition={'.3s'}
                              h={{ base: '35px', xl: '45px' }}
                              _hover={{
                                boxShadow: '0px 7px 20px 0px #0000003b',
                              }}
                              onClick={onOpen3}
                            >
                              Upload Mobile Banners
                            </Button>
                          </Stack>
                        </Stack>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
      )}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={'lg'}
        scrollBehavior={'inside'}
      >
        <ModalOverlay />
        <ModalContent height={'fit-content'}>
          <ModalHeader>Store Availability</ModalHeader>
          <ModalCloseButton />
          <ModalBody gap={3}>
            {SingleStore?.availability?.map((val, ind) => (
              <Stack
                mb="5px !important"
                border={'1px solid'}
                borderRadius={'12px'}
                borderColor={!val.isSelected ? '#c5c5c5' : '#409D46'}
                p="5px 5px 5px 15px"
                key={val.day}
                direction={'row'}
                alignItems={'center'}
              >
                <Text flex={'1'}>
                  <Checkbox
                    colorScheme="green"
                    defaultChecked={val.isSelected}
                    onChange={e => changeOnStatus(ind, e.target.checked)}
                    mr="5px"
                    mt="3px"
                    transform="translateY(2px)"
                    borderRadius={'25px'}
                  ></Checkbox>
                  {val.day}:
                </Text>
                <Box flex={'1'} px={2}>
                  <Input
                    isDisabled={!val.isSelected}
                    bgColor={!val.isSelected ? '#75767A' : '#E4933C'}
                    onChange={e => changeStartingTime(ind, e.target.value)}
                    type="time"
                    value={val.time[0].start}
                  />
                </Box>
                <Box flex={'1'} px={2}>
                  <Input
                    isDisabled={!val.isSelected}
                    bgColor={!val.isSelected ? '#75767A' : '#E4933C'}
                    onChange={e => changeEndingTime(ind, e.target.value)}
                    type="time"
                    value={val.time[0].end}
                  />
                </Box>
              </Stack>
            ))}
          </ModalBody>

          <ModalFooter>
            <Button
              background={
                'radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)'
              }
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={8}
              _hover={{
                color: '#000',
              }}
              w={'full'}
              onClick={updatedAvailability}
              isLoading={isAvaLoading}
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpen2} onClose={onClose2} isCentered>
        <ModalOverlay />
        <ModalContent>
          <form id="desktopBanners" onSubmit={DesktopBannerSubmit}>
            <ModalHeader>Desktop Banners</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl
                onClick={() => document.getElementById('upload').click()}
              >
                <FormLabel
                  fontSize={'16px'}
                  fontWeight={'500'}
                  lineHeight={'30px'}
                >
                  Banner Size : 1400x600
                </FormLabel>
                <Button border="1px dashed #75767A" w="100%" h="89px">
                  Attach File
                </Button>
                <Input
                  onChange={e => setSelectedFile(e.target.files[0])}
                  type="file"
                  id="upload"
                  display={'none'}
                  name="banners"
                />
                {selectedFile && (
                  <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={'500'}>
                    {selectedFile.name}
                  </Text>
                )}
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                bg={
                  'radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)'
                }
                fontSize={{ base: '12px', xl: '14px' }}
                fontWeight={500}
                color={'#fff'}
                width={'max-content'}
                px={'40px'}
                py={'24px'}
                gap={2}
                transition={'.3s'}
                h={{ base: '35px', xl: '45px' }}
                _hover={{
                  boxShadow: '0px 7px 20px 0px #0000003b',
                }}
                type="submit"
              >
                Banners Submit
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpen3} onClose={onClose3} isCentered>
        <ModalOverlay />
        <ModalContent>
          <form id="mobileBanners" onSubmit={MobileBannerSubmit}>
            <ModalHeader>Mobile Banners</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl
                onClick={() => document.getElementById('uploadmobile').click()}
              >
                <FormLabel
                  fontSize={'16px'}
                  fontWeight={'500'}
                  lineHeight={'30px'}
                >
                  Banner Size : 410x176
                </FormLabel>
                <Button border="1px dashed #75767A" w="100%" h="89px">
                  Attach File
                </Button>
                <Input
                  onChange={e => setSelectedMobFile(e.target.files[0])}
                  type="file"
                  id="uploadmobile"
                  display={'none'}
                  name="mobileBanners"
                />
                {selectedMobFile && (
                  <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={'500'}>
                    {selectedMobFile.name}
                  </Text>
                )}
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                bg={
                  'radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)'
                }
                fontSize={{ base: '12px', xl: '14px' }}
                fontWeight={500}
                color={'#fff'}
                width={'max-content'}
                px={'40px'}
                py={'24px'}
                gap={2}
                transition={'.3s'}
                h={{ base: '35px', xl: '45px' }}
                _hover={{
                  boxShadow: '0px 7px 20px 0px #0000003b',
                }}
                type="submit"
              >
                Banners Submit
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default StoreSetting;
