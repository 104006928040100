import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  IconButton,
  Image,
  Input,
  Select,
  SimpleGrid,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  Checkbox,
  AccordionPanel,
  Stack,
  Text,
  Textarea,
  Button,
  HStack,
  useDisclosure,
  useToast,
  Spinner,
  Icon,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import ModalWrapper from '../../Modal';
import { GET, POST, DELETE, PUT } from '../../../utilities/ApiProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineImage } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';
import { IoArrowBack } from 'react-icons/io5';

function AddCategory({ fetchCategories, onClose }) {
  const [file, setFile] = useState(null);
  const toast = useToast();
  const handleFileChange = event => {
    setFile(event.target.files[0]);
  };
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      if (
        Array.from(formData.entries())?.some(([key, value]) => value === '')
      ) {
        toast({
          title: 'Please fill all the fields',
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        return;
      }
      formData.append('image', file);

      const response = await POST('/admin/category', formData);
      if (response.status === 'success') {
        console.log(response.data);
        onClose();
        fetchCategories();
        toast({
          title: 'Category added successfully',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      } else {
        toast({
          title: 'Something went wrong',
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Stack display={'flex'} flexDirection={'column'} gap={3}>
        <FormControl>
          <FormLabel fontSize={'16px'} fontWeight={'500'} lineHeight={'30px'}>
            Category Name
          </FormLabel>
          <Input
            background={'#F3F3F3'}
            border={'1px solid #B4B4B4'}
            py={6}
            name="name"
            type="text"
            placeholder="Enter Your Name"
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize={'16px'} fontWeight={'500'} lineHeight={'30px'}>
            Category Picture
          </FormLabel>
          <Box
            h="56px"
            borderRadius={'8px'}
            border={'1px dashed #B4B4B4'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            cursor={'pointer'}
            onClick={() => document.getElementById('upload').click()}
          >
            <Stack
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              gap={3}
            >
              <Box
                w="24px"
                h="24px"
                borderRadius={'50%'}
                border={'1px solid #1A1A1A'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                color="#1A1A1A"
              >
                <AddIcon fontSize={'12px'} />
              </Box>
              <Box marginTop={'0px !important'}>
                <Text fontSize={'18px'} fontWeight={'500'}>
                  Upload Picture
                </Text>
              </Box>
              <Input
                onChange={handleFileChange}
                type="file"
                display="none"
                id="upload"
              />
              <label htmlFor="upload">
                <Box
                  as="span"
                  display={'none'}
                  cursor="pointer"
                  padding="10px 20px"
                  borderRadius="8px"
                  border="1px solid #B4B4B4"
                  background="#F3F3F3"
                  _hover={{
                    background: '#E2E2E2',
                  }}
                >
                  Choose File
                </Box>
              </label>
              {file && <Text marginTop="10px">{file.name}</Text>}
            </Stack>
          </Box>
        </FormControl>
        <Stack py={3}>
          <Button
            padding={'10px 50px'}
            type="submit"
            bg={
              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            h="45px"
            _hover={{
              color: '#000',
            }}
          >
            Continue
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

function AddSubCategory({ id, fetchCategories, onClose }) {
  const [file, setFile] = useState(null);
  const toast = useToast();
  const handleFileChange = event => {
    setFile(event.target.files[0]);
  };
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      if (
        Array.from(formData.entries())?.some(([key, value]) => value === '')
      ) {
        toast({
          title: 'Please fill all the fields',
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        return;
      }
      formData.append('image', file);
      formData.append('parent', id);

      const response = await POST('/admin/category', formData);
      if (response.status === 'success') {
        console.log(response.data);
        onClose();
        fetchCategories();
        toast({
          title: 'Subcategory added successfully',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      } else {
        toast({
          title: 'Something went wrong',
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Stack display={'flex'} flexDirection={'column'} gap={3}>
        <FormControl>
          <FormLabel fontSize={'16px'} fontWeight={'500'} lineHeight={'30px'}>
            Subcategory Name
          </FormLabel>
          <Input
            background={'#F3F3F3'}
            border={'1px solid #B4B4B4'}
            py={6}
            name="name"
            type="text"
            placeholder="Enter Your Name"
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize={'16px'} fontWeight={'500'} lineHeight={'30px'}>
            Subcategory Picture
          </FormLabel>
          <Box
            h="56px"
            borderRadius={'8px'}
            border={'1px dashed #B4B4B4'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            cursor={'pointer'}
            onClick={() => document.getElementById('upload').click()}
          >
            <Stack
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              gap={3}
            >
              <Box
                w="24px"
                h="24px"
                borderRadius={'50%'}
                border={'1px solid #1A1A1A'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                color="#1A1A1A"
              >
                <AddIcon fontSize={'12px'} />
              </Box>
              <Box marginTop={'0px !important'}>
                <Text fontSize={'18px'} fontWeight={'500'}>
                  Upload Picture
                </Text>
              </Box>
              <Input
                onChange={handleFileChange}
                type="file"
                display="none"
                id="upload"
              />
              <label htmlFor="upload">
                <Box
                  as="span"
                  display={'none'}
                  cursor="pointer"
                  padding="10px 20px"
                  borderRadius="8px"
                  border="1px solid #B4B4B4"
                  background="#F3F3F3"
                  _hover={{
                    background: '#E2E2E2',
                  }}
                >
                  Choose File
                </Box>
              </label>
              {file && <Text marginTop="10px">{file.name}</Text>}
            </Stack>
          </Box>
        </FormControl>
        <Stack py={3}>
          <Button
            padding={'10px 50px'}
            type="submit"
            bg={
              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            h="45px"
            _hover={{
              color: '#000',
            }}
          >
            Continue
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

function EditSubCategory() {
  return (
    <Stack display={'flex'} flexDirection={'column'} gap={3}>
      <FormControl>
        <FormLabel fontSize={'16px'} fontWeight={'500'} lineHeight={'30px'}>
          Subcategory Name
        </FormLabel>
        <Input
          background={'#F3F3F3'}
          border={'1px solid #B4B4B4'}
          py={6}
          type="text"
          placeholder="Enter Your Name"
        />
      </FormControl>
      <FormControl>
        <FormLabel fontSize={'16px'} fontWeight={'500'} lineHeight={'30px'}>
          Subcategory Picture
        </FormLabel>
        <Box
          h="56px"
          borderRadius={'8px'}
          border={'1px dashed #B4B4B4'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Stack
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            gap={3}
          >
            <Box
              w="24px"
              h="24px"
              borderRadius={'50%'}
              border={'1px solid #1A1A1A'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              color="#1A1A1A"
            >
              <AddIcon fontSize={'12px'} />
            </Box>
            <Box marginTop={'0px !important'}>
              <Text fontSize={'18px'} fontWeight={'500'}>
                Upload Picture
              </Text>
            </Box>
          </Stack>
        </Box>
      </FormControl>
      <Stack display={'flex'} flexDirection={'column'}>
        <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={'500'}>
          “Grocery” Subcategories
        </Text>
        <Text color="#4A4B4E" fontSize={'14px'} fontWeight={'400'}>
          You’ve 3 subcategories in “Grocery”
        </Text>
      </Stack>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        h={'50px'}
        border={'1px solid #B4B4B4'}
        borderRadius={8}
        py={2}
        px={4}
      >
        <Box>
          <Heading fontSize={'16px'} fontWeight={'600'}>
            Category 1
          </Heading>
        </Box>

        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          gap={6}
        >
          <HStack>
            <Button
              w="24px"
              h="34px"
              fontSize={'13px'}
              fontWeight={'600'}
              border={'1px solid #1A1B1A'}
              borderRadius={'8px'}
              width={'max-content'}
            >
              Edit
            </Button>
            <Button
              w="24px"
              h="34px"
              fontSize={'13px'}
              fontWeight={'600'}
              border={'1px solid #1A1B1A'}
              borderRadius={'8px'}
              width={'max-content'}
            >
              Remove
            </Button>
          </HStack>
        </Box>
      </Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        h={'50px'}
        border={'1px solid #B4B4B4'}
        borderRadius={8}
        py={2}
        px={4}
      >
        <Box>
          <Heading fontSize={'16px'} fontWeight={'600'}>
            Category 2
          </Heading>
        </Box>

        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          gap={6}
        >
          <HStack>
            <Button
              w="24px"
              h="34px"
              fontSize={'13px'}
              fontWeight={'600'}
              border={'1px solid #1A1B1A'}
              borderRadius={'8px'}
              width={'max-content'}
            >
              Edit
            </Button>
            <Button
              w="24px"
              h="34px"
              fontSize={'13px'}
              fontWeight={'600'}
              border={'1px solid #1A1B1A'}
              borderRadius={'8px'}
              width={'max-content'}
            >
              Remove
            </Button>
          </HStack>
        </Box>
      </Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        h={'50px'}
        border={'1px solid #B4B4B4'}
        borderRadius={8}
        py={2}
        px={4}
      >
        <Box>
          <Heading fontSize={'16px'} fontWeight={'600'}>
            Category 3
          </Heading>
        </Box>

        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          gap={6}
        >
          <HStack>
            <Button
              w="24px"
              h="34px"
              fontSize={'13px'}
              fontWeight={'600'}
              border={'1px solid #1A1B1A'}
              borderRadius={'8px'}
              width={'max-content'}
            >
              Edit
            </Button>
            <Button
              w="24px"
              h="34px"
              fontSize={'13px'}
              fontWeight={'600'}
              border={'1px solid #1A1B1A'}
              borderRadius={'8px'}
              width={'max-content'}
            >
              Remove
            </Button>
          </HStack>
        </Box>
      </Box>
      <Stack py={3}>
        <Button
          padding={'10px 50px'}
          bg={
            'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
          }
          color={'#fff'}
          fontWeight={'500'}
          lineHeight={'inherit'}
          borderRadius={12}
          h="45px"
          _hover={{
            color: '#000',
          }}
        >
          Continue
        </Button>
      </Stack>
    </Stack>
  );
}

export default function AddNewProduct() {
  const { productId } = useParams();

  const [images, setImages] = useState([]);
  const [primaryImage, setPrimaryImage] = useState(null);
  const [categories, setCategories] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const disclosure = useDisclosure();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const [checkedTags, setCheckedTags] = useState([]);
  const [editProduct, setEditProduct] = useState(null);
  const disclosure1 = useDisclosure();
  const disclosure2 = useDisclosure();
  const [id, setId] = useState(null);
  const { onClose, isOpen, onOpen } = disclosure;
  const { onClose: onClose1, isOpen: isOpen1, onOpen: onOpen1 } = disclosure1;
  const { onClose: onClose2, isOpen: isOpen2, onOpen: onOpen2 } = disclosure2;

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await GET(
          `/store/product/${productId}?storeId=${localStorage.getItem(
            'storeId'
          )}`
        );
        console.log(response);

        if (response.status === 'success') {
          console.log(response.data);
          setEditProduct(response.data);
          setImages(response.data.images);
          setTags(response.data.tags);
        } else {
          console.log(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchProduct();
  }, [productId]);
  const handleImageUpload = event => {
    if (productId) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages(prevImages => [...prevImages, { path: reader.result }]);
      };
      reader.readAsDataURL(event.target.files[0]);
    } else {
      const files = Array.from(event.target.files);
      console.log(files);
      setSelectedImages(prevImages => [...prevImages, ...files]);
      console.log(selectedImages);
      const newImages = files.map(file => URL.createObjectURL(file));
      setImages(prevImages => [...prevImages, ...newImages]);
    }
  };
  function isObjectEmpty(obj) {
    for (let key in obj) {
      if (
        obj[key] !== '' &&
        obj[key] !== null &&
        obj[key] !== undefined &&
        obj[key].length !== 0
      ) {
        return false;
      }
    }
    return true;
  }
  const handlePrimaryImage = index => {
    setPrimaryImage(index);
  };
  const Categories = [1, 2, 3, 4, 5, 6, 7, 8];
  const data = [
    'Grocery',
    'Fresh Fruits',
    'Meat',
    'Sea Foods',
    'Baking',
    'Drinks',
    'Cheese',
  ];
  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData(e.target);
      // formData.append('image', images);

      if (productId) {
        formData.append('image', JSON.stringify(images));
        formData.append('tags', JSON.stringify(editProduct?.tags));
        formData.append('categories', JSON.stringify(editProduct?.categories));
      } else {
        selectedImages?.forEach(image => {
          formData.append('image', image);
        });
        formData.append('tags', JSON.stringify(checkedTags));
        formData.append('categories', JSON.stringify(subCategories));
      }

      if (
        Array.from(formData.entries())?.some(([key, value]) => value === '') ||
        selectedImages.length === 0
      ) {
        toast({
          title: 'Please fill all the fields',
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        setLoading(false);
        return;
      }

      if (productId) {
        const response = await PUT(
          '/admin/product?productId=' + productId,
          formData
        );
        console.log(response);
        if (response.status === 'success') {
          setLoading(false);
          toast({
            title: 'Product updated successfully',
            status: 'success',
            duration: 3000,
            position: 'top-right',
            isClosable: true,
          });
        } else {
          setLoading(false);

          toast({
            title: 'Something went wrong',
            status: 'error',
            duration: 3000,
            position: 'top-right',
            isClosable: true,
          });
        }
      } else {
        const response = await POST('/admin/product', formData);
        if (response.status === 'success') {
          console.log(response.data);
          setLoading(false);
          toast({
            title: 'Product added successfully',
            status: 'success',
            duration: 3000,
            position: 'top-right',
            isClosable: true,
          });
          e.target.reset();
          navigate('/dashboard/product');
        } else {
          setLoading(false);

          toast({
            title: 'Something went wrong',
            status: 'error',
            duration: 3000,
            position: 'top-right',
            isClosable: true,
          });
        }
        localStorage.removeItem('product');
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };
  async function fetchCategories() {
    try {
      const response = await GET('/admin/groceries');
      if (response.status === 'success') {
        console.log(response.data);
        setCategories(response.data);
      } else {
        setCategories([]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchCategories();
  }, []);

  const handleAddSubcategory = async id => {
    onOpen1();
    setId(id);
  };
  const handleRemoveCategory = async id => {
    try {
      const response = await DELETE(`/admin/category/${id}`);
      if (response.status === 'success') {
        console.log(response.data);
        setCategories(prevCategories =>
          prevCategories.filter(item => item._id !== id)
        );
        toast({
          title: 'Category removed successfully',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      } else {
        toast({
          title: 'Something went wrong',
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = (event, id) => {
    console.log(id);
    if (event.target.checked) {
      if (productId) {
        let temp = [...editProduct?.categories];
        temp.push({ _id: id });
        setEditProduct(prev => ({ ...prev, categories: temp }));
        console.log(editProduct.categories);
      } else {
        if (localStorage.getItem('product')) {
          setEditProduct(prev => ({
            ...prev,
            categories: [...prev.categories, id],
          }));
        }
        setSubCategories([...subCategories, id]);
      }
    } else {
      if (productId) {
        let temp = editProduct?.categories;
        temp = temp.filter(item => item._id !== id);
        setEditProduct(prev => ({ ...prev, categories: temp }));
        console.log(editProduct.categories);
      } else {
        if (localStorage.getItem('product')) {
          setEditProduct(prev => ({
            ...prev,
            categories: prev.categories.filter(item => item !== id),
          }));
        }
        setSubCategories(subCategories.filter(item => item !== id));
      }
    }
  };
  const handleAddTag = () => {
    setTags([...tags, document.getElementById('tagName').value]);
    document.getElementById('tagName').value = '';
  };
  const handleCheckboxTagChange = (event, id) => {
    if (event.target.checked) {
      if (productId) {
        let temp = [...editProduct?.tags];
        temp.push(id);
        setEditProduct(prev => ({ ...prev, tags: temp }));
      } else {
        setCheckedTags([...checkedTags, id]);
      }
    } else {
      if (productId) {
        let temp = editProduct?.tags;
        temp = temp.filter(item => item !== id);
        setEditProduct(prev => ({ ...prev, tags: temp }));
      } else {
        setCheckedTags(checkedTags.filter(item => item !== id));
      }
    }
  };
  const handleRemoveImage = index => {
    let temp = images;
    temp = temp.filter((item, i) => i !== index);
    setImages(temp);
  };
  const handleBackBtn = () => {
    const form = document.getElementById('formSubmit');
    const formData = new FormData(form);
    let test = Array.from(formData.entries());
    const makeObject = Object.fromEntries(test);
    makeObject['categories'] = subCategories;
    makeObject['tags'] = tags;
    makeObject['checkedTags'] = checkedTags;
    makeObject['images'] = images;
    makeObject['selectedImages'] = selectedImages;
    if (isObjectEmpty(makeObject)) {
      navigate(-1);
      localStorage.removeItem('product');
    } else {
      if (!productId) {
        localStorage.setItem('product', JSON.stringify(makeObject));
        navigate(-1);
      } else {
        navigate(-1);
      }
    }
  };
  useEffect(() => {
    if (!productId && localStorage.getItem('product')) {
      const data = JSON.parse(localStorage.getItem('product'));
      if (data) {
        console.log(data);
        setEditProduct(data);
        setImages(data.images);
        setTags(data.tags);
        setCheckedTags(data.checkedTags);
        // setEditProduct(JSON.parse(data));
        // setImages(JSON.parse(data).images);
        // setTags(JSON.parse(data).tags);
        // setSubCategories(JSON.parse(data).subCategories);
        // setCheckedTags(JSON.parse(data).checkedTags);
        // setSelectedImages(JSON.parse(data).selectedImages);
      }
    }
  }, []);

  return (
    <Stack gap={6} px={4} pb={6}>
      <ModalWrapper
        isOpen={isOpen}
        onClose={onClose}
        title={'Add New Category'}
        children={
          <AddCategory fetchCategories={fetchCategories} onClose={onClose} />
        }
        size={'lg'}
        isCentered={true}
      />
      <ModalWrapper
        isOpen={isOpen1}
        onClose={onClose1}
        title={'Add New Subcategory'}
        children={
          <AddSubCategory
            id={id}
            fetchCategories={fetchCategories}
            onClose={onClose1}
          />
        }
        size={'lg'}
        isCentered={true}
      />
      <ModalWrapper
        isOpen={isOpen2}
        onClose={onClose2}
        title={'Edit “Grocery” Category'}
        children={<EditSubCategory />}
        size={'lg'}
        isCentered={true}
      />
      <form id="formSubmit" onSubmit={handleSubmit}>
        <SimpleGrid columns={2} spacing={16}>
          <Stack gap={4}>
            <Stack onClick={handleBackBtn} cursor={'pointer'}>
              <Icon as={IoArrowBack} w={10} h={10} />
            </Stack>
            <Stack>
              <Heading fontSize={'30px'} fontWeight={'700'}>
                Add New Product
              </Heading>
              <Text fontSize={'15px'} color={'#1A1B1A'} fontWeight={'400'}>
                Here is the all user details...Check Now!
              </Text>
            </Stack>
            <Stack gap={4}>
              {/* <FormControl>
              <FormLabel fontSize={'15px'}>Full Name</FormLabel>
              <Select height={'50px'} placeholder="Simple Product">
                <option value="option2">Simple Product</option>
                <option value="option1">Variable Product </option>
              </Select>
            </FormControl> */}
              <FormControl>
                <FormLabel fontSize={'15px'}>Product Name</FormLabel>
                <Input
                  py={6}
                  name="name"
                  defaultValue={editProduct && editProduct?.name}
                  type="text"
                  placeholder="Enter Product Name"
                />
              </FormControl>
              <Flex gap={8}>
                <FormControl>
                  <FormLabel fontSize={'15px'}>Price</FormLabel>
                  <Input
                    py={6}
                    name="price"
                    defaultValue={editProduct && editProduct?.price}
                    type="text"
                    placeholder="Rs. 0.00"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={'15px'}>Sales Price</FormLabel>
                  <Input
                    name="saleprice"
                    py={6}
                    defaultValue={editProduct && editProduct?.saleprice}
                    type="text"
                    placeholder="Rs. 0.00"
                  />
                </FormControl>
              </Flex>
              <FormControl>
                <FormLabel fontSize={'15px'}>Sale Quantity</FormLabel>
                <Input
                  name="salequantity"
                  py={6}
                  type="text"
                  defaultValue={editProduct && editProduct?.salequantity}
                  placeholder="Enter Here"
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={'15px'}>Description</FormLabel>
                <Textarea
                  py={6}
                  name="description"
                  height={'150px'}
                  defaultValue={editProduct && editProduct?.description}
                  placeholder="Enter Description"
                ></Textarea>
              </FormControl>
              <Flex gap={8}>
                <FormControl>
                  <FormLabel fontSize={'15px'}>In stock Items</FormLabel>
                  <Input
                    name="stock"
                    py={6}
                    defaultValue={editProduct && editProduct?.stock}
                    type="text"
                    placeholder="Rs. 0.00"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={'15px'}>Threshold</FormLabel>
                  <Input
                    name="threshold"
                    py={6}
                    defaultValue={editProduct && editProduct?.threshold}
                    type="text"
                    placeholder="Rs. 0.00"
                  />
                </FormControl>
              </Flex>
              <FormControl>
                <FormLabel fontSize={'15px'}>SKU No.</FormLabel>
                <Input
                  name="sku"
                  py={6}
                  type="text"
                  defaultValue={editProduct && editProduct?.sku}
                  placeholder="654321234564"
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={'15px'}>Short Description</FormLabel>
                <Textarea
                  py={6}
                  name="shortdescription"
                  height={'150px'}
                  defaultValue={editProduct && editProduct?.shortdescription}
                  placeholder="Enter Short Description"
                ></Textarea>
              </FormControl>
              <FormControl>
                <FormLabel fontSize={'15px'}>Upload Pictures</FormLabel>
                <Box p={4} borderWidth="1px" borderRadius="lg">
                  <Input
                    type="file"
                    // name="image"
                    multiple
                    onChange={handleImageUpload}
                    display="none"
                    id="upload-input"
                  />
                  <label htmlFor="upload-input">
                    <Button
                      as="span"
                      mb={4}
                      leftIcon={<AddIcon />}
                      bg={
                        'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                      }
                    >
                      Upload Pictures
                    </Button>
                  </label>
                  <Grid
                    templateColumns="repeat(auto-fill, minmax(100px, 1fr))"
                    gap={4}
                    mt={4}
                  >
                    {images.map((src, index) => (
                      <Box key={index} position="relative">
                        <Image
                          src={src?.path ? src?.path : src}
                          alt={`upload-${index}`}
                          boxSize="100px"
                          objectFit="cover"
                          borderRadius="md"
                        />
                        <Box
                          cursor={'pointer'}
                          border={'1px solid'}
                          borderColor={'#1A1A1A'}
                          borderRadius={'20px'}
                          pos={'absolute'}
                          top={'-20px'}
                          right={'-10px'}
                          w="25px"
                          h="25px"
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          onClick={() => handleRemoveImage(index)}
                        >
                          <Icon fontSize={'12px'} as={RxCross2} w={5} h={5} />
                        </Box>
                        <Flex
                          mt={2}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Text mr={2}>
                            {index === primaryImage
                              ? 'Primary'
                              : 'Set as Primary'}
                          </Text>
                          <IconButton
                            size="sm"
                            colorScheme={
                              index === primaryImage ? 'green' : 'gray'
                            }
                            icon={<AddIcon />}
                            onClick={() => handlePrimaryImage(index)}
                          />
                        </Flex>
                      </Box>
                    ))}
                  </Grid>
                </Box>
              </FormControl>
            </Stack>
          </Stack>
          <Stack>
            <Stack
              mb={2}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
            >
              <Stack display={'flex'} flexDirection={'column'}>
                <Heading fontSize={'40px'} fontWeight={'600'}>
                  Categories
                </Heading>
                <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
                  Choose at least ({categories?.length || 0}) category:
                </Text>
              </Stack>
              <Button
                padding={'10px 50px'}
                bg={
                  'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
                }
                color={'#fff'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                borderRadius={12}
                w="180px"
                onClick={onOpen}
                _hover={{
                  color: '#000',
                }}
              >
                Add New Category
              </Button>
            </Stack>
            <Stack h={'70vh'} overflowY={'scroll'} margin={'0 auto'}>
              {!categories ? (
                <Stack
                  w={'100%'}
                  h={'100%'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Spinner />
                </Stack>
              ) : (
                <Accordion allowToggle>
                  {categories?.map((item, index) => (
                    <AccordionItem
                      mb={3}
                      border={'1px solid #B4B4B4'}
                      background={'#F3F3F3'}
                      borderRadius={'8px'}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        h={'62px'}
                        borderBottom={'1px solid #B4B4B4'}
                        py={4}
                      >
                        <Box
                          px={4}
                          display={'flex'}
                          alignItems={'center'}
                          gap={3}
                        >
                          <Checkbox
                            colorScheme={'green'}
                            fontSize={'18px'}
                            fontWeight={'500'}
                            isChecked={
                              productId
                                ? editProduct?.categories?.some(
                                    product => product._id === item?._id
                                  )
                                : editProduct?.categories?.some(
                                    product => product === item?._id
                                  )
                            }
                            onChange={e => handleCheckboxChange(e, item?._id)}
                          ></Checkbox>
                          <Heading fontSize={'20px'} fontWeight={'600'}>
                            {item?.name}
                          </Heading>
                        </Box>

                        <Box
                          display={'flex'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          gap={6}
                        >
                          <HStack>
                            {/* <Button
                          onClick={onOpen2}
                          fontSize={'13px'}
                          fontWeight={'600'}
                          border={'1px solid #1A1B1A'}
                          borderRadius={'8px'}
                          width={'max-content'}
                        >
                          Edit
                        </Button> */}
                            <Button
                              fontSize={'13px'}
                              fontWeight={'600'}
                              border={'1px solid #1A1B1A'}
                              borderRadius={'8px'}
                              width={'max-content'}
                              onClick={() => handleRemoveCategory(item?._id)}
                            >
                              Remove
                            </Button>
                          </HStack>
                          <AccordionButton width={'max-content'}>
                            <AccordionIcon />
                          </AccordionButton>
                        </Box>
                      </Box>
                      <AccordionPanel
                        display={'flex'}
                        flexDirection={'column'}
                        gap={4}
                      >
                        <Stack
                          display={'flex'}
                          flexDirection={'row'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          gap={3}
                          flexWrap={'wrap'}
                        >
                          {item?.children?.length > 0 ? (
                            item?.children?.map((item, index) => (
                              <Box w="48%">
                                <Checkbox
                                  colorScheme={'green'}
                                  fontSize={'18px'}
                                  fontWeight={'500'}
                                  isChecked={
                                    productId
                                      ? editProduct?.categories?.some(
                                          product => product._id === item?._id
                                        )
                                      : editProduct?.categories?.some(
                                          product => product === item?._id
                                        )
                                  }
                                  onChange={e =>
                                    handleCheckboxChange(e, item?._id)
                                  }
                                >
                                  {item?.name}
                                  {/* Category {item?.name} */}
                                </Checkbox>
                              </Box>
                            ))
                          ) : (
                            <Text
                              fontSize={'15px'}
                              color={'#1A1B1A'}
                              fontWeight={'400'}
                            >
                              No Subcategories Found
                            </Text>
                          )}
                        </Stack>

                        <Button
                          w="100%"
                          onClick={() => handleAddSubcategory(item?._id)}
                          fontSize={'14px'}
                          fontWeight={'600'}
                          border={'1px solid #1A1B1A'}
                          borderRadius={'8px'}
                        >
                          Add New Subcatory
                        </Button>
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              )}
            </Stack>

            <Stack>
              <Heading fontSize={'30px'} fontWeight={'700'}>
                Tags
              </Heading>
              <Text fontSize={'15px'} color={'#1A1B1A'} fontWeight={'400'}>
                Choose at least (1) tag:
              </Text>
            </Stack>
            <Accordion allowToggle>
              <AccordionItem
                mb={3}
                border={'1px solid #B4B4B4'}
                background={'#F3F3F3'}
                borderRadius={'8px'}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  h={'62px'}
                  borderBottom={'1px solid #B4B4B4'}
                  py={4}
                >
                  <Box px={4}>
                    <Heading fontSize={'20px'} fontWeight={'600'}>
                      Add New Tag
                    </Heading>
                  </Box>

                  <AccordionButton width={'max-content'}>
                    <AccordionIcon />
                  </AccordionButton>
                </Box>
                <AccordionPanel
                  display={'flex'}
                  flexDirection={'column'}
                  gap={4}
                >
                  <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    gap={3}
                  >
                    <Input
                      border={'1px solid #1A1B1A'}
                      bg={'#F3F3F3'}
                      borderRadius={'8px'}
                      id="tagName"
                      placeholder="Enter Here"
                    />
                    <Button
                      bg={
                        'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                      }
                      onClick={handleAddTag}
                      color={'#000'}
                      width={'max-content'}
                    >
                      Add
                    </Button>
                  </Stack>
                  <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    gap={3}
                    flexWrap={'wrap'}
                  >
                    {tags.map((item, index) => (
                      <Box w="48%">
                        <Checkbox
                          colorScheme={'green'}
                          fontSize={'18px'}
                          fontWeight={'500'}
                          isChecked={
                            productId
                              ? editProduct?.tags?.some(
                                  product => product === item
                                )
                              : editProduct?.checkedTags?.some(
                                  product => product === item
                                )
                          }
                          onChange={e => handleCheckboxTagChange(e, item)}
                        >
                          {item}
                        </Checkbox>
                      </Box>
                    ))}
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Stack>
        </SimpleGrid>
        <Stack mt={4}>
          {productId ? (
            <Button
              type="submit"
              isLoading={loading}
              bg={
                'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
              }
              color={'#000'}
              width={'max-content'}
            >
              Edit Changes
            </Button>
          ) : (
            <Button
              type="submit"
              isLoading={loading}
              bg={
                'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
              }
              color={'#000'}
              width={'max-content'}
            >
              Save Changes
            </Button>
          )}
        </Stack>
      </form>
    </Stack>
  );
}
