import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Container,
  Heading,
  Stack,
  Text,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  SimpleGrid,
  Box,
  Image,
  Icon,
  Select,
  useToast,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import CategoryBanner from '../banner/CategoryBanner';
import { FiShoppingCart } from 'react-icons/fi';
import { GET, POST } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';
import star from '../../../assets/images/website/star.svg';
import { FaRegHeart, FaHeart } from 'react-icons/fa';
import {
  selectWebsiteData,
  addWishlist,
  setWebsiteData,
  selectWishlistData,
} from '../../../reducers/slices/website.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { TbArrowBackUp } from 'react-icons/tb';
import {
  convertNumbertoCommaSeperate,
  useAddToCart,
  useAddToWhishlist,
} from '../../../utilities/helper';
import ReactStars from 'react-rating-stars-component';
import Pagination from '../../../utilities/Pagination';

export default function ProductShop({ id }) {
  const [productlist, setProductlist] = useState(null);
  const [categories, setCategories] = useState([]);
  const [sortBy, setSortBy] = useState('');
  const [priceRange, setPriceRange] = useState([10, 10000]); // Default price range
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const storeId = localStorage.getItem('storeId');
  const websiteData = useSelector(selectWebsiteData);
  const wishlist = useSelector(selectWishlistData);
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addToCart = useAddToCart();
  const user = useSelector(state => state.user.value);
  const { handleFavourite, loading } = useAddToWhishlist();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 20;
  const [isLoading, setIsLoading] = useState(false);
  // Fetch categories on component mount
  useEffect(() => {
    setCurrentPage(1);
    fetchCategories();
    fetchProducts(); // Fetch products on component mount
  }, [id]); // Dependencies: id and storeId

  // Function to fetch products based on filters
  async function fetchProducts() {
    setIsLoading(true);
    try {
      // const subcategoryIds = selectedSubcategories.join(',');
      // const response = await GET(
      //   `/store/product/category/${id}?storeId=${storeId}&sortBy=${sortBy}${
      //     priceRange[0] !== '' &&
      //     priceRange[1] !== '' &&
      //     `&startPrice=${priceRange[0]}&endPrice=${priceRange[1]}`
      //   }&subcategories=${subcategoryIds}`
      // );
      const response = await GET(
        `/store/product/category/${id}?storeId=${storeId}&sortBy=${sortBy}&skip=${
          (currentPage - 1) * itemsPerPage
        }&limit=${itemsPerPage}`
      );
      window.scrollTo({ top: 0, behavior: 'smooth' });

      if (response.status === 'success') {
        setIsLoading(false);
        setTotalItems(response?.data?.productCount);
        // const updatedList = response?.data?.product?.map(item => {
        //   if (wishlist.find(v => v._id === item._id)) {
        //     return { ...item, is_favourite: true };
        //   }
        //   return { ...item, is_favourite: false };
        // });

        // setProductlist(updatedList || []);
        setProductlist(response?.data?.product || []);
      } else {
        setIsLoading(false);
        setProductlist([]);
        console.log('Error while fetching products');
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error, 'error while fetching products');
      setProductlist([]);
    }
  }
  useEffect(() => {
    fetchProducts();
  }, [currentPage, sortBy]);
  // Function to fetch categories
  async function fetchCategories() {
    try {
      const response = await GET(`/store/category/${id}?storeId=${storeId}`);

      if (response.status === 'success') {
        setCategories(response || []);
      } else {
        setCategories([]);
        console.log('Error while fetching categories');
      }
    } catch (error) {
      console.log(error, 'error while fetching categories');
      setCategories([]);
    }
  }

  // Function to handle sorting change
  const handleSortChange = event => {
    const value = event.target.value;
    if (value === 'Price, low to high') {
      setSortBy('price-asc');
    } else if (value === 'Price, high to low') {
      setSortBy('price-desc');
    } else {
      setSortBy('');
    }
  };

  // Function to handle price range change
  // const handlePriceChange = value => {
  //   setPriceRange(value);
  // };

  // const handleSubcategoryChange = (event, subcategoryId) => {
  //   if (event.target.checked) {
  //     setSelectedSubcategories([...selectedSubcategories, subcategoryId]);
  //   } else {
  //     setSelectedSubcategories(
  //       selectedSubcategories.filter(id => id !== subcategoryId)
  //     );
  //   }
  // };
  // Function to apply filters
  // const applyFilter = () => {
  //   fetchProducts(); // Fetch products when filters are applied
  // };

  return (
    <>
      <Stack>
        <Container
          p={0}
          mt={4}
          mb={6}
          maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}
        >
          <Stack px={4} bg={'#fff'} py={8} borderRadius={12} gap={4}>
            <CategoryBanner categories={categories} />
            <Stack
              direction={'row'}
              flexWrap={'wrap'}
              alignItems={'start'}
              // gap={4}
            >
              {/* Hiding Filters */}
              {/* <Stack
                width={{ base: '100%', md: '20%', xl: '20%', '2xl': '20%' }}
                gap={4}
                px={3}
                boxShadow="xl"
                borderRadius={8}
                py={6}
              >
                <Stack>
                  <Heading color={'#409D47'} fontSize={'18px'} fontWeight={700}>
                    Price
                  </Heading>
                  <RangeSlider
                    aria-label={['min', 'max']}
                    defaultValue={priceRange}
                    min={10} // Set minimum value
                    max={10000} // Set maximum value
                    step={100} // Step value to control granularity
                    onChange={handlePriceChange}
                  >
                    <RangeSliderTrack>
                      <RangeSliderFilledTrack bg="#409D47" />
                    </RangeSliderTrack>
                    <RangeSliderThumb bg="#409D47" index={0} />
                    <RangeSliderThumb bg="#409D47" index={1} />
                  </RangeSlider>
                  <Text fontWeight={500} fontSize={'15px'}>
                    Price: {priceRange[0]} — {priceRange[1]}
                  </Text>
                </Stack>
                <Stack>
                  <Heading
                    color={'#409D47;'}
                    fontSize={'18px'}
                    fontWeight={700}
                  >
                    Item Type
                  </Heading>
                  <Stack>
                    <Checkbox colorScheme="green">Top Rated</Checkbox>
                    <Checkbox colorScheme="green">Best Sellers</Checkbox>
                  </Stack>
                </Stack>
                <Stack>
                  <Heading
                    color={'#409D47;'}
                    fontSize={'18px'}
                    fontWeight={700}
                  >
                    Sub Categories
                  </Heading>
                  <Stack>
                    {categories?.data?.map((v, k) => (
                      <Checkbox
                        key={k}
                        colorScheme="green"
                        onChange={e => handleSubcategoryChange(e, v._id)}
                      >
                        {v.name}
                      </Checkbox>
                    ))}
                  </Stack>
                </Stack>
                <Button
                  bg={'transparent'}
                  color={'#409D47'}
                  border={'2px solid #409D47'}
                  borderRadius={'25px'}
                  onClick={applyFilter}
                >
                  Apply Filter
                </Button>
              </Stack> */}
              <Stack
                borderRadius={8}
                boxShadow="xl"
                p={4}
                width={'100%'}
                // width={{ base: '100%', md: '75%', xl: '75%', '2xl': '75%' }}
                gap={4}
              >
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  flexWrap={'wrap'}
                  borderRadius={8}
                  boxShadow="xl"
                  p={4}
                >
                  <Text fontSize={'14px'}>
                    Showing {productlist?.length ?? 0} results
                  </Text>
                  <Select
                    width={'max'}
                    placeholder="Default sorting"
                    size="sm"
                    onChange={handleSortChange}
                  >
                    <option>Price, low to high</option>
                    <option>Price, high to low</option>
                  </Select>
                </Stack>
                <SimpleGrid
                  columns={{ base: '1', sm: '2', lg: '3', xl: '4', '2xl': '4' }}
                  spacing={0}
                >
                  {!productlist && <Spinner />}
                  {productlist && productlist?.length > 0 ? (
                    productlist.map((v, k) => (
                      <Stack key={k} border={'1px solid #D9E8D6'}>
                        <Stack as={Link} to={`/productdetail/${v?._id}`}>
                          <Image
                            minHeight={'238px'}
                            maxHeight={'238px'}
                            objectFit={'cover'}
                            src={
                              v.url?.includes('https')
                                ? v.url
                                : `${imgUrl}${v.url}`
                            }
                          />
                        </Stack>
                        <Stack className="mh-3" px={4} py={3} spacing={2}>
                          <Heading
                            as={'h3'}
                            noOfLines={1}
                            color={'#212121'}
                            display={'-webkit-box'}
                            WebkitLineClamp={2}
                            WebkitBoxOrient={'vertical'}
                          >
                            {v.name}
                          </Heading>
                          <Text
                            fontSize={'13px'}
                            minHeight={'40px'}
                            noOfLines={[1, 2]}
                            color={'#212121'}
                            overflow={'hidden'}
                            display={'-webkit-box'}
                            WebkitLineClamp={2}
                            WebkitBoxOrient={'vertical'}
                          >
                            {v.description}
                          </Text>
                          <Box display={'flex'} gap={2}>
                            <ReactStars
                              count={5}
                              value={v.rating}
                              size={15}
                              isHalf={true}
                              edit={false}
                              activeColor="#ffd700"
                            />
                            <Text fontSize={'12px'} color={'#989BA2'}>
                              ({v.rating})
                            </Text>
                          </Box>
                        </Stack>
                        <Stack px={4} pb={4} spacing={2}>
                          <Text
                            fontSize={'13px'}
                            minHeight={'40px'}
                            noOfLines={[1, 2]}
                            color={'#212121'}
                          >
                            {v.tagName}
                          </Text>
                          <Box
                            className="mh-3"
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                          >
                            {v?.isDiscount ? (
                              <Heading as={'h3'}>
                                Rs{' '}
                                {convertNumbertoCommaSeperate(
                                  v.discountedPrice
                                )}
                                <Text
                                  as="span"
                                  ml={2}
                                  textDecoration="line-through"
                                  color="gray.500"
                                  fontSize={'sm'}
                                >
                                  Rs {convertNumbertoCommaSeperate(v.price)}
                                </Text>
                              </Heading>
                            ) : (
                              <Heading as={'h3'}>
                                Rs {convertNumbertoCommaSeperate(v.price)}
                              </Heading>
                            )}
                            <Flex gap={2}>
                              {user && (
                                <Button
                                  disabled={loading}
                                  onClick={() => {
                                    handleFavourite(v, false);
                                    // Create a deep copy of the product list
                                    let temp = productlist.map(item => ({
                                      ...item,
                                    }));

                                    let check = temp.find(
                                      item => item._id === v._id
                                    )?.is_favourite;

                                    temp = temp.map(item => {
                                      if (item._id === v._id) {
                                        return {
                                          ...item,
                                          is_favourite: !check,
                                        };
                                      }
                                      return item;
                                    });

                                    setProductlist(temp);
                                  }}
                                  w={'40px'}
                                  height={'40px'}
                                  borderRadius={'50%'}
                                >
                                  <Icon
                                    fontSize={'15px'}
                                    color={'#007341'}
                                    as={v?.is_favourite ? FaHeart : FaRegHeart}
                                  />
                                </Button>
                              )}

                              <Button
                                w={'40px'}
                                height={'40px'}
                                borderRadius={'50%'}
                                onClick={() => addToCart(v)}
                              >
                                <Icon
                                  fontSize={'17px'}
                                  color={'#007341'}
                                  as={FiShoppingCart}
                                />
                              </Button>
                            </Flex>
                          </Box>
                        </Stack>
                      </Stack>
                    ))
                  ) : (
                    <Text>No products available</Text>
                  )}
                </SimpleGrid>
              </Stack>
            </Stack>
          </Stack>
          {totalItems > itemsPerPage && (
            <Stack bg={'#fff'} px={4} py={4} borderRadius={12} mt={4}>
              <Pagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
                isLoading={isLoading}
              />
            </Stack>
          )}
        </Container>
      </Stack>
    </>
  );
}
