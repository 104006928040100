import {
  Box,
  Button,
  FormLabel,
  Heading,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useToast,
  Checkbox,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { POST } from '../../../utilities/ApiProvider';

const CreateBranch = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [openingHours, setOpeningHours] = useState([
    {
      day: 'Monday',
      time: [
        {
          start: '08:00',
          end: '22:00',
        },
      ],
      isSelected: true,
    },
    {
      day: 'Tuesday',
      time: [
        {
          start: '08:00',
          end: '22:00',
        },
      ],
      isSelected: true,
    },
    {
      day: 'Wednesday',
      time: [
        {
          start: '08:00',
          end: '22:00',
        },
      ],
      isSelected: true,
    },
    {
      day: 'Thursday',
      time: [
        {
          start: '08:00',
          end: '22:00',
        },
      ],
      isSelected: true,
    },
    {
      day: 'Friday',
      time: [
        {
          start: '08:00',
          end: '22:00',
        },
      ],
      isSelected: true,
    },
    {
      day: 'Saturday',
      time: [
        {
          start: '08:00',
          end: '22:00',
        },
      ],
      isSelected: true,
    },
    {
      day: 'Sunday',
      time: [
        {
          start: '08:00',
          end: '22:00',
        },
      ],
      isSelected: true,
    },
  ]);

  const changeOnStatus = (itemIndex, value) => {
    const modifiedArray = openingHours.map((val, ind) => {
      if (ind === itemIndex) {
        return { ...val, isSelected: value };
      } else return val;
    });
    setOpeningHours(modifiedArray);
  };

  const changeStartingTime = (itemIndex, value) => {
    const modifiedArray = openingHours.map((val, ind) => {
      if (ind === itemIndex) {
        return { ...val, time: [{ ...val.time[0], start: value }] };
      } else return val;
    });
    setOpeningHours(modifiedArray);
  };

  const changeEndingTime = (itemIndex, value) => {
    const modifiedArray = openingHours.map((val, ind) => {
      if (ind === itemIndex) {
        return { ...val, time: [{ ...val.time[0], end: value }] };
      } else return val;
    });
    setOpeningHours(modifiedArray);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const form = new FormData(e.target);
      form.append('availability', JSON.stringify(openingHours.filter(item => item.isSelected)));
      const response = await POST('/store', form);
      if (response.status === 'success') {
        toast({
          title: 'Store Added Successfully',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        navigate('/dashboard/store');
      } else {
        toast({
          title: response.message,
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(error);
      toast({
        title: 'An error occurred',
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SimpleGrid
        columns={{
          base: '1',
          sm: '1',
          md: '1',
          lg: '1',
          xl: '2',
          '2xl': '2',
        }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'} fontFamily={'Quicksand'} color={'#1B1C1D'}>
            Create New Branch
          </Heading>
          <Text color={'#75767A'} fontWeight={400} fontFamily={'Quicksand'}>
            Here are your store details... Check Now!
          </Text>
        </Stack>
      </SimpleGrid>
      <form onSubmit={handleSubmit}>
        <Stack bg={'#FBFBFB'} px={2} gap={6} py={6} borderRadius={8}>
          <SimpleGrid
            columns={{
              base: '1',
              sm: '1',
              md: '1',
              lg: '1',
              xl: '2',
              '2xl': '2',
            }}
            spacingX="70px"
            spacingY="20px"
          >
            <Stack className="adser" gap={2}>
              <Stack>
                <FormLabel
                  fontWeight={500}
                  m={0}
                  color={'#1A1B1A'}
                  fontSize={'16px'}
                >
                  Branch Name
                </FormLabel>
                <Box className="bfiled">
                  <Input
                    type="text"
                    name="name"
                    placeholder="Enter Branch Name"
                    required
                  />
                </Box>
              </Stack>

              <Stack>
                <FormLabel
                  fontWeight={500}
                  m={0}
                  color={'#1A1B1A'}
                  fontSize={'16px'}
                >
                  Phone No.
                </FormLabel>
                <Box className="bfiled">
                  <Input
                    type="number"
                    name="phone"
                    placeholder="Enter Phone No."
                    required
                  />
                </Box>
              </Stack>
              <Stack direction={'row'} gap={6} justifyContent={'space-between'}>
                <Stack w={'100%'}>
                  <FormLabel
                    fontWeight={500}
                    m={0}
                    color={'#1A1B1A'}
                    fontSize={'16px'}
                  >
                    State
                  </FormLabel>
                  <Box className="bfiled">
                    <Select name="state" placeholder="Select State" required>
                      <option value={'Sindh'}>Sindh</option>
                      <option value={'Punjab'}>Punjab</option>
                      <option value={'Balochistan'}>Balochistan</option>
                      <option value={'Gilgit-Baltistan'}>Gilgit-Baltistan</option>
                    </Select>
                  </Box>
                </Stack>
                <Stack w={'100%'}>
                  <FormLabel
                    fontWeight={500}
                    m={0}
                    color={'#1A1B1A'}
                    fontSize={'16px'}
                  >
                    Area
                  </FormLabel>
                  <Box className="bfiled">
                    <Input
                      type="text"
                      name="area"
                      placeholder="Enter Area here"
                      required
                    />
                  </Box>
                </Stack>
              </Stack>
              <Stack>
                <FormLabel
                  fontWeight={500}
                  m={0}
                  color={'#1A1B1A'}
                  fontSize={'16px'}
                >
                  Address
                </FormLabel>
                <Box className="bfiled">
                  <Input
                    type="text"
                    name="address"
                    placeholder="Enter Store Address"
                    required
                  />
                </Box>
              </Stack>
              <Stack>
                <FormLabel
                  fontWeight={500}
                  m={0}
                  color={'#1A1B1A'}
                  fontSize={'16px'}
                >
                  Zip Code
                </FormLabel>
                <Box className="bfiled">
                  <Input
                    type="number"
                    name="zipCode"
                    placeholder="Enter Zip Code"
                    required
                  />
                </Box>
              </Stack>
              <Stack>
                <FormLabel
                  fontWeight={500}
                  m={0}
                  color={'#1A1B1A'}
                  fontSize={'16px'}
                >
                  Upload Picture*
                </FormLabel>
                <Box className="ufiled">
                  <Input type="file" name="logo" required />
                </Box>
              </Stack>
            </Stack>
            <Stack pr={4}>
              <Heading fontSize={'22px'}>Store Availability</Heading>

              {openingHours.map((val, ind) => (
                <Stack
                  mb="5px !important"
                  border={'1px solid'}
                  borderRadius={'12px'}
                  borderColor={!val.isSelected ? '#c5c5c5' : '#409D46'}
                  p="5px 5px 5px 15px"
                  key={val.day}
                  direction={'row'}
                  alignItems={'center'}
                >
                  <Text flex={'1'}>
                    <Checkbox
                      colorScheme="green"
                      defaultChecked={val.isSelected}
                      onChange={e => changeOnStatus(ind, e.target.checked)}
                      mr="5px"
                      mt="3px"
                      transform="translateY(2px)"
                      borderRadius={'25px'}
                    ></Checkbox>
                    {val.day}:
                  </Text>
                  <Box flex={'1'} px={2}>
                    <Input
                      isDisabled={!val.isSelected}
                      bgColor={!val.isSelected ? '#75767A' : '#E4933C'}
                      onChange={e => changeStartingTime(ind, e.target.value)}
                      type="time"
                      value={val.time[0].start}
                    />
                  </Box>
                  <Box flex={'1'} px={2}>
                    <Input
                      isDisabled={!val.isSelected}
                      bgColor={!val.isSelected ? '#75767A' : '#E4933C'}
                      onChange={e => changeEndingTime(ind, e.target.value)}
                      type="time"
                      value={val.time[0].end}
                    />
                  </Box>
                </Stack>
              ))}
            </Stack>
          </SimpleGrid>
          <Stack>
            <Button
              padding={'25px 70px'}
              bg={
                'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
              }
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={6}
              _hover={{
                color: '#000',
              }}
              type="submit"
              w={'fit-content'}
              isLoading={isLoading}
            >
              Continue
            </Button>
          </Stack>
        </Stack>
      </form>
    </>
  );
};

export default CreateBranch;
