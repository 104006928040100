import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  ListItem,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  Icon,
  useToast,
  UnorderedList,
  Select,
  Textarea,
  Checkbox,
} from '@chakra-ui/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCartData,
  setCarts,
  setCheckoutDetails,
} from '../../reducers/slices/cart.slice';
import { GET, POST } from '../../utilities/ApiProvider';
import { RiDiscountPercentLine } from 'react-icons/ri';
import { selectBuyNow, setBuyNow } from '../../reducers/slices/website.slice';
import moment from 'moment';
import { convertNumbertoCommaSeperate } from '../../utilities/helper';

const getQueryParams = search => {
  return new URLSearchParams(search);
};
export default function CheckOutfrom() {
  const dispatch = useDispatch();
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const buyNow = useSelector(selectBuyNow);
  const storeId = localStorage.getItem('storeId');
  const cart = useSelector(selectCartData || []);
  const [couponlist, setcouponlist] = useState([]);
  const [coupon, setCoupon] = useState('');
  const [address, setAddress] = useState('');
  const user = useSelector(state => state.user.value);
  const [areas, setAreas] = useState([]);
  const inputRef = useRef(null);
  const [cartId, setCartId] = useState(null);
  const [couponApplied, setCouponApplied] = useState(false);
  const currentDate = new Date();
  const [addresses, setAddresses] = useState(null);
  const [deliveryFee, setDeliveryFee] = useState(null);
  const queryParams = getQueryParams(location.search);
  const type = queryParams.get('type');
  const [paymentMethod, setPaymentMethod] = useState('cod');
  const [selectedId, setSelectedId] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [isChecked, setIsChecked] = useState('');

  useEffect(() => {
    if (!buyNow && !cartId && cart?.length == 0) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    async function getCart() {
      try {
        const response = await GET('/store/cart');
        // console.log(response);
        if (response.status === 'success') {
          setCouponApplied(response.data.couponApplied);
          setCartId(response.data._id);
          dispatch(setCarts(response.data || []));
        } else {
          console.log('Error while fetching cart');
        }
      } catch (error) {
        console.log(error);
      }
    }
    async function getAddress() {
      try {
        const response = await GET('/users/address/lists');
        console.log(response);

        if (response.status === 'success') {
          setAddresses(response.data);
          // setAddress(response.data.slice(-1)[0]);
        }
      } catch (error) {
        console.log(error);
      }
    }
    async function getAreas() {
      try {
        const response = await GET(
          `/store/delivery/area?storeId=66792cc177d1c268a6bf34af`
        );
        console.log(response);

        if (response.status === 'success') {
          setAreas(response.data || []);
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (user) {
      getCart();
      getAddress();
      fetchcoupons();
    }
    getAreas();
  }, [user, dispatch]);

  useEffect(() => {
    if (window.google && window.google.maps) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          // types: ['address'],
          fields: ['formatted_address', 'geometry', 'name'],
          componentRestrictions: {
            country: 'pk',
          },
          strictBounds: true,
          types: ['establishment'],
        }
      );

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        // if (place && place.formatted_address) {
        //   console.log(place.formatted_address);
        //   // setAddress(place.formatted_address);
        // }
        console.log(place);
      });
      // autocomplete?.addEventListener(
      //   autocomplete,
      //   'place_changed',
      //   function () {
      //     let near_places = autocomplete.getPlace();
      //     console.log(near_places);
      //   }
      // );
    }
  }, []);
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      let storeId = localStorage.getItem('storeId');
      let userId = JSON.parse(localStorage.getItem('ajwaUser'))?._id;
      const formData = new FormData(e.target);
      const formDataEntries = Array.from(formData.entries());
      const hasEmptyValue = formDataEntries
        .filter(([key]) => key !== 'notes') // Exclude the 'notes' field
        .some(([key, value]) => value === ''); // Check for empty values

      if (hasEmptyValue) {
        console.log('There are empty fields excluding notes.');
      } else {
        console.log('All fields are filled.');
      }
      // Check if any field is empty
      if (hasEmptyValue) {
        toast({
          description: 'Please fill all the fields',
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      formData.append('storeId', storeId);

      if (user === undefined || user === null) {
        formData.append('isGuest', 'true');
        formData.append('cartItems', JSON.stringify(cart));
      } else {
        if (isChecked === '1') {
          const data = {
            name: formData.get('name'),
            streetAddress: formData.get('streetAddress'),
            town: formData.get('town'),
            areaId: formData.get('areaId'),
            phone: formData.get('phone'),
            email: formData.get('email'),
            type: formData.get('type'),
          };
          const response = await POST('/users/address/store', data);
          console.log(response);
        }

        formData.append('userId', userId);
        formData.append('isGuest', 'false');
        if (cartId) {
          formData.append('cartId', cartId);
        } else {
          formData.append('cartItems', JSON.stringify(buyNow));
        }
      }

      formData.append('paymentMethod', paymentMethod);

      // Convert formData to an object
      console.log(formData.entries());
      let itemToSend = Object.fromEntries(formData.entries());
      dispatch(setCheckoutDetails(itemToSend));
      navigate('/deliveryoption');
    } catch (error) {
      console.log(error);
    }
  };

  async function fetchcoupons() {
    try {
      const response = await GET(
        `/store/coupon?storeId=${storeId}&currentDate=${currentDate}`
      );
      // console.log('discount coupon List', response);
      if (response.status === 'success') {
        setcouponlist(response?.data || []);
      } else {
        setcouponlist([]);
        console.log('Error while fetching products');
      }
    } catch (error) {
      console.log(error, 'error while fetching products');
      setcouponlist([]);
    }
  }

  const handleCopyCode = code => {
    navigator.clipboard.writeText(code).then(
      () => {
        toast({
          description: 'Code copied to clipboard!',
          status: 'success',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
      },
      err => {
        console.error('Failed to copy: ', err);
      }
    );
  };

  const handleCoupon = async value => {
    try {
      const response = await POST('/store/coupon', {
        couponCode: value,
        currentDate: moment(new Date()).format('YYYY-MM-DD'),
        cartId: cartId,
      });
      // console.log(response);
      if (response.status === 'success') {
        setCouponApplied(true);
        dispatch(setCarts(response.data || []));
        // navigate('/deliveryoption');
      } else {
        toast({
          description: response?.trace,
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
      }
      // if (response.status === 'success' && response.data) {
      //   console.log(response.data);
      //   dispatch(setCarts(response.data.cartItems || []));
      //   navigate('/deliveryoption');
      // } else {
      //   console.log('Error while fetching products');
      // }
    } catch (error) {
      console.log(error);
    }
  };
  const calculateTotal = () => {
    if (couponApplied) {
      return cart?.total;
    } else if (!type) {
      if (cart.cartItems) {
        return cart?.cartItems?.reduce((total, sum) => {
          return total + sum.price * sum.quantity;
        }, 0);
      } else {
        return cart?.reduce((total, sum) => {
          return total + sum.total;
        }, 0);
      }
    } else {
      return buyNow?.[0]?.price * 1;
    }
  };
  const handleDeliveryAddress = e => {
    if (!e.target.value) {
      return;
    }
    setSelectedArea(e.target.value);
    let temp = areas?.find(item => item._id === e.target.value);
    setDeliveryFee(temp?.fee);

    setAddress({
      ...address,
      area: temp?.area,
      areaId: temp,
    });
  };
  const handleCheckbox = id => {
    setSelectedId(id);
    const temp = addresses?.find(item => item._id === id);
    console.log(temp);
    setDeliveryFee(temp?.areaId?.fee);
    setAddress(temp);
  };
  const handleRadioChange = value => {
    if (value === '1') {
      setPaymentMethod('cod');
    } else if (value === '2') {
      setPaymentMethod('swap card');
    }
  };
  // console.log(cart, selectedArea);

  return (
    <>
      <Stack>
        <Container p={0} mb={6} maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}>
          {user && addresses && (
            <Stack px={8} bg={'#fff'} py={12} borderRadius={12} gap={8}>
              <Heading color={'#111'} fontSize={'25px'} fontWeight={700}>
                Select Saved Address
              </Heading>
              <SimpleGrid
                columns={{ base: '2', xl: '4', '2xl': '4' }}
                spacing={6}
              >
                {addresses && addresses?.length > 0
                  ? addresses.map((v, k) => {
                      return (
                        <Stack
                          border={
                            selectedId === v?._id
                              ? '3px solid orange'
                              : '1px solid #B4B4B4'
                          }
                          borderRadius={6}
                          px={3}
                          py={4}
                          direction={'row'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          flexWrap={'wrap'}
                          cursor={'pointer'}
                          onClick={() => handleCheckbox(v?._id)}
                        >
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            gap={2}
                          >
                            {/* <Box
                            height={'60px'}
                            width={'60px'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            display={'flex'}
                            borderRadius={6}
                            bg={
                              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                            }
                          >
                            <Icon
                              fontSize={'40px'}
                              as={RiDiscountPercentLine}
                            />
                          </Box> */}
                            <Box>
                              <Heading
                                color={'#007341'}
                                fontSize={'19px'}
                                fontWeight={'700'}
                              >
                                {v?.type || v?.name}
                              </Heading>
                              <Heading
                                color={'#111'}
                                fontSize={'16px'}
                                fontWeight={'700'}
                              >
                                {v?.address}
                              </Heading>
                            </Box>
                          </Stack>
                          <Stack>
                            {/* <Checkbox
                              isChecked={selectedId === v?._id}
                              value={v?._id}
                              onChange={handleCheckbox}
                            /> */}
                          </Stack>

                          {/* <Button
                          border={'2px solid #07d57c'}
                          padding={'10px 22px'}
                          bg={'transparent'}
                          width={'max-content'}
                          fontSize={'14px'}
                          // onClick={() => handleCopyCode(v.couponCode)}
                        >
                          Copy Code
                        </Button> */}
                        </Stack>
                      );
                    })
                  : null}
              </SimpleGrid>
            </Stack>
          )}
        </Container>
        {/* <Container p={0} mb={6} maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}>
          {user && (
            <Stack px={8} bg={'#fff'} py={12} borderRadius={12} gap={8}>
              <Heading color={'#111'} fontSize={'25px'} fontWeight={700}>
                Select Coupon
              </Heading>
              <SimpleGrid
                columns={{ base: '2', xl: '4', '2xl': '4' }}
                spacing={6}
              >
                {couponlist?.length > 0 &&
                  couponlist.map((v, k) => {
                    return (
                      <Stack
                        border={'1px solid #B4B4B4'}
                        borderRadius={6}
                        px={3}
                        py={4}
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        flexWrap={'wrap'}
                      >
                        <Stack direction={'row'} alignItems={'center'} gap={2}>
                          <Box
                            height={'60px'}
                            width={'60px'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            display={'flex'}
                            borderRadius={6}
                            bg={
                              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                            }
                          >
                            <Icon
                              fontSize={'40px'}
                              as={RiDiscountPercentLine}
                            />
                          </Box>
                          <Box>
                            <Heading
                              color={'#007341'}
                              fontSize={'19px'}
                              fontWeight={'700'}
                            >
                              {v.name}
                            </Heading>
                            <Heading
                              color={'#111'}
                              fontSize={'16px'}
                              fontWeight={'700'}
                            >
                              {v.discount}% Off
                            </Heading>
                          </Box>
                        </Stack>
                        <Button
                          border={'2px solid #07d57c'}
                          padding={'10px 22px'}
                          bg={'transparent'}
                          width={'max-content'}
                          fontSize={'14px'}
                          onClick={() => handleCopyCode(v.couponCode)}
                        >
                          Copy Code
                        </Button>
                      </Stack>
                    );
                  })}
              </SimpleGrid>
            </Stack>
          )}
        </Container> */}
      </Stack>
      <Stack>
        <Container p={0} mb={6} maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}>
          <Stack px={8} bg={'#fff'} py={12} borderRadius={12} gap={8}>
            <form onSubmit={handleSubmit}>
              <SimpleGrid
                columns={{ base: '1', xl: '2', '2xl': '2' }}
                px={4}
                spacing={12}
                alignItems={'center'}
              >
                <Stack gap={6}>
                  <Heading color={'#111'} fontSize={'25px'} fontWeight={700}>
                    Billing Details
                  </Heading>

                  <Stack gap={3}>
                    <FormControl>
                      <FormLabel fontSize={'15px'}>Address Labeled*</FormLabel>
                      <Select
                        h={'50px'}
                        // onChange={handleDeliveryAddress}
                        // name="areaId"
                        name="type"
                        onChange={e =>
                          setAddress({ ...address, type: e.target.value })
                        }
                        // value={address?.areaId?._id || ''}
                        value={address?.type || 'home'}
                        placeholder="Enter Your Address Labeled"
                      >
                        <option value="home">Home</option>
                        <option value="work">Work</option>
                        <option value="hotel">Hotel</option>
                        <option value="others">Others</option>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontSize={'15px'}>Full Name *</FormLabel>
                      <Input
                        defaultValue={address?.name}
                        name="name"
                        py={6}
                        type="text"
                        placeholder="Enter Your Name"
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel fontSize={'15px'}>Street address *</FormLabel>
                      <Input
                        py={6}
                        ref={inputRef}
                        defaultValue={address?.address}
                        name="streetAddress"
                        type="text"
                        placeholder="Enter Your Addresss"
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel fontSize={'15px'}>Town / City *</FormLabel>
                      <Input
                        name="town"
                        py={6}
                        // disabled={true}
                        value={'Karachi'}
                        type="text"
                        placeholder="Enter Your Phone"
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel fontSize={'15px'}>
                        Delivery Address *
                      </FormLabel>
                      <Select
                        h={'50px'}
                        onChange={handleDeliveryAddress}
                        name="areaId"
                        value={address?.areaId?._id || ''}
                        placeholder="Enter Your Area"
                      >
                        {areas?.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.area}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    {/* <FormControl>
                      <FormLabel fontSize={'15px'}>State *</FormLabel>
                      <Input
                        py={6}
                        defaultValue={address?.state}
                        name="state"
                        type="text"
                        placeholder="Enter Your State "
                      />
                    </FormControl> */}
                    {/* <FormControl>
                      <FormLabel fontSize={'15px'}>ZIP Code *</FormLabel>
                      <Input
                        py={6}
                        defaultValue={address?.zipCode}
                        type="text"
                        name="zip"
                        placeholder="Enter Your ZIP Code"
                      />
                    </FormControl> */}
                    <FormControl>
                      <FormLabel fontSize={'15px'}>Phone *</FormLabel>
                      <Input
                        py={6}
                        defaultValue={address?.phone}
                        name="phone"
                        type="number"
                        placeholder="Enter Your Phone "
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel fontSize={'15px'}>Email address *</FormLabel>
                      <Input
                        py={6}
                        defaultValue={address?.email}
                        name="email"
                        type="email"
                        placeholder="Enter Your Email"
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel fontSize={'15px'}>Notes*</FormLabel>
                      <Textarea
                        name="notes"
                        rows={3}
                        defaultValue={address?.notes || ''}
                        // background={'#F3F3F3'}
                        color={'#1A1B1A'}
                        border={'1px solid #B4B4B4'}
                        resize={'none'}
                      ></Textarea>
                      {/* <Input
                        py={6}
                        defaultValue={address?.email}
                        name="email"
                        type="email"
                        placeholder="Enter Your Email"
                      /> */}
                    </FormControl>

                    {user && (
                      <FormControl
                        display={'flex'}
                        alignItems={'start'}
                        gap={2}
                      >
                        <FormLabel fontSize={'15px'}>Save address?</FormLabel>
                        <Checkbox
                          mt={1}
                          onChange={handleRadioChange}
                          colorScheme="red"
                          value="1"
                        ></Checkbox>
                        {/* <RadioGroup
                          onChange={handleRadioChange}
                          value={isChecked}
                        >
                          <Stack direction="row">
                            <Radio value="1">Yes</Radio>
                            <Radio value="0">No</Radio>
                          </Stack>
                        </RadioGroup> */}
                      </FormControl>
                    )}

                    <Text fontWeight={600} fontSize={'15px'}>
                      Your personal data will be used to process your order,
                      support your experience throughout this website, and for
                      other purposes described in our privacy policy.
                    </Text>
                  </Stack>
                </Stack>
                <Stack
                  bg="#F3F3F3"
                  borderRadius={6}
                  border={'1px solid #B4B4B4'}
                  py={6}
                  px={4}
                >
                  {user && (
                    <Stack pb={5} mb={4} borderBottom={'1px solid #B4B4B4'}>
                      <Heading
                        color={'#111'}
                        fontSize={'20px'}
                        fontWeight={700}
                      >
                        Coupon code
                      </Heading>
                      <Box
                        display={'flex'}
                        gap={3}
                        justifyContent={'space-between'}
                        alignItems={'flex-end'}
                      >
                        <Stack width={'100%'}>
                          <FormLabel fontSize={'15px'}>code*</FormLabel>
                          <Input
                            py={6}
                            type="text"
                            onChange={e => setCoupon(e.target.value)}
                            placeholder="Enter Your Code"
                          />
                        </Stack>
                        <Button
                          fontSize={'16px'}
                          padding={'25px 40px'}
                          onClick={e => handleCoupon(coupon)}
                          background={
                            'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                          }
                        >
                          Apply
                        </Button>
                      </Box>
                    </Stack>
                  )}

                  <Stack pb={5} borderBottom={'1px solid #B4B4B4'} gap={4}>
                    <Heading color={'#111'} fontSize={'20px'} fontWeight={700}>
                      Your order
                    </Heading>
                    <Table variant="simple">
                      <Tbody>
                        {!type
                          ? user
                            ? (cart.cartItems || cart)?.map((item, index) => (
                                <Tr key={index} p={'0 !important'}>
                                  <Td>
                                    <Text fontSize={'17px'} fontWeight={400}>
                                      {item.productName} × {item.quantity}
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Heading
                                      fontSize={'17px'}
                                      fontWeight={700}
                                      bg={
                                        'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                                      }
                                      backgroundClip={'text'}
                                    >
                                      Rs{' '}
                                      {convertNumbertoCommaSeperate(
                                        item.price * item.quantity
                                      )}
                                    </Heading>
                                  </Td>
                                </Tr>
                              ))
                            : cart?.map((item, index) => (
                                <Tr key={index} p={'0 !important'}>
                                  <Td>
                                    <Text fontSize={'17px'} fontWeight={400}>
                                      {item.productName} × {item.quantity}
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Heading
                                      fontSize={'17px'}
                                      fontWeight={700}
                                      bg={
                                        'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                                      }
                                      backgroundClip={'text'}
                                    >
                                      Rs{' '}
                                      {convertNumbertoCommaSeperate(
                                        item.price * item.quantity
                                      )}
                                    </Heading>
                                  </Td>
                                </Tr>
                              ))
                          : buyNow?.map((item, index) => (
                              <Tr key={index} p={'0 !important'}>
                                <Td>
                                  <Text fontSize={'17px'} fontWeight={400}>
                                    {item.productName} × 1
                                  </Text>
                                </Td>
                                <Td>
                                  <Heading
                                    fontSize={'17px'}
                                    fontWeight={700}
                                    bg={
                                      'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                                    }
                                    backgroundClip={'text'}
                                  >
                                    Rs {item.price * 1}
                                  </Heading>
                                </Td>
                              </Tr>
                            ))}

                        <Tr>
                          <Td>
                            <Heading fontSize={'17px'} fontWeight={700}>
                              Subtotal
                            </Heading>
                          </Td>
                          <Td>
                            <Heading
                              fontSize={'17px'}
                              fontWeight={700}
                              bg={
                                'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                              }
                              backgroundClip={'text'}
                            >
                              Rs{' '}
                              {convertNumbertoCommaSeperate(calculateTotal())}
                            </Heading>
                          </Td>
                        </Tr>

                        {couponApplied && (
                          <>
                            <Tr py={'0 !important'}>
                              <Td py={'0 !important'}>
                                <Heading fontSize={'17px'} fontWeight={700}>
                                  Coupon Code
                                </Heading>
                              </Td>
                              <Td py={'0 !important'}>
                                <Heading
                                  fontSize={'17px'}
                                  fontWeight={700}
                                  bg={
                                    'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                                  }
                                  backgroundClip={'text'}
                                >
                                  {cart?.couponCode}
                                </Heading>
                              </Td>
                            </Tr>
                            <Tr py={'0 !important'}>
                              <Td py={'0 !important'}>
                                <Heading fontSize={'17px'} fontWeight={700}>
                                  Discount
                                </Heading>
                              </Td>
                              <Td>
                                <Heading
                                  fontSize={'17px'}
                                  fontWeight={700}
                                  bg={
                                    'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                                  }
                                  backgroundClip={'text'}
                                >
                                  Rs {cart?.discount}
                                </Heading>
                              </Td>
                            </Tr>
                          </>
                        )}
                        {deliveryFee && (
                          <Tr py={'0 !important'}>
                            <Td py={'0 !important'}>
                              <Heading fontSize={'17px'} fontWeight={700}>
                                Delivery Fee
                              </Heading>
                            </Td>
                            <Td>
                              <Heading
                                fontSize={'17px'}
                                fontWeight={700}
                                bg={
                                  'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                                }
                                backgroundClip={'text'}
                              >
                                Rs {deliveryFee}
                              </Heading>
                            </Td>
                          </Tr>
                        )}

                        <Tr p={'0 !important'}>
                          <Td py={'0 !important'}>
                            <Heading fontSize={'17px'} fontWeight={700}>
                              Total
                            </Heading>
                          </Td>
                          <Td py={'0 !important'}>
                            <Heading
                              fontSize={'17px'}
                              fontWeight={700}
                              bg={
                                'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                              }
                              backgroundClip={'text'}
                            >
                              Rs{' '}
                              {convertNumbertoCommaSeperate(
                                calculateTotal() + deliveryFee
                              )}
                            </Heading>
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Stack>
                  <Stack p={'10px 0'} gap={4}>
                    <Heading color={'#111'} fontSize={'20px'} fontWeight={700}>
                      Payment Method
                    </Heading>
                    <RadioGroup defaultValue="1" onChange={handleRadioChange}>
                      <UnorderedList
                        listStyleType={'none'}
                        spacing={4}
                        m={'0 !important'}
                      >
                        <ListItem>
                          <Stack
                            bg={'#fff'}
                            px={4}
                            py={6}
                            borderRadius={4}
                            border={'1px solid #B4B4B4'}
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                          >
                            <Text>Cash on delivery</Text>
                            <Radio colorScheme="red" value="1"></Radio>
                          </Stack>
                        </ListItem>
                        <ListItem>
                          <Stack
                            bg={'#fff'}
                            px={4}
                            py={6}
                            borderRadius={4}
                            border={'1px solid #B4B4B4'}
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                          >
                            <Text>Card Swipe on delivery</Text>
                            <Radio colorScheme="red" value="2"></Radio>
                          </Stack>
                        </ListItem>
                        {/* <ListItem>
                          <Stack
                            bg={'#fff'}
                            px={4}
                            py={6}
                            borderRadius={4}
                            border={'1px solid #B4B4B4'}
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                          >
                            <Text>VISA /Jazz Cash / Easypaisa</Text>
                            <Radio colorScheme="red" value="2"></Radio>
                          </Stack>
                        </ListItem>
                        <ListItem>
                          <Stack
                            bg={'#fff'}
                            px={4}
                            py={6}
                            borderRadius={4}
                            border={'1px solid #B4B4B4'}
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                          >
                            <Stack gap={2}>
                              <Text>Bonus Card</Text>
                              <Heading
                                fontSize={'20px'}
                                fontWeight={700}
                                bg={
                                  'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                                }
                                backgroundClip={'text'}
                              >
                                $140.00
                              </Heading>
                              <Text>
                                Note: If your purchase is above 10k, you will
                                receive free delivery!
                              </Text>
                            </Stack>

                            <Radio colorScheme="red" value="3"></Radio>
                          </Stack>
                        </ListItem> */}
                      </UnorderedList>
                    </RadioGroup>
                  </Stack>
                  <Button
                    fontSize={'16px'}
                    padding={'25px 40px'}
                    background={
                      'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                    }
                    type="submit"
                    // as={Link}
                    // to={'/deliveryoption'}
                  >
                    Continue
                  </Button>
                </Stack>
              </SimpleGrid>
            </form>
          </Stack>
        </Container>
      </Stack>
    </>
  );
}
